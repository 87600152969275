import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Company } from '../../interfaces/company';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

const GET_COMPANIES = gql`
  query companies {
    companies {
      name_company
      uuid
      bds_number
      uid
      company_register_number
      kind
      delivery_addresses {
        name
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
      }
      invoice_addresses {
        name
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
      }
      persons {
        uuid
        first_name
        last_name
        bds_number
        birthday
        phone_private
        mail_private
        fax
        gender
        title_academic
      }
      invoices {
        title
        invoice_number
        delivery_address_id
        invoice_address_id
      }
    }
  }
`;

const GET_COMPANY = gql`
  query company($uuid: String!) {
    company(uuid: $uuid) {
      name_company
      uuid
      bds_number
      uid
      company_register_number
      bank_name
      iban
      bic
      url
      kind
      info
      delivery_addresses {
        name
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
      }
      invoice_addresses {
        name
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
      }
      persons {
        uuid
        first_name
        last_name
        bds_number
        birthday
        phone_private
        mail_private
        fax
        gender
        title_academic
      }
    }
  }
`;

const CREATE_COMPANY = gql`
  mutation CreateCompany($createCompanyInput: CreateCompanyInput) {
    createCompany(createCompanyInput: $createCompanyInput) {
      _id
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($uuid: String!, $updateCompanyInput: UpdateCompanyInput) {
    updateCompany(uuid: $uuid, updateCompanyInput: $updateCompanyInput) {
      _id
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation DeleteCompany($uuid: String!) {
    deleteCompany(uuid: $uuid) {
      _id
    }
  }
`;

const ADDRESS_TO_COMPANY = gql`
  mutation AddressToCompany($uuid: String!, $input: AddressCompanyInput) {
    addressToCompany(uuid: $uuid, input: $input) {
      _id
    }
  }
`;

const DELETE_COMPANY_ADDRESS = gql`
  mutation DeleteCompanyAddress($uuid: String!, $input: DeleteCompanyAddressInput) {
    deleteCompanyAddress(uuid: $uuid, input: $input) {
      _id
    }
  }
`;

const PERSON_TO_COMPANY = gql`
  mutation PersonToCompany($uuid: String!, $input: CompanyPersonInput) {
    personToCompany(uuid: $uuid, input: $input) {
      _id
    }
  }
`;
const DELETE_COMPANY_PERSON = gql`
  mutation DeleteCompanyPerson($uuid: String!, $personUuid: String!) {
    deleteCompanyPerson(uuid: $uuid, personUuid: $personUuid) {
      _id
    }
  }
`;

const UPDATE_OR_CREATE_COMPANY_PERSON = gql`
  mutation UpdateOrCreateCompanyPerson($input: UpdateOrCreateCompanyPersonInput) {
    updateOrCreateCompanyPerson(input: $input) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  getCompanies(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_COMPANIES,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getCompany(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_COMPANY,
      variables: { uuid },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  saveCompany(company: Company): Observable<any> {
    const companyInput = {
      name_company: company.name_company,
      uuid: company.uuid,
      bds_number: company.bds_number,
      uid: company.uid,
      company_register_number: company.company_register_number,
      bank_name: company.bank_name,
      iban: company.iban,
      bic: company.bic,
      url: company.url,
      info: company.info,
    };
    return this.apollo.mutate({
      mutation: CREATE_COMPANY,
      variables: { createCompanyInput: companyInput },
    });
  }

  updateCompany(uuid: string, company: Company): Observable<any> {
    const companyInput = {
      name_company: company.name_company,
      bds_number: company.bds_number,
      uid: company.uid,
      company_register_number: company.company_register_number,
      bank_name: company.bank_name,
      iban: company.iban,
      bic: company.bic,
      url: company.url,
      info: company.info,
    };
    return this.apollo.mutate({
      mutation: UPDATE_COMPANY,
      variables: { uuid, updateCompanyInput: companyInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompany(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_COMPANY, variables: { uuid } });
  }

  saveCompanyAddress(uuid: string, addressInput: any) {
    return this.apollo.mutate({
      mutation: ADDRESS_TO_COMPANY,
      variables: { uuid, input: addressInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompanyAddress(uuid: string, addressInput: any) {
    return this.apollo.mutate({
      mutation: DELETE_COMPANY_ADDRESS,
      variables: { uuid, input: addressInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  saveCompanyPerson(uuid: string, input: any) {
    return this.apollo.mutate({
      mutation: PERSON_TO_COMPANY,
      variables: { uuid, input },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompanyPerson(uuid: string, personUuid: string) {
    return this.apollo.mutate({
      mutation: DELETE_COMPANY_PERSON,
      variables: { uuid, personUuid },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  updateOrCreateCompanyPerson(input: any) {
    return this.apollo.mutate({
      mutation: UPDATE_OR_CREATE_COMPANY_PERSON,
      variables: { input },
      refetchQueries: [{ query: GET_COMPANIES }],
    });
  }

  getDummyCompanies() {
    return this.http.get(`${environment.uri}/companies/rss-dummy-companies`, { responseType: 'text' });
  }
}
