<div class="flex justify-center">
  <form
    class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="financialService.detailsForm"
  >
    <financehub-input-template
      [optionsArray]="financialService.filteredCompanies"
      [formGroup]="financialService.detailsForm"
      (autocompleteChanged)="autocompleteChanged($event)"
      (autocompleteSelected)="onCompanySelected($event)"
      [displayWith]="displayCustomerName"
      displayField="name_company"
      type="autocomplete"
      label="Customer"
      controlName="customer"
    />

    <financehub-input-template
      [optionsArray]="financialService.localsOutput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Output Locale"
      controlName="outputLocale"
    />

     <financehub-input-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Invoice Date - Days' : 'Offer Date - Days'"
      [controlName]="financialService.isInvoice ? 'invoiceDateDays' : 'offerDateDays'"
    />

    <financehub-input-template
      [readonly]="financialService.isEdit ? false : true"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Invoice Date' : 'Offer Date'"
      [type]="financialService.isEdit ? 'datepicker' : 'text'"
      [controlName]="financialService.isInvoice ? 'invoiceDate' : 'offerDate'"
    />

     <financehub-input-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Due Date Days' : 'Validity Date Days'"
      [controlName]="financialService.isInvoice ? 'dueDateDays' : 'validityDateDays'"
    />

    <financehub-input-template
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Due Date' : 'Validity Date'"
      [type]="financialService.isEdit ? 'datepicker' : 'text'"
      [controlName]="financialService.isInvoice ? 'dueDate' : 'validityDate'"
    />

    <financehub-input-template
      [optionsArray]="financialService.localsInput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Input Locale"
      controlName="inputLocale"
    />
  </form>
</div>
