<div class="!bg-background min-h-screen py-10 px-[10vw] flex gap-x-10 relative">
    <div class="flex flex-col w-full">
        <div class="text-3xl text-primary font-light w-full mb-1">
            <span class="text-start">Companies</span>
        </div>
        <financehub-company-list class="w-full min-h-[300px]"/>
        <div class="text-3xl text-primary font-light w-full mb-2"><span class="text-start">Persons</span></div>
        <financehub-person-list class="w-full min-h-[300px]"/>
        <div class="text-3xl text-primary font-light w-full mb-5"><span class="text-start">Addresses</span></div>
        <financehub-address-list class="w-full min-h-[300px]"/>
    </div>
    <div class="fixed bg-overlay top-0 right-0 bottom-0 left-0 z-50" (click)="financialService.resetEntity()" *ngIf="financialService.entityEdit">
        <div 
          class="fixed right-0 shadow-xl !bg-background top-0 bottom-0 border-1 border-gray-200 rounded-l-xl p-5 z-50"
          [ngClass]="{'left-[40%]': financialService.entityEdit === 'company', 'left-[60%]': financialService.entityEdit === 'address' || financialService.entityEdit === 'person'}"
          (click)="$event.stopPropagation()">
          <financehub-company-detail *ngIf="financialService.entityEdit === 'company'"/>
          <financehub-address-detail *ngIf="financialService.entityEdit === 'address'"/>
          <financehub-person-detail *ngIf="financialService.entityEdit === 'person'"/>
        </div>
      </div>
</div>