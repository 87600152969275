<div class="flex-col rounded bg-white p-5">
  <h1>{{ data.headline }}</h1>
  <p *ngIf="data.text">{{ data.text }}</p>

    <!-- DELETE ADDRESS OF COMPANY -->
    <div *ngIf="addressesService.dialogCall === 'deleteAddressOfCompany' || personsService.dialogCall === 'deletePersonOfCompany'" >
      <div class="flex w-full justify-end">
        <button
        (click)="dialogRef.close(data.element)"
        mat-button
        color="primary"
        >
          {{data.confirm}}
        </button>
      </div>
    </div>

    <!-- EDIT ADDRESS OF COMPANY -->
    <div *ngIf="addressesService.dialogCall === 'editAddressOfCompany'" >
      <form [formGroup]="addressesService.editAddressOfCompanyForm">
        <section class="mb-3 flex w-full">
          <div class="flex justify-center items-center ml-2">
            <mat-checkbox formControlName="invoice">Billing Address</mat-checkbox>
          </div>
          <div class="flex justify-center items-center mr-2">
            <mat-checkbox formControlName="delivery">Shipping Address</mat-checkbox>
          </div>
        </section>
      </form>
  
      <div class="flex w-full justify-end">
        <button
        (click)="dialogRef.close(data.element)"
        mat-button
        color="primary"
        >
          {{data.confirm}}
        </button>
      </div>
    </div>

  <!-- EDIT PERSON OF COMPANY -->
  <div *ngIf="personsService.dialogCall === 'editPersonOfCompany'">
    <form [formGroup]="personsService.editPersonOfCompanyForm">
      <financehub-input-template
        *ngFor="let p of config.fields.personCompanyInfo"
        [formGroup]="personsService.editPersonOfCompanyForm"
        [label]="p.name"
        [controlName]="p.controlName"
        [type]="p.type"
      />
    </form>
    <div class="flex w-full justify-end">
      <button (click)="dialogRef.close()" mat-button color="primary">{{data.cancel}}</button>
      <button
        (click)="dialogRef.close(data.element)"
        mat-button
        color="primary"
      >
      {{data.confirm}}
      </button>
    </div>
  </div>

  <!-- ADD ADDRESS TO COMPANY -->
  <div *ngIf="addressesService.dialogCall === 'addAddressToCompany'" >
    <form [formGroup]="addressesService.addressToCompanyForm">
      <financehub-input-template
        [formGroup]="addressesService.addressToCompanyForm"
        [optionsArray]="addressesService.availableAddresses"
        [readonly]="false"
        [displayWith]="displayAddressName"
        (autocompleteChanged)="onAutocompleteChange($event)"
        (autocompleteSelected)="onAutocompleteSelected($event)"
        label="Address"
        controlName="selectedAddress"
        type="autocomplete"
        displayField="street"
        displayFieldSecondary="house_number"
      />
      <section class="mb-3 flex w-full">
        <div class="flex justify-center items-center ml-2">
          <mat-checkbox formControlName="invoice">Billing Address</mat-checkbox>
        </div>
        <div class="flex justify-center items-centermr-2">
          <mat-checkbox formControlName="delivery">Shipping Address</mat-checkbox>
        </div>
      </section>
    </form>

    <div class="flex w-full justify-end">
      <button
      *ngIf="addressesService.dialogCall === 'addAddressToCompany'"
      [disabled]="addressesService.addressToCompanyForm.invalid"
      (click)="dialogRef.close(data.element)"
      mat-button
      color="primary"
      >
        {{data.confirm}}
      </button>
    </div>
  </div>


  <!-- ADD PERSON TO COMPANY -->
  <div *ngIf="personsService.dialogCall === 'addPersonToCompany'">
    <form  [formGroup]="personsService.personToCompanyForm">
      <financehub-input-template
          [formGroup]="personsService.personToCompanyForm"
          [optionsArray]="personsService.availablePersons"
          [readonly]="false"
          [displayWith]="displayPersonName"
          (autocompleteChanged)="onAutocompleteChange($event)"
          label="Person"
          controlName="selectedPerson"
          type="autocomplete"
          displayField="first_name"
          displayFieldSecondary="last_name"
      />
    </form>
    <div class="flex w-full justify-end">
      <button
        [disabled]="personsService.personToCompanyForm.invalid"
        (click)="dialogRef.close(data.element)"
        mat-button
        color="primary"
        >
        {{data.confirm}}
      </button>
    </div>
  </div>
</div>
