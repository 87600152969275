import { Component, OnInit, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { AddressesService } from '../../services/crm/addresses.service';
import { NgIf, NgClass } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../assets/config/project-config';
import { AddTemplateComponent } from '../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { Company } from 'src/app/interfaces/company';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { MatInputModule } from '@angular/material/input';
import { Address } from 'src/app/interfaces/address';
import { Person } from 'src/app/interfaces/person';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { DragDropTemplateComponent } from 'src/app/components/drag-drop-template/drag-drop-template.component';
import { defaultConfig, FilterConfig } from 'src/app/interfaces/filter-config';
import { DialogTemplateComponent } from 'src/app/components/dialog-template/dialog-template.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';

@Component({
  selector: 'financehub-client-add',
  templateUrl: './client-add-component.html',
  styleUrls: ['./client-add-component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgClass,
    MatButtonModule,
    AddTemplateComponent,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatExpansionModule,
    DragDropTemplateComponent,
    DialogModule
  ],
})
export class clientAddComponent implements OnInit {
  constructor(
    private addressesService: AddressesService,
    private companyService: CompanyService,
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,
    private addService: AddService,
    public financialService: FinancialService,
    public dialog: Dialog,
  ) {}

  addressForm!: FormGroup;
  companyForm!: FormGroup;
  personForm!: FormGroup;
  personCompanyForm!: FormGroup;
  addressTypeForm!: FormGroup;

  filterConfig: FilterConfig = defaultConfig;

  companySelected = false;
  addressSelected = false;
  personSelected = false;
  selectedCompany: any;
  selectedPerson: any;

  personIsAvailable = true;

  invalidAddressType = false;
  invalidForm = false;
  config = ProjectSettings;
  editEnabled = true;

  panels = {
    panel1Expanded: false,
    panel2Expanded: false,
    panel3Expanded: false,
    panel4Expanded: false,
    panel5Expanded: false,
  }

  selectedFile!: File;

  @ViewChild('accordion1') accordion1!: MatAccordion;
  @ViewChild('accordion2') accordion2!: MatAccordion;
  @ViewChild('accordion3') accordion3!: MatAccordion;

  ngOnInit() {
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
    this.replaceBdsNumbers();
    this.getCompanies();
    this.getAddresses();
    this.getPersons();
   }

   //companies
   getCompanies() {
    this.companyService.getCompanies(this.filterConfig).subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies.data;
      this.financialService.filteredCompanies = [...data.companies.data];
      this.sortEntities(this.financialService.filteredCompanies, 'name_company');
    });
   }

   //addresses
   getAddresses() {
    this.addressesService.getAddresses(this.filterConfig).subscribe(({ data, loading }) => {
      this.financialService.filteredAddressesOriginal = data.addresses.data;
      this.financialService.filteredAddresses = [...data.addresses.data];
      this.sortEntities(this.financialService.filteredAddresses, 'name');
    });
  }

  //persons
  getPersons() {
    this.personsService.getPersons(this.filterConfig).subscribe(({ data, loading }) => {
      this.financialService.filteredPersonsOriginal = data.persons.data;
      this.financialService.filteredPersons = [...data.persons.data];
      this.sortEntities(this.financialService.filteredPersons, 'first_name');
    });
  }

  sortEntities(array: any, name: any) {
    const sortedArray = array.sort((a:any, b:any) => {
      const nameA = a[name].toUpperCase();
      const nameB = b[name].toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  //companies
  displayCompanyName(company: Company) {
    return company ? company.name_company : '';
  }

  //addresses
  displayAddressName(address: Address) {
    return address ? address.street : '';
  }
  
  //persons
  displayPersonName(person: Person) {
    return person ? person.first_name : '';
  }

  //companies
  clearCompanyInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchCompanyChanged('');
    }
  }

  //addresses
  clearAddressInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchAddressChanged('');
    }
  }

  //persons
  clearPersonInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchPersonChanged('');
    }
  }

  //companies
   searchCompanyChanged(value: any) {
    this.companyService.getCompanies({ ...this.filterConfig, filter_term: value }).subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies.data;
      this.financialService.filteredCompanies = [...data.companies.data];
      this.sortEntities(this.financialService.filteredCompanies, 'name_company');
      if (this.editEnabled && this.companySelected) {
        this.financialService.editCompany = true;
      } else {
        this.financialService.editCompany = false;
      }
      if (!this.editEnabled) {
        if (this.companySelected) {
          this.enableFields(this.companyForm);
          this.companyForm = this.addService.createFormGroup(this.config.fields.company);
          this.companySelected = false;
          this.selectedCompany = null;
          this.personIsAvailable = true;
        }
      }
    });
  }

  //addresses
  searchAddressChanged(value: any) {
    this.addressesService.getAddresses({ ...this.filterConfig, filter_term: value }).subscribe(({ data, loading }) => {
      this.financialService.filteredAddressesOriginal = data.addresses.data;
      this.financialService.filteredAddresses = [...data.addresses.data];
      this.sortEntities(this.financialService.filteredAddresses, 'name');
      if (this.editEnabled && this.addressSelected) {
        this.financialService.editAddress = true;
      } else {
        this.financialService.editAddress = false;
      }
      if (!this.editEnabled) {
        if (this.addressSelected) {
          this.enableFields(this.addressForm);
          this.enableFields(this.addressTypeForm);
          this.addressForm = this.addService.createFormGroup(this.config.fields.address);
          this.addressSelected = false;
        }
      }
    });
  }

  //persons
  searchPersonChanged(value: any) {
    this.personsService.getPersons({ ...this.filterConfig, filter_term: value }).subscribe(({ data, loading }) => {
      this.financialService.filteredPersonsOriginal = data.persons.data;
      this.financialService.filteredPersons = [...data.persons.data];
      this.sortEntities(this.financialService.filteredPersons, 'first_name');
      if (this.editEnabled && this.personSelected) {
        this.financialService.editPerson = true;
      } else {
        this.financialService.editPerson = false;
      }
      if (!this.editEnabled) {
        if (this.personSelected) {
          this.enableFields(this.personForm);
          this.enableFields(this.personCompanyForm);
          this.personForm = this.addService.createFormGroup(this.config.fields.person);
          this.personSelected = false;
          this.selectedPerson = null;
          this.personIsAvailable = true;
        }
      }
    });
  }

  //companies
  searchCompanySelected(value: any) {
    this.financialService.editCompany = false;
    this.companyService.getCompany(value.uuid).subscribe(({ data }) => {
      this.selectedCompany = data.company;
      Object.keys(this.companyForm.controls).forEach(controlName => {
        const controlValue = this.selectedCompany[controlName];
        const type = this.config.fields.company.find((f: any) => f.controlName === controlName)?.type;
        controlName !== 'name_company' ? this.companyForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue) : null;
      });

      if (this.selectedPerson)
        this.checkPersonAvailability();

      this.sortEntities(this.financialService.filteredCompanies, 'name_company');
      if (!this.editEnabled) {
        this.disableFields(this.companyForm, 'name_company');
      }
      this.companySelected = true;
      //address
      if (this.selectedCompany.invoice_addresses.length > 0) {
        this.searchAddressSelected(this.selectedCompany.invoice_addresses[0]);
        this.addressTypeForm.controls['invoice'].setValue(true);
        this.addressTypeForm.controls['delivery'].setValue(true);
      } else if (this.addressSelected) {
        this.resetAddress();
        //this.addressForm = this.addService.createFormGroup(this.config.fields.address);
        //this.addressSelected = false;
      }
    });
  }

  //addresses
  searchAddressSelected(value: any) {
    this.financialService.editAddress = false;
    this.addressesService.getAddress(value.uuid).subscribe(({ data }) => {
      const selectedAddress = data.address
      Object.keys(this.addressForm.controls).forEach(controlName => {
        const controlValue = selectedAddress[controlName];
        const type = this.config.fields.address.find((f: any) => f.controlName === controlName)?.type;
        controlName !== 'street' ? this.addressForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue) : null;
      });
      this.addressForm.controls['street'].setValue(value);
      this.sortEntities(this.financialService.filteredAddresses, 'street');
      if (!this.editEnabled) {
        this.disableFields(this.addressForm, "street");
        this.disableFields(this.addressTypeForm, "");
      }
      this.addressSelected = true;
    });
  }

  //persons
  searchPersonSelected(value: any) {
    this.financialService.editPerson = false;
    this.personsService.getPerson(value.uuid).subscribe(({ data }) => {
      this.selectedPerson = data.person;
      Object.keys(this.personForm.controls).forEach(controlName => {
        const controlValue = this.selectedPerson[controlName];
        const type = this.config.fields.person.find((f: any) => f.controlName === controlName)?.type;
        controlName !== 'first_name' ? this.personForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue) : null;
      });
      if (this.selectedCompany) {
        this.checkPersonAvailability();
        const person = this.selectedCompany.company_persons.find((p: any) => p.person_uuid === value.uuid);
        if (person) {
          Object.keys(this.personCompanyForm.controls).forEach(controlName => {
            const controlValue = person[controlName];
            const type = this.config.fields.personCompanyInfo.find((f: any) => f.controlName === controlName)?.type;
            this.personCompanyForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
          });
        }
      }

      this.personForm.controls['first_name'].setValue(value);
      this.sortEntities(this.financialService.filteredPersons, 'first_name');
      if (!this.editEnabled) {
        this.disableFields(this.personForm, "first_name");
        this.disableFields(this.personCompanyForm, "");
      }
      this.personSelected = true;
    });
  }
  
  enableFields(form: FormGroup) {
    Object.keys(form.controls).forEach(controlName => {
      controlName !== 'uuid' ? form.get(controlName)?.enable() : null;
    })
  }

  disableFields(form: FormGroup, searchfield: string) {
    Object.keys(form.controls).forEach(controlName => {
      controlName !== searchfield ? form.get(controlName)?.disable() : null;
    })
  }

  clickedOnPanel(panel: any, accordion: MatAccordion) {
    if (panel) {
      accordion.openAll();
    } else {
      accordion.closeAll();
    }
  }

  create() {
    if (this.companyForm.invalid || this.personForm.invalid) {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.incompleteClient } });
      this.companyForm.markAllAsTouched();
      this.personForm.markAllAsTouched();
    }
    if (this.addressForm.controls['street'].value && this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    }
    if (this.addressForm.controls['street'].value && this.addressTypeFormInvalid()) {
      this.addressTypeForm.markAllAsTouched();
    }
    if (this.companyForm.valid && this.personForm.valid && !this.addressForm.controls['street'].value) {
      //this.openAddressDialog();
      this.processFormIsValid();
    }
    if (this.companyForm.valid && this.personForm.valid && this.addressForm.valid && !this.addressTypeFormInvalid()) {
      //this.createWithAddress();
      this.processFormIsValid();
    }
  }

  openAddressDialog(): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        element: 'create_without_address',
        text: `Create client without address?`,
        cancel: this.config.dialog.createCancel,
        confirm: this.config.dialog.createConfirm
      },
    });
    dialogRef.closed.subscribe((entity) => {
      if (entity) this.createWithoutAddress();
    });
  }

  createWithoutAddress() {
    console.log('create without address');
  }

  createWithAddress() {
    this.processFormIsValid();
    // if (this.companyForm.invalid || this.addressForm.invalid || this.personForm.invalid || this.addressTypeFormInvalid()) {
    //   this.processFormIsInvalid();
    // } else {
    //   this.processFormIsValid();
    // }
  }

  addressTypeFormInvalid() {
    return (!this.addressTypeForm.controls['invoice'].value && !this.addressTypeForm.controls['delivery'].value)
  }

  processFormIsInvalid() {
    this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.incompleteClient } });
    this.companyForm.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    this.personForm.markAllAsTouched();
    this.addressTypeForm.markAllAsTouched();
    this.invalidForm = true;
  }

  processFormIsValid() {
    this.addressForm = this.addService.parseToString(this.addressForm, this.config.fields.address);
    this.companyForm = this.addService.parseToString(this.companyForm, this.config.fields.company);
    this.personForm = this.addService.parseToString(this.personForm, this.config.fields.person);

    this.companyService.updateOrCreateCompanyPerson({
      company: this.getEntityFromForm(this.companyForm, 'name_company'),
      address: this.getEntityFromForm(this.addressForm, 'street'),
      company_address: {
        type_delivery: this.addressTypeForm.controls['delivery'].value,
        type_invoice: this.addressTypeForm.controls['invoice'].value,
        address_uuid: this.addressForm.controls['uuid'].value,
      },
      person: this.getEntityFromForm(this.personForm, 'first_name'),
      company_person: {
        person_uuid: this.personForm.controls['uuid'].value,
        position: this.personCompanyForm.controls['position'].value,
        phone_office: this.personCompanyForm.controls['phone_office'].value,
        phone_mobile: this.personCompanyForm.controls['phone_mobile'].value,
        email_office: this.personCompanyForm.controls['email_office'].value,
      }
    }).subscribe(({ data, loading }) => {
      this.resetForms();
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdClient } });
    });
  }

  getEntityFromForm(form: FormGroup, autocompleteField: string) {
    const formValues = form.getRawValue();
    // for autocomplete inputs the complete entity is saved as the value --> overwrite the field with the actual value before saving.
    // see console log
    if (typeof formValues[autocompleteField] === 'object')
      formValues[autocompleteField] = formValues[autocompleteField][autocompleteField];
    return {
      ...formValues
    }
  }

  resetCompany() {
    this.companyForm.reset();
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.searchCompanyChanged('');
    this.companySelected = false;
    this.financialService.editCompany = false;
    this.companyService.getFirstBdsNumber().subscribe(({ data }) => {
      this.companyForm.controls['bds_number'].setValue(data.companyFirstBdsNumber);
    });
  }

  resetAddress() {
    this.addressForm.reset();
    this.addressTypeForm.reset();
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
    this.searchAddressChanged('');
    this.addressSelected = false;
    this.financialService.editAddress = false;
    this.addressesService.getFirstBdsNumber().subscribe(({ data }) => {
      this.addressForm.controls['bds_number'].setValue(data.addressFirstBdsNumber);
    });
  }

  resetPerson() {
    this.personForm.reset();
    this.personCompanyForm.reset();
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.searchPersonChanged('');
    this.personSelected = false;
    this.financialService.editPerson = false;
    this.personsService.getFirstBdsNumber().subscribe(({ data }) => {
      this.personForm.controls['bds_number'].setValue(data.personFirstBdsNumber);
    });
  }

  newCompany() {
    if (this.companySelected) {
      this.companySelected = false;
      this.companyForm.controls['uuid'].setValue(uuidv4());
      this.enableFields(this.companyForm);
    }
  }

  newAddress() {
    if (this.addressSelected) {
      this.addressSelected = false;
      this.addressForm.controls['uuid'].setValue(uuidv4());
      this.enableFields(this.addressForm);
      this.enableFields(this.addressTypeForm);
    }
  }

  newPerson() {
    if (this.personSelected) {
      this.personSelected = false;
      this.personForm.controls['uuid'].setValue(uuidv4());
      this.enableFields(this.personForm);
      this.enableFields(this.personCompanyForm);
    }
  }

  enableEdit() {
    if (this.editEnabled) {
      this.enableFields(this.companyForm);
      this.enableFields(this.addressForm);
      this.enableFields(this.addressTypeForm);
      this.enableFields(this.personForm);
      this.enableFields(this.personCompanyForm);
    } else {
      this.disableFields(this.companyForm, 'name_company');
      this.disableFields(this.addressForm, 'street');
      this.disableFields(this.addressTypeForm, '');
      this.disableFields(this.personForm, 'first_name');
      this.disableFields(this.personCompanyForm, '');
    }
  }
  
  resetForms() {
    this.addressForm.reset();
    this.companyForm.reset();
    this.personForm.reset();
    this.personCompanyForm.reset();
    this.addressTypeForm.reset();
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
    this.financialService.editAddress = false;
    this.financialService.editCompany = false;
    this.financialService.editPerson = false;
    this.companySelected = false;
    this.personSelected = false;
    this.addressSelected = false;
    this.replaceBdsNumbers();
  }

  checkPersonAvailability() {
    return this.personIsAvailable = !this.selectedCompany.persons.find((p: Person) => p.uuid === this.selectedPerson.uuid)
  }

  replaceBdsNumbers() {
    this.addressesService.getFirstBdsNumber().subscribe(({ data }) => {
      this.addressForm.controls['bds_number'].setValue(data.addressFirstBdsNumber);
    });
    this.personsService.getFirstBdsNumber().subscribe(({ data }) => {
      this.personForm.controls['bds_number'].setValue(data.personFirstBdsNumber);
    });
    this.companyService.getFirstBdsNumber().subscribe(({ data }) => {
      this.companyForm.controls['bds_number'].setValue(data.companyFirstBdsNumber);
    });
  }

  fileSelected(file: File) {
    this.selectedFile = file;
  }

  companyChanged() {
    console.log('changed company fields')
  }
}
