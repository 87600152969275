<div class="relative flex min-h-screen flex-col text-gray-700 bg-background px-[10vw] py-5" #scrollTarget>
  <div class="text-3xl text-primary font-light w-full mb-2"><span class="text-start">{{financialService.isInvoice ? 'Invoice' : 'Quote'}}</span></div>
  <div class="my-3 flex flex-col w-full">
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="flex flex-col lg:w-4/12 2xl:w-3/12">
        <financehub-invoice-details/>
        <financehub-invoice-project/>
        <financehub-invoice-texts class="2xl:hidden"/>
      </div>
      <div class="flex flex-col lg:w-5/12 2xl:w-4/12">
        <financehub-invoice-customer/>
      </div>
      <div class="hidden 2xl:flex 2xl:w-3/12">
        <financehub-invoice-texts class="w-full"/>
      </div>
      <div class="lg:w-3/12 2xl:w-2/12">
        <financehub-invoice-sums/>
        <button class="w-full mt-5" (click)="submit()" mat-raised-button color="primary">
          {{
            financialService.isInvoice && !financialService.isEdit ? config.button.createInvoice
            : financialService.isInvoice && financialService.isEdit ? config.button.updateInvoice
            : !financialService.isInvoice && !financialService.isEdit ? config.button.createOffer
            : !financialService.isInvoice && financialService.isEdit ? config.button.updateOffer
            : ''
          }}
        </button>
      </div>
    </div>
    <financehub-invoice-positions [scrollTarget]="scrollTarget" class="pt-2"/>
  </div>
</div>
