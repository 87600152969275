import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Address, AddressType } from 'src/app/interfaces/address';
import { FilterConfig } from 'src/app/interfaces/filter-config';
import { AddService } from '../add.service';
import { ProjectSettings } from 'src/assets/config/project-config';

export const GET_ADDRESSES = gql`
  query addresses($filter: FilterInput) {
    addresses (filter: $filter) {
      data {
        uuid
        bds_number
        country
        street
        city
        house_number
        zip
        additional_information
      }
      totalCount
    }
  }
`;

const GET_ADDRESS = gql`
  query address($uuid: String!) {
    address(uuid: $uuid) {
      uuid
      bds_number
      country
      street
      city
      house_number
      zip
      additional_information
    }
  }
`;

const GET_FIRST_BDS_NUMBER =  gql`
  query addressFirstBdsNumber {
    addressFirstBdsNumber
  }
`;

const CREATE_ADDRESS = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput) {
    createAddress(createAddressInput: $createAddressInput) {
      _id
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($uuid: String!, $updateAddressInput: UpdateAddressInput) {
    updateAddress(uuid: $uuid, updateAddressInput: $updateAddressInput) {
      _id
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation DeleteAddress($uuid: String!) {
    deleteAddress(uuid: $uuid) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private apollo: Apollo, private addService: AddService) {}

  addressToCompanyForm!: FormGroup;
  editAddressOfCompanyForm!: FormGroup;
  dialogCall = '';
  availableAddresses: Address[] = [];
  companyAddresses: AddressType[] = [];
  addressesOfCompany: AddressType[] = [];
  config = ProjectSettings;

  setAddressToCompanyForm() {
    this.addressToCompanyForm = new FormGroup(
      {
        selectedAddress: new FormControl('', Validators.required),
        delivery: new FormControl({ value: false, disabled: false }),
        invoice: new FormControl({ value: false, disabled: false }),
      },
      { validators: this.addressToCompanyCheckboxesValidation },
    );
  }

  setEditAddressOfCompanyForm(address: any) {
    this.editAddressOfCompanyForm = this.addService.createFormGroup(this.config.fields.addressType);
    Object.keys(this.editAddressOfCompanyForm.controls).forEach(controlName => {
      let controlValue = address[controlName];
      if (!controlValue) {
        controlValue = false;
      }
      console.log(controlValue)
      this.editAddressOfCompanyForm.controls[controlName].setValue(controlValue);
    });
  }

  addressToCompanyCheckboxesValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const deliveryValue = control.get('delivery')?.value;
    const invoiceValue = control.get('invoice')?.value;
    if (!deliveryValue && !invoiceValue) {
      return { atLeastOneCheckbox: true };
    }
    return null;
  }

  addressToCompanyDisablingCheckboxes(selectedAddress: Address) {
    if (this.addressToCompanyIsInUse(selectedAddress.uuid, 'delivery')) {
      this.addressToCompanyForm.get('delivery')?.disable();
    } else {
      this.addressToCompanyForm.get('delivery')?.enable();
    }
    if (this.addressToCompanyIsInUse(selectedAddress.uuid, 'invoice')) {
      this.addressToCompanyForm.get('invoice')?.disable();
    } else {
      this.addressToCompanyForm.get('invoice')?.enable();
    }
  }

  addressToCompanyIsInUse(addrUuid: string, type: string) {
    return (
      this.companyAddresses.findIndex((addr: AddressType) => {
        return addr.uuid === addrUuid && addr.type === type;
      }) !== -1
    );
  }

  getAddresses(filterConfig: FilterConfig): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_ADDRESSES,
      variables: {
        filter: {
          limit_count: filterConfig.limit_count,
          limit_start: filterConfig.limit_start,
          sort_field: filterConfig.sort_field,
          sort_order: filterConfig.sort_order,
          filter_term: filterConfig.filter_term,
        }
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getAddress(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_ADDRESS,
      variables: { uuid },
    }).valueChanges;
  }

  getFirstBdsNumber(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_FIRST_BDS_NUMBER,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  saveAddress(address: Address): Observable<any> {
    const addressInput = {
      uuid: address.uuid,
      bds_number: address.bds_number,
      country: address.country,
      street: address.street,
      city: address.city,
      house_number: address.house_number,
      zip: address.zip,
    };
    return this.apollo.mutate({
      mutation: CREATE_ADDRESS,
      variables: { createAddressInput: addressInput },
    });
  }

  updateAddress(uuid: string, address: Address): Observable<any> {
    const addressInput = {
      bds_number: address.bds_number,
      country: address.country,
      street: address.street,
      city: address.city,
      house_number: address.house_number,
      zip: address.zip,
    };
    return this.apollo.mutate({
      mutation: UPDATE_ADDRESS,
      variables: { uuid: uuid, updateAddressInput: addressInput },
      refetchQueries: [{ query: GET_ADDRESS, variables: { uuid } }],
    });
  }

  deleteAddress(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_ADDRESS, variables: { uuid } });
  }
}
