import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Company } from '../../../interfaces/company';
import { Person } from '../../../interfaces/person';
import { AddressesService } from '../../../services/crm/addresses.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Address } from '../../../interfaces/address';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { InputTemplateComponent } from '../../../components/input-template/input-template.component';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';

@Component({
  selector: 'financehub-address-detail',
  templateUrl: './address-detail.component.html',
  standalone: true,
  imports: [
    RouterLink,
    MatExpansionModule,
    NgIf,
    NgClass,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    InputTemplateComponent,
    NgFor,
    MatSnackBarModule,
    DetailTemplateComponent
  ],
})
export class AddressDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    public addressesService: AddressesService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) {}

  config = ProjectSettings;
  private querySubscription!: Subscription;
  uuid!: string;
  address!: any;
  addressForm!: FormGroup;
  editAddressActive: boolean = false;


  ngOnInit() {
    this.createOrUpdateAddressForm();
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.addressesService.getAddress(this.uuid).subscribe(({ data, loading }) => {
        this.address = { ...data.address };
        this.createOrUpdateAddressForm(this.address);
      });
    });
  }

  createOrUpdateAddressForm(address: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.address.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = address[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.addressForm = new FormGroup(formGroupConfig);
  }

  editAddress() {
    this.editAddressActive = true;
    this.enableFields();
  }

  enableFields() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.addressForm.get(controlName)?.enable() : null;
    })
  }

  updateAddress() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      const controlValue = this.addressForm.get(controlName)?.value;
      const type = this.config.fields.address.find((f: any) => f.controlName === controlName)?.type;
      this.address[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.addressesService.updateAddress(this.uuid, this.address).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedAddress } });
    });
    this.editAddressActive = false;
    this.disableFields();
  }

  disableFields() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.addressForm.get(controlName)?.disable() : null;
    })
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
  
}