<div>
  <div class="text-primary text-lg mb-2">Customer</div>
  <div class="flex justify-center">
    <form
      class="mb-4 flex grid w-full grid-cols-1 flex-col items-start lg:grid-cols-12 gap-x-2"
      [formGroup]="financialService.customerForm"
    >
      <financehub-input-template
        class="col-span-12 lg:col-span-9"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="UUID"
        controlName="uuid"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-3"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="bds-Nr."
        controlName="bds_number"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-4"
        [formGroup]="financialService.customerForm"
        label="UID"
        controlName="uid"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-4"
        [formGroup]="financialService.customerForm"
        label="CRN"
        controlName="company_register_number"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-4"
        [formGroup]="financialService.customerForm"
        [optionsArray]="financialService.filteredPersons"
        [readonly]="!financialService.filteredPersons || financialService.filteredPersons.length === 0"
        [displayWith]="displayPersonName"
        (autocompleteChanged)="onPersonChange($event)"
        label="Person"
        controlName="person_id"
        type="autocomplete"
        displayField="first_name"
        displayFieldSecondary="last_name"
      />

      <span class="mt-4 flex items-center text-lg text-primary mb-2 col-span-12">
        Billing Address
      </span>

      <financehub-input-template
        class="col-span-12"
        [optionsArray]="financialService.filteredInvoiceAddresses"
        [formGroup]="financialService.customerForm"
        (autocompleteChanged)="onInvoiceAddressChange($event)"
        (autocompleteSelected)="onInvoiceAddressSelected($event)"
        displayField="street"
        displayFieldSecondary="house_number"
        type="autocomplete"
        label="Search"
        controlName="invoice_search"
        optionValue="uuid"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-7"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Street"
        controlName="invoice_street"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-2"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Nr."
        controlName="invoice_house_number"
      />
      <financehub-input-template
        class="col-span-12 lg:col-span-3"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Info"
        controlName="invoice_additional_information"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-3"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="ZIP"
        controlName="invoice_zip"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-4"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="City"
        controlName="invoice_city"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-5"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Country"
        controlName="invoice_country"
      />

      <span class="mt-4 flex items-center text-lg text-primary mb-2 col-span-12">
        Shipping Address
      </span>

      <financehub-input-template
        class="col-span-12"
        [optionsArray]="financialService.filteredDeliveryAddresses"
        [formGroup]="financialService.customerForm"
        (autocompleteChanged)="onDeliveryAddressChange($event)"
        (autocompleteSelected)="onDeliveryAddressSelected($event)"
        displayField="street"
        displayFieldSecondary="house_number"
        type="autocomplete"
        label="Search"
        controlName="delivery_search"
        optionValue="uuid"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-7"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Street"
        controlName="delivery_street"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-2"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Nr."
        controlName="delivery_house_number"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-3"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Info"
        controlName="delivery_additional_information"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-3"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="ZIP"
        controlName="delivery_zip"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-4"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="City"
        controlName="delivery_city"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-5"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Country"
        controlName="delivery_country"
      /> 

    </form>
  </div>
</div>
