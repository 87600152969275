import { Component, OnInit } from '@angular/core';
import { PersonsService } from '../../../services/crm/persons.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule   } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { AddTemplateComponent } from '../../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';


@Component({
  selector: 'financehub-person-add',
  templateUrl: './person-add.component.html',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgFor,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    ReactiveFormsModule,
    AddTemplateComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
})
export class PersonAddComponent implements OnInit {
  constructor(
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,
    private addService: AddService
  ) {
  }

  personForm!: FormGroup;
  config = ProjectSettings;

  ngOnInit() {
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
   }

  create() {
    this.personForm = this.addService.parseToString(this.personForm, this.config.fields.person);
    const newPerson = this.getValuesFromForm();
    this.personsService.savePerson(newPerson).subscribe(({ data, loading }) => {
      this.personForm.reset();
      this.personForm = this.addService.createFormGroup(this.config.fields.person);
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdPerson } });
    });
  }

  getValuesFromForm() {
    const formValues = this.personForm.getRawValue();
    return {
      ...formValues,  
      companies: [],
      addresses: []
    };
  }

 
}
