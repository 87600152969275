import { Component, OnInit} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ListTemplateComponent } from 'src/app/components/list-template/list-template.component';
import { ListParentComponent } from 'src/app/components/list-parent/list-parent.component';

@Component({
  selector: 'financehub-address-list',
  templateUrl: './address-list.component.html',
  standalone: true,
  imports: [
    ListTemplateComponent,
    ListParentComponent
  ],
})
export class AddressListComponent extends ListParentComponent implements OnInit {
  
  sortState: Sort = { active: this.config.table.sortBy, direction: 'desc' };

  ngOnInit(): void {
    this.fetchEntities('addresses');
  }
  
  deleteFilter() {
    this.filterTerm = undefined;
    this.refetchEntities('addresses');
  }

  onFilterChange(value: any) {
    this.filterTerm = value;
    this.refetchEntities('addresses');
  }
}
