import { Component, OnInit } from '@angular/core';
import { PersonsService } from '../../../services/crm/persons.service';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { AddTemplateComponent } from '../../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';

@Component({
  selector: 'financehub-person-add',
  templateUrl: './person-add.component.html',
  standalone: true,
  imports: [AddTemplateComponent]
})
export class PersonAddComponent implements OnInit {
  constructor(
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,
    private addService: AddService
  ) {
  }

  personForm!: FormGroup;
  config = ProjectSettings;

  ngOnInit() {
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.replaceBdsNumber();
  }

  create() {
    this.personForm = this.addService.parseToString(this.personForm, this.config.fields.person);
    const newPerson = this.getValuesFromForm();
    this.personsService.savePerson(newPerson).subscribe(({ data, loading }) => {
      this.personForm.reset();
      this.personForm = this.addService.createFormGroup(this.config.fields.person);
      this.replaceBdsNumber();
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdPerson } });
    });
  }

  replaceBdsNumber() {
    this.personsService.getFirstBdsNumber().subscribe(({ data }) => {
      this.personForm.controls['bds_number'].setValue(data.personFirstBdsNumber);
    });
  }

  getValuesFromForm() {
    const formValues = this.personForm.getRawValue();
    return {
      ...formValues,  
      companies: [],
      addresses: []
    };
  }

 
}
