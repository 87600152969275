<form [formGroup]="formGroup">
    <mat-checkbox class="!text-sm" *ngIf="type === 'checkbox'" [formControlName]="controlName">{{label}}</mat-checkbox>
    <mat-form-field *ngIf="type !== 'checkbox'" class="w-full !text-sm" appearance="outline" (click)="navigateToLink(type, formGroup.get(controlName))">
      <mat-label>{{ label }}</mat-label>
        <input
          class="!ring-0"
          *ngIf="type === 'text' || type === 'number' || type === 'link'"
          [readonly]="readonly"
          [formControlName]="controlName"
          matInput
        />
      <textarea
        class="!h-16 !resize-none !ring-0"
        *ngIf="type === 'textarea'"
        [readonly]="readonly"
        [formControlName]="controlName"
        matInput
      ></textarea>

      <mat-select (selectionChange)="onSelectionChange($event)" *ngIf="type === 'select'" [formControlName]="controlName">
        <mat-option *ngFor="let element of optionsArray; let i = index" [value]="optionValue ? element[optionValue] : element">{{ optionValue ? element[optionValue] : element }}</mat-option>
      </mat-select>

      <div *ngIf="type === 'datepicker'" class="flex items-center">
        <input [matDatepicker]="picker" matInput [formControlName]="controlName"/>
        <mat-datepicker-toggle [for]="picker" matIconSuffix class="flex"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
      </div>

      <div *ngIf="type === 'autocomplete'">
        <input [matAutocomplete]="auto" (keyup)="onAutocompleteChange($event)" [formControlName]="controlName" [readonly]="readonly" matInput />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayWith"
          (optionSelected)="onAutocompleteSelected($event)"
          panelWidth="auto"
        >
          <mat-option *ngFor="let option of optionsArray" [value]="optionValue ? option[optionValue] : option">{{ option[displayField] }}<span *ngIf="displayFieldSecondary"> {{ option[displayFieldSecondary] }}</span></mat-option>
        </mat-autocomplete>
      </div>

      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['required']">required</mat-error>
      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['email']">{{ label }} not valid</mat-error>
      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['pattern']">only numbers possible</mat-error>
    </mat-form-field>
</form>