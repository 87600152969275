import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { GLOBAL_RX_STATE, GlobalState, Project } from '../../global-state';
import { RxState } from '@rx-angular/state';
import { AsyncPipe, CommonModule } from '@angular/common';
import { getInitials } from '../../utils/common';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { APP_VERSION, APP_DEPLOYMENT_DATE } from '../../settings';

@Component({
  selector: 'financehub-bds421-com-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AsyncPipe, RxFor, RxLet],
})
export class DashboardComponent {
  readonly current_project$ = this.state.select('current_project');
  readonly projects$ = this.state.select('projects').pipe(map((projects) => projects.filter((x) => x.visible === true)));
  appVersion = APP_VERSION;
  appDeploymentDate = APP_DEPLOYMENT_DATE;

  constructor(
    @Inject(GLOBAL_RX_STATE) private state: RxState<GlobalState>,
    private router: Router,
  ) {}

  public selectProject(project: Project) {
    this.router.navigate(['/' + project.id]);
  }

  trackItem(_: unknown, item: Project) {
    return item.id;
  }

  protected readonly getInitials = getInitials;
}
