import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InvoiceService } from '../../services/finance/invoice.service';
import { InvoiceSumsComponent } from '../../components/invoice-sums/invoice-sums.component';
import { DatePipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import { InvoicePositionsComponent } from '../../components/invoice-positions/invoice-positions.component';
import { InvoiceTextsComponent } from '../../components/invoice-texts/invoice-texts.component';
import { InvoiceProjectComponent } from '../../components/invoice-project/invoice-project.component';
import { InvoiceCustomerComponent } from '../../components/invoice-customer/invoice-customer.component';
import { InvoiceDetailsComponent } from '../../components/invoice-details/invoice-details.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { AddressesService } from '../../services/crm/addresses.service';
import { CompanyService } from '../../services/crm/company.service';
import { CalculateService } from '../../services/finance/calculate.service';
import { SnackbarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
import { FinancialService } from '../../services/finance/financial.service';
import { ProjectSettings } from 'src/assets/config/project-config';
import { OfferService } from 'src/app/services/finance/offer.service';

@Component({
  selector: 'financehub-invoice',
  templateUrl: './invoice.component.html',
  standalone: true,
  imports: [
    FormsModule,
    InvoiceDetailsComponent,
    InvoiceCustomerComponent,
    InvoiceProjectComponent,
    InvoiceTextsComponent,
    InvoicePositionsComponent,
    NgClass,
    InvoiceSumsComponent,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgIf,
    MatToolbarModule,
    MatSnackBarModule
  ],
  providers: [DecimalPipe],
})
export class InvoiceComponent implements OnInit {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    private addressService: AddressesService,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public companyService: CompanyService,
    public calculateService: CalculateService,
    private decimalPipe: DecimalPipe,
    private router: Router,
    private offerService: OfferService
  ) {
    this.route.data.subscribe((params) => {
      this.financialService.isEdit = params['isEdit'];
      this.financialService.isInvoice = params['name'].toLowerCase().includes('invoice')
    });
  }

  config = ProjectSettings;

  @ViewChild('scrollTarget', { read: ElementRef }) scrollTarget!: ElementRef;

  ngOnInit() {
    this.resetInvoice();
    if (this.financialService.isEdit) {
      this.getInvoice();
    }
  }

  resetInvoice() {
    this.financialService.invalidForm = false;
    this.financialService.filteredInvoiceAddresses = [];
    this.financialService.filteredInvoiceAddressesOriginal = [];
    this.financialService.filteredDeliveryAddresses = [];
    this.financialService.filteredDeliveryAddressesOriginal = [];
  }

  getInvoice() {
    this.route.paramMap
      .pipe(
        concatMap((params) => {
          const id = params.get('id') || '';
          if (this.financialService.isInvoice) {
            return this.invoiceService.getInvoice(id);
          } else {
            return this.offerService.getOffer(id);
          }
        }),
        concatMap(({ data, loading }) => {
          this.invoiceService.isEditInvoice = this.financialService.isInvoice ? data.invoice : data.offer;
          if (data.invoice.person_id)
            this.financialService.isEditPerson = data.invoice.person;
          return this.addressService.getAddress(this.invoiceService.isEditInvoice.delivery_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditDeliveryAddress = data.address;
          return this.addressService.getAddress(this.invoiceService.isEditInvoice.invoice_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditInvoiceAddress = data.address;
          return this.companyService.getCompany(this.invoiceService.isEditInvoice.customer_id);
        }),
      )
      .subscribe(({ data, loading }) => {
        this.financialService.isEditCompany = data.company
          ? data.company
          : {
              name_company: '',
              uuid: '',
              bds_number: '',
              uid: '',
              company_register_number: '',
              delivery_addresses: [],
              invoice_addresses: [],
            };
        this.setFormValues();
      });
  }

  setFormValues() {
    this.financialService.detailsForm = this.invoiceService.setDetailsValues();
    const customer = this.financialService.detailsForm.get('customer')?.value;
    this.financialService.filteredInvoiceAddresses = [...customer.invoice_addresses];
    this.financialService.filteredInvoiceAddressesOriginal = customer.invoice_addresses;
    this.financialService.filteredDeliveryAddresses = [...customer.delivery_addresses];
    this.financialService.filteredDeliveryAddressesOriginal = customer.delivery_addresses;
    this.financialService.filteredPersons = [...customer.persons];
    this.financialService.filteredPersonsOriginal = customer.persons;
    this.financialService.customerForm = this.financialService.setCustomerValues();
    this.financialService.projectForm = this.invoiceService.setProjectValues();
    this.financialService.textsForm = this.invoiceService.setTextsValues();
    this.setLineItemsValues();
    this.calculateService.calcSubtotal();
    this.calculateService.calcTaxes();
  }

  setLineItemsValues() {
    let items = this.financialService.isInvoice ? 'invoice_line_items' : 'offer_line_items';
    this.financialService.lineItems.controls.splice(0, 1);
    for (let i = 0; i < this.invoiceService.isEditInvoice[items].length; i++) {
      const item = this.invoiceService.isEditInvoice[items][i];
      const lineItemGroup = this.invoiceService.fb.group({
        _id: [item._id],
        title: [item.title, Validators.required],
        description: [item.description],
        quantity: [item.quantity, Validators.required],
        unitPrice: [item.unitPrice, Validators.required],
        taxPerc: [item.taxPerc, Validators.required],
        singleSum: [{ value: this.setSingleSum(item), disabled: true }, Validators.required],
      });
      this.financialService.lineItems.push(lineItemGroup);
    }
  }

  setSingleSum(item: any) {
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'de-DE');
    } else {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'en-US');
    }
  }

  async submit() {
    if (this.formIsValid()) {
      if (this.financialService.isInvoice) {
        if (this.financialService.isEdit) {
          this.processInvoiceUpdate();
        } else {
          this.processInvoiceSave();
        }
      } else {
        if (this.financialService.isEdit) {
          this.processOfferUpdate();
        } else {
          this.processOfferSave();
        }
      }
      this.financialService.invalidForm = false;
    } else {
      this.processFormIsInvalid();
    }
  }

  formIsValid() {
    return (
      this.financialService.detailsForm.valid &&
      this.financialService.customerForm.valid &&
      this.financialService.lineItemsForm.valid
    );
  }

  processInvoiceUpdate() {
    this.invoiceService.updateInvoice(this.invoiceService.isEditInvoice._id).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedInvoice} });
      this.router.navigate(['/invoices']);
    });
  }

  processInvoiceSave() {
    this.invoiceService.saveInvoice().subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdInvoice } });
      this.router.navigate(['/invoices']);
    });
  }


  processOfferUpdate() {
    this.offerService.updateOffer(this.invoiceService.isEditInvoice._id).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedOffer} });
      this.router.navigate(['/offers']);
    });
  }

  processOfferSave() {
    this.offerService.saveOffer().subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message:  this.config.snackbar.createdOffer } });
      this.router.navigate(['/offers']);
    });
  }

  processFormIsInvalid() {
    this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.incompleteInvoice } });
    this.financialService.detailsForm.markAllAsTouched();
    this.financialService.customerForm.markAllAsTouched();
    this.financialService.lineItemsForm.markAllAsTouched();
    this.financialService.invalidForm = true;
  }
}
