import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Address } from '../../../interfaces/address';
import { Company } from '../../../interfaces/company';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { Subscription } from 'rxjs';
import { PersonsService } from '../../../services/crm/persons.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgClass, DatePipe, NgFor } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputTemplateComponent } from '../../../components/input-template/input-template.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';

@Component({
  selector: 'financehub-person-detail',
  templateUrl: './person-detail.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    MatExpansionModule,
    NgClass,
    FormsModule,
    MatTableModule,
    MatSortModule,
    DatePipe,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputTemplateComponent,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSnackBarModule,
    NgFor,
    MatDialogModule,
    DetailTemplateComponent
  ],
})
export class PersonDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private personsService: PersonsService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: Dialog,
  ) {
  }

  private querySubscription!: Subscription;
  config!: any;
  uuid!: string;
  personForm!: FormGroup;
  person!: any;
  editPersonActive = false;

  ngOnInit() {
    this.config = ProjectSettings;
    this.createOrUpdatePersonForm();
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.personsService.getPerson(this.uuid).subscribe(({ data, loading }) => {
        this.person = { ...data.person };
        this.createOrUpdatePersonForm(this.person);
      });
    });
  }

  createOrUpdatePersonForm(person: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.person.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = person[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.personForm = new FormGroup(formGroupConfig);
  }

  editPerson() {
    this.editPersonActive = true;
    this.enableFields();
  }

  enableFields() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.personForm.get(controlName)?.enable() : null;
    })
  }

  updatePerson() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      const controlValue = this.personForm.get(controlName)?.value;
      const type = this.config.fields.person.find((f: any) => f.controlName === controlName).type;
      this.person[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.personsService.updatePerson(this.uuid, this.person).subscribe(({ data, loading }) => {
      this.editPersonActive = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedPerson} });
    });
    this.editPersonActive = false;
    this.disableFields();
  }

  disableFields() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.personForm.get(controlName)?.disable() : null;
    })
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

}
