import { Component, OnInit } from '@angular/core';
import { AddressesService } from '../../../services/crm/addresses.service';
import { FormGroup } from '@angular/forms';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { AddTemplateComponent } from '../../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';

@Component({
  selector: 'financehub-address-add',
  templateUrl: './address-add.component.html',
  standalone: true,
  imports: [AddTemplateComponent]
})
export class AddressAddComponent implements OnInit {
  constructor(
    private addressesService: AddressesService,
    private _snackBar: MatSnackBar,
    private addService: AddService
  ) {
  }

  addressForm!: FormGroup;
  config = ProjectSettings;
  
  ngOnInit() {
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.replaceBdsNumber();
   }

  create() {
    this.addressForm = this.addService.parseToString(this.addressForm, this.config.fields.address);
    const newAddress = this.getValuesFromForm();
    this.addressesService.saveAddress(newAddress).subscribe(({ data, loading }) => {
      this.addressForm.reset();
      this.addressForm = this.addService.createFormGroup(this.config.fields.address);
      this.replaceBdsNumber();
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdAddress } });
    });
  }

  replaceBdsNumber() {
    this.addressesService.getFirstBdsNumber().subscribe(({ data }) => {
      this.addressForm.controls['bds_number'].setValue(data.addressFirstBdsNumber);
    });
  }

  getValuesFromForm() {
    const formValues = this.addressForm.getRawValue();
    return {
      ...formValues,  
      companies: [],
      persons: []
    };
  }
}
