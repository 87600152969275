import { NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InputTemplateComponent } from '../input-template/input-template.component';


@Component({
  selector: 'financehub-add-template',
  templateUrl: './add-template.component.html',
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    NgIf
  ],
})
export class AddTemplateComponent{
  @Input() formGroup!: FormGroup;
  @Input() entity!: string;
  @Input() create!: () => void;
  @Input() array!: any[];
  @Input() createButton: any;
  @Input() disabledButton: any;
  @Input() optionsArray: any;
  @Input() displayWith: any;
  @Input() displayField: any;
  @Input() displayFieldSecondary: any;
  @Output() autoChanged = new EventEmitter();
  @Output() autoSelected = new EventEmitter();

  onAutocompleteChange(event: any) {
    this.autoChanged.emit(event);
  }

  onAutocompleteSelected(event: any) {
    this.autoSelected.emit(event);
  }
}
