import { Component} from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule, Sort } from '@angular/material/sort';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogModule } from '@angular/cdk/dialog';
import { ListTemplateComponent } from 'src/app/components/list-template/list-template.component';
import { InputTemplateComponent } from 'src/app/components/input-template/input-template.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AddressListComponent } from '../address/address-list/address-list.component';
import { PersonListComponent } from '../person/person-list/person-list.component';
import { CompanyListComponent } from '../company/company-list/company-list.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { PersonDetailComponent } from '../person/person-detail/person-detail.component';
import { AddressDetailComponent } from '../address/address-detail/address-detail.component';
import { CompanyDetailComponent } from '../company/company-detail/company-detail.component';

@Component({
  selector: 'financehub-client-list',
  templateUrl: './client-list.component.html',
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    RouterLink,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    ListTemplateComponent,
    DialogModule,
    MatSnackBarModule,
    AddressListComponent,
    PersonListComponent,
    CompanyListComponent,
    PersonDetailComponent,
    AddressDetailComponent,
    CompanyDetailComponent
  ],
})
export class ClientListComponent {

  constructor(
    public financialService: FinancialService
  ) {

  }
}
