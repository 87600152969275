<div class="grid grid-cols-12 grid-rows-auto">
  <div class="col-start-4 col-span-6 text-3xl text-primary font-light w-full px-4 2xl:col-start-5 2xl:col-span-4"><span class="text-start">Create Address</span></div>
  <financehub-add-template
    class="relative top-10 col-start-4 col-span-6 2xl:col-start-5 2xl:col-span-4"
    [formGroup]="addressForm" 
    [create]="create.bind(this)" 
    [array]="config.fields.address"
    [createButton]="config.button.createAddress"
    [disabledButton]="addressForm.invalid"
  />
</div>