import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { CsvImportService } from 'src/app/services/finance/csv-import.service';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from 'src/app/components/snackbar-template/snackbar-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropTemplateComponent } from 'src/app/components/drag-drop-template/drag-drop-template.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'financehub-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatRadioModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    DragDropTemplateComponent,
    NgForOf
  ],
})
export class CsvImportComponent {
    constructor(
        private csvImportService: CsvImportService,
        private _snackBar: MatSnackBar,
    ) {}

    config = ProjectSettings;
    csvType = 'companies';
    selectedFile!: File;
    options = [
        {value: 'companies', text: 'Companies'},
        {value: 'addresses', text: 'Addresses'},
        {value: 'persons', text: 'Persons'},
        {value: 'addresses-to-companies', text: 'Addresses To Companies'},
        {value: 'persons-to-companies', text: 'Persons To Companies'},
        {value: 'invoice-data', text: 'Invoices'},
        {value: 'invoice-line-items', text: 'Invoice Line Items'},
    ]


    fileSelected(file: File) {
        this.selectedFile = file;
    }

    importCsvData() {
        this.csvImportService.importCsvData(this.csvType, this.selectedFile).subscribe(() => {
            this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.csvImportSuccessful } });
        });
    }

    resetCollection() {
        if (confirm(`Are you sure you want to reset the collection ${this.csvType}`)) {
            this.csvImportService.resetCollection(this.csvType).subscribe(() => {
                this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.collectionReset } });
            });
        }
    }
}
