<form>
  <div class="flex justify-center">
    <form
      class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
      [formGroup]="financialService.customerForm"
    >
      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="UUID"
        controlName="uuid"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="bds-Nummer"
        controlName="bds_number"
      />

      <financehub-input-template
        [formGroup]="financialService.customerForm"
        label="UID"
        controlName="uid"
      />

      <financehub-input-template
        [formGroup]="financialService.customerForm"
        label="Firmenbuchnummer"
        controlName="company_register_number"
      />

      <financehub-input-template
        [formGroup]="financialService.customerForm"
        [optionsArray]="financialService.filteredPersons"
        [readonly]="!financialService.filteredPersons || financialService.filteredPersons.length === 0"
        [displayWith]="displayPersonName"
        (autocompleteChanged)="onPersonChange($event)"
        label="Person"
        controlName="person_id"
        type="autocomplete"
        displayField="first_name"
        displayFieldSecondary="last_name"
      />

      <span class="a-flex-center text-center text-base lg:col-span-2 lg:col-start-1">
        <mat-icon>euro</mat-icon><span>&nbsp;Rechnungsadresse</span>
      </span>
      <br/>

      <financehub-input-template
        class="lg:col-start-1 lg:col-span-2"
        [optionsArray]="financialService.filteredInvoiceAddresses"
        [formGroup]="financialService.customerForm"
        (autocompleteChanged)="onInvoiceAddressChange($event)"
        (autocompleteSelected)="onInvoiceAddressSelected($event)"
        displayField="name"
        type="autocomplete"
        label="Suche"
        controlName="invoice_search"
        optionValue="uuid"
      />

      <financehub-input-template
        class="lg:col-span-2 lg:col-start-1"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Name"
        controlName="invoice_name"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Straße"
        controlName="invoice_street"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Hausnummer"
        controlName="invoice_house_number"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Stadt"
        controlName="invoice_city"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="PLZ"
        controlName="invoice_zip"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Land"
        controlName="invoice_country"
      />

      <span class="a-flex-center text-center text-base lg:col-span-2 lg:col-start-1">
        <mat-icon>euro</mat-icon><span>&nbsp;Lieferadresse</span>
      </span>
      <br/>

      <financehub-input-template
        class="lg:col-start-1 lg:col-span-2"
        [optionsArray]="financialService.filteredDeliveryAddresses"
        [formGroup]="financialService.customerForm"
        (autocompleteChanged)="onDeliveryAddressChange($event)"
        (autocompleteSelected)="onDeliveryAddressSelected($event)"
        displayField="name"
        type="autocomplete"
        label="Suche"
        controlName="delivery_search"
        optionValue="uuid"
      />

      <financehub-input-template
        class="lg:col-span-2 lg:col-start-1"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Name"
        controlName="delivery_name"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Straße"
        controlName="delivery_street"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Hausnummer"
        controlName="delivery_house_number"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Stadt"
        controlName="delivery_city"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="PLZ"
        controlName="delivery_zip"
      />

      <financehub-input-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Land"
        controlName="delivery_country"
      /> 

    </form>
  </div>
</form>
