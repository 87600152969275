import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/crm/company.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputTemplateComponent } from '../../../components/input-template/input-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { AddTemplateComponent } from '../../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';

@Component({
  selector: 'financehub-company-add',
  templateUrl: './company-add.component.html',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    InputTemplateComponent,
    MatSnackBarModule,
    NgFor,
    AddTemplateComponent
  ],
})
export class CompanyAddComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private _snackBar: MatSnackBar,
    private addService: AddService
  ) {}

  config = ProjectSettings;
  companyForm!: FormGroup;

  ngOnInit() {
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
  }

  create() {
    this.companyForm = this.addService.parseToString(this.companyForm, this.config.fields.company);
    const newCompany = this.getValuesFromForm();
    this.companyService.saveCompany(newCompany).subscribe(({ data, loading }) => {
      this.companyForm.reset();
      this.companyForm = this.addService.createFormGroup(this.config.fields.company);
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdCompany } });
    });
  }

  getValuesFromForm() {
    const formValues = this.companyForm.getRawValue();
    return {
      ...formValues,  
      addresses: [],
      persons: []
    };
  }
}
