<div class="grid grid-cols-12 grid-rows-auto">
  <div class="col-span-12 text-3xl text-primary font-light w-full"><span class="text-start">Edit Address</span></div>
  <financehub-detail-template
    class="relative top-10 col-span-12"
    [formGroup]="addressForm" 
    (clickedUpdate)="updateAddress()"
    (clickedEdit)="editAddress()"
    [array]="config.fields.address"
    [editButton]="config.button.editAddress"
    [saveButton]="config.button.saveAddress"
    [editActive]="editAddressActive"
  />
</div>