<div class="!bg-background min-h-screen py-10 px-[10vw] flex flex-col items-center">
  <div class="text-primary font-light w-full mb-5 flex justify-between">
    <span class="text-start text-3xl">All Invoices</span>
    <mat-form-field class="mat-form-field-normal font-medium !text-sm hide-fields w-2/5 lg:w-2/5" appearance="outline">
      <mat-label>{{config.table.filter}}</mat-label>
      <input
        [(ngModel)]="filterTerm"
        (keyup)="refetchInvoices()"
        matInput
      />
      <mat-icon
        class="cursor-pointer !p-1"
        *ngIf="filterTerm"
        (click)="clearFilterAndRefetch()"
        matSuffix
        >close</mat-icon
      >
    </mat-form-field>
  </div>
  <div class="overflow-x-auto w-full">
    <table [dataSource]="dataSource" mat-table matSort [matSortActive]="sortState.active" [matSortDirection]="sortState.direction" (matSortChange)="resetPaginationAndRefetch()">
      <ng-container matColumnDef="invoice_number">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">Invoice Nr.</th>
        <td *matCellDef="let element" mat-cell>{{ element.invoice_number }}</td>
      </ng-container>
      <ng-container matColumnDef="invoice_date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by invoiceDate">Invoice Date</th>
        <td *matCellDef="let element" mat-cell>{{ element.invoice_date | date }}</td>
      </ng-container>
      <ng-container matColumnDef="due_date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by dueDate">Due Date</th>
        <td *matCellDef="let element" mat-cell>{{ element.due_date | date }}</td>
      </ng-container>
      <ng-container matColumnDef="customer_id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by customerID">Client</th>
        <td *matCellDef="let element" mat-cell>
          <div
            class="line-clamp-2"
            [matTooltip]="
              element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')'
            "
          >
            {{ element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="subtotal">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by net">Net</th>
        <td class="!text-end" *matCellDef="let element" mat-cell>
          {{ element.subtotal | number: '1.2-2' : 'de-DE' }}&nbsp;€
        </td>
      </ng-container>
      <ng-container matColumnDef="gross">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by gross">Gross</th>
        <td class="!text-end" *matCellDef="let element" mat-cell>
          {{ element.subtotal + element.total_taxes | number: '1.2-2' : 'de-DE' }}&nbsp;€
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by status">Status</th>
        <td *matCellDef="let element; let even = even" mat-cell (click)="$event.stopPropagation()">
          <mat-form-field class="invoice-list-status-select hide-field !mb-0" appearance="outline">
            <select matNativeControl required (ngModelChange)="statusChanged($event, element._id)" [(ngModel)]="element.status">
              <option [value]="availableStatus.type" *ngFor="let availableStatus of availableStatuses(element.status)">{{availableStatus.title}}</option>
            </select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="icon">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <div class="flex flex-col justify-end xs:flex-row">
            <mat-icon class="mr-3 cursor-pointer" (click)="$event.stopPropagation(); downloadPdf(element)">picture_as_pdf</mat-icon>
            <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../invoice/' + element._id">edit</mat-icon>
            <mat-icon class="cursor-pointer" (click)="$event.stopPropagation(); openDeleteDialog(element)">delete</mat-icon>
          </div>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let element; let even = even; columns: displayedColumns" class="!bg-background cursor-pointer hover:!bg-white" mat-row [routerLink]="'../invoice/' + element._id" 
      ></tr>
    </table>
    <mat-paginator (page)="refetchInvoices()" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>




<!-- <mat-tab-group class="flex w-full" #matTabGroup>
  <mat-tab
    *ngFor="let status of statusService.invoiceStatuses; index as i"
    [label]="getTabLabel(status, i)"
    [labelClass]="status.type"
  >
    <div class="w-full lg:w-4/5">
      <div class="my-5 flex items-center justify-between px-5 lg:px-0">
        <mat-form-field class="hide-field w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>{{config.table.filter}}</mat-label>
          <input
            [(ngModel)]="financialService.filterValues[status.type]"
            (keyup)="financialService.applyFilter($event, dataSources[status.type])"
            matInput
          />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="financialService.filterValues[status.type]"
            (click)="financialService.deleteValue(status.type, dataSources[status.type])"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
        <button class="w-2/5" [routerLink]="'../invoice/create'" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>{{config.button.addInvoice}}
        </button>
      </div>
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table [dataSource]="dataSources[status.type]" mat-table matSort>
          <ng-container matColumnDef="number">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">Re.nr.</th>
            <td *matCellDef="let element" mat-cell>{{ element.invoice_number }}</td>
          </ng-container>
          <ng-container matColumnDef="invoiceDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by invoiceDate">Re.dat.</th>
            <td *matCellDef="let element" mat-cell>{{ element.invoice_date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="dueDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by dueDate">Fk.dat.</th>
            <td *matCellDef="let element" mat-cell>{{ element.due_date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="customerID">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by customerID">Kunde</th>
            <td *matCellDef="let element" mat-cell>
              <div
                class="line-clamp-2"
                [matTooltip]="
                  element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')'
                "
              >
                {{ element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="net">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by net">Netto</th>
            <td class="!text-end" *matCellDef="let element" mat-cell>
              {{ element.subtotal | number: '1.2-2' : 'de-DE' }}&nbsp;€
            </td>
          </ng-container>
          <ng-container matColumnDef="gross">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by gross">Brutto</th>
            <td class="!text-end" *matCellDef="let element" mat-cell>
              {{ element.subtotal + element.total_taxes | number: '1.2-2' : 'de-DE' }}&nbsp;€
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by status">Status</th>
            <td *matCellDef="let element; let even = even" mat-cell>
              <div class="custom-select">
                <mat-select
                  class="hide-field border-0"
                  id=""
                  [ngClass]="{ 'bg-gray-200': even }"
                  (selectionChange)="statusChanged($event.value, element._id)"
                  name=""
                >
                  <mat-option
                    class="w-40"
                    *ngFor="let availableStatus of availableStatuses(status.type)"
                    [value]="availableStatus.type"
                  >
                    {{ availableStatus.title }}
                  </mat-option>
                </mat-select>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <div class="flex flex-col justify-end xs:flex-row">
                <mat-icon class="mr-3 cursor-pointer" (click)="downloadPdf(element)">picture_as_pdf</mat-icon>
                <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../invoice/' + element._id">edit</mat-icon>
                <mat-icon class="cursor-pointer" (click)="openDeleteDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ '!bg-gray-200': even }" mat-row></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </mat-tab>
</mat-tab-group> -->
