import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Orb } from '@memgraph/orb';
import { CompanyService } from '../services/crm/company.service';
import { Subscription } from 'rxjs';
import { Company } from '../interfaces/company';
import { Address } from '../interfaces/address';
import { Person } from '../interfaces/person';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { defaultConfig, FilterConfig } from '../interfaces/filter-config';

@Component({
  selector: 'financehub-orb-demo',
  templateUrl: './orb-demo.component.html',
  styleUrls: ['./orb-demo.component.scss'],
  standalone: true,
  imports: [MatSelectModule, FormsModule],
})
export class OrbDemoComponent implements OnInit, OnDestroy {
  @Input() companyUuid: string | null = null;
  container!: HTMLElement;

  nodes: any[] = [];
  edges: any[] = [];
  orb: any;
  selectedGraphTypes: string[] = ['supplyChain', 'customerSegmentation'];
  driver: any;
  filterConfig: FilterConfig = defaultConfig;
  private querySubscription!: Subscription;

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.querySubscription = this.companyService.getCompanies(this.filterConfig).subscribe(({ data, loading }) => {
      let nodeId = '';
      let edgeId = 0;
      const companies = this.companyUuid ? data.companies.data.filter((c: Company) => c.uuid === this.companyUuid) : data.companies.data;

      companies.forEach((company: any, index: number) => {
        nodeId = company.uuid;
        const companyId = nodeId;
        this.nodes.push({
          id: nodeId,
          label: company.name_company,
          type: 'company',
          graphType: index % 2 === 0 ? 'supplyChain' : 'customerSegmentation',
        });
        company.invoice_addresses.forEach((addr: Address) => {
          nodeId = addr.uuid + '_invoice';
          edgeId++;
          if (this.nodes.findIndex((n) => n.id === nodeId && n.type === 'invoice address') === -1)
            this.nodes.push({ id: nodeId, label: `${addr.street} ${addr.house_number}`, type: 'invoice address' });
          this.edges.push({ id: edgeId, start: nodeId, end: companyId });
        });
        company.delivery_addresses.forEach((addr: Address) => {
          nodeId = addr.uuid + '_delivery';
          edgeId++;
          if (this.nodes.findIndex((n) => n.id === nodeId && n.type === 'delivery address') === -1)
            this.nodes.push({ id: nodeId, label: `${addr.street} ${addr.house_number}`, type: 'delivery address' });
          this.edges.push({ id: edgeId, start: nodeId, end: companyId });
        });
        company.persons.forEach((person: Person) => {
          nodeId = person.uuid;
          edgeId++;
          if (this.nodes.findIndex((n) => n.id === nodeId) === -1)
            this.nodes.push({ id: nodeId, label: `${person.first_name} ${person.last_name}`, type: 'person' });
          this.edges.push({ id: edgeId, start: nodeId, end: companyId });
        });
        company.invoices.forEach((invoice: any) => {
          nodeId = invoice.invoice_number;
          edgeId++;
          if (this.nodes.findIndex((n) => n.id === nodeId) === -1) {
            this.nodes.push({ id: nodeId, label: invoice.title, type: 'invoice' });

            if (
              this.nodes.findIndex((n) => n.id === invoice.delivery_address_id + '_delivery' && n.type === 'delivery address') ===
              -1
            )
              this.nodes.push({
                id: invoice.delivery_address_id + '_delivery',
                label: 'Removed address',
                type: 'delivery address',
              });

            this.edges.push({ id: edgeId, start: nodeId, end: invoice.delivery_address_id + '_delivery' });
            edgeId++;

            if (
              this.nodes.findIndex((n) => n.id === invoice.invoice_address_id + '_invoice' && n.type === 'invoice address') === -1
            )
              this.nodes.push({ id: invoice.invoice_address_id + '_invoice', label: 'Removed address', type: 'invoice address' });

            this.edges.push({ id: edgeId, start: nodeId, end: invoice.invoice_address_id + '_invoice' });
            edgeId++;
          }
          this.edges.push({ id: edgeId, start: nodeId, end: companyId });
        });
      });

      console.log('nodes:', this.nodes);
      console.log('edges:', this.edges);

      this.container = document.getElementById('graph') as HTMLElement;
      this.orb = new Orb(this.container);

      this.orb.data.setDefaultStyle({
        getNodeStyle(node: any) {
          let nodeSize = 0;
          switch (node.getEdges().length) {
            case 0:
              nodeSize = 2;
              break;
            case 1:
              nodeSize = 3;
              break;
            case 2:
            case 3:
              nodeSize = 4;
              break;
            case 4:
            case 5:
              nodeSize = 5;
              break;
            default:
              nodeSize = 6;
              break;
          }
          const basicStyle = {
            color: '#DD2222',
            colorHover: '#e7644e',
            fontSize: 3,
            label: node.data.label,
            size: nodeSize,
          };

          if (node.data.type === 'company') {
            return {
              ...basicStyle,
              color: '#22c55e',
              colorHover: '#86efac',
            };
          }

          if (node.data.type === 'invoice address') {
            return {
              ...basicStyle,
              color: '#3b82f6',
              colorHover: '#93c5fd',
            };
          }

          if (node.data.type === 'delivery address') {
            return {
              ...basicStyle,
              color: '#8b5cf6',
              colorHover: '#c4b5fd',
            };
          }

          if (node.data.type === 'person') {
            return {
              ...basicStyle,
              color: '#f59e0b',
              colorHover: '#fcd34d',
            };
          }

          if (node.data.type === 'invoice') {
            return {
              ...basicStyle,
              color: '#f43f5e',
              colorHover: '#fda4af',
            };
          }

          return {
            ...basicStyle,
          };
        },
        getEdgeStyle(edge: any) {
          return {
            color: '#999999',
            colorHover: '#1d1d1d',
            colorSelected: '#1d1d1d',
            fontSize: 3,
            width: 0.3,
            widthHover: 0.9,
            widthSelected: 0.9,
            label: edge.data.label,
          };
        },
      });
      this.renderGraph(this.nodes);
    });
  }

  onGraphTypeChange() {
    const filteredNodes = this.nodes.filter((node: any) => {
      return !node.graphType || this.selectedGraphTypes.includes(node.graphType);
    });
    this.renderGraph(filteredNodes);
  }

  renderGraph(nodes: any[]) {
    // Initialize nodes and edges
    this.orb.data.setup({ nodes: nodes, edges: this.edges });
    // Render and recenter the view
    this.orb.view.render(() => {
      this.orb.view.recenter();
    });
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }
}
