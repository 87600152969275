import { Injectable, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { Address } from '../../interfaces/address';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectSettings } from 'src/assets/config/project-config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  constructor(
    public apollo: Apollo,
    public fb: FormBuilder,
    private router: Router
  ) {}

  projects = ProjectSettings.invoiceProjects;
  products = ProjectSettings.invoiceProducts;
  textsBefore = ProjectSettings.invoicePreTexts;
  textsAfter = ProjectSettings.invoicePostTexts;
  localsOutput = ['de-DE', 'en-EN'];
  localsInput = ['de-DE', 'en-EN'];
  invalidForm = false;
  detailsForm!: FormGroup;
  customerForm!: FormGroup;
  projectForm!: FormGroup;
  textsForm!: FormGroup;
  sumsForm!: FormGroup;
  taxes!: FormArray;
  lineItemsForm!: FormGroup;
  lineItems!: FormArray;
  allPanelsClosed = false;
  filteredCompanies!: any[];
  filteredCompaniesOriginal!: any[];
  filteredAddresses!: any[];
  filteredAddressesOriginal!: any[];
  filteredPersons!: any[];
  filteredPersonsOriginal!: any[];
  customerAuto!: MatAutocomplete;
  lineItemsAuto!: QueryList<MatAutocomplete>;
  filteredInvoiceAddresses: Array<Address> = [];
  filteredInvoiceAddressesOriginal: Array<Address> = [];
  filteredDeliveryAddresses: Array<Address> = [];
  filteredDeliveryAddressesOriginal: Array<Address> = [];
  filterValues: any = {};
  isEdit!: boolean;
  isEditDeliveryAddress: any;
  isEditInvoiceAddress: any;
  isEditCompany: any;
  isEditPerson: any;
  filterValue!: any;
  isInvoice!: boolean;
  entityEdit!: any;
  entityEditUuid!: string;
  editCompany = false;
  editAddress = false;
  editPerson = false;
  
  resetEntity() {
    this.entityEdit = '';
    this.entityEditUuid = '';
    // this.router.navigate(['/clients']);
  }

  setCustomerForm() {
    return new FormGroup({
      uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      bds_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      uid: new FormControl('', [Validators.required]),
      company_register_number: new FormControl(''),
      person_id: new FormControl(''),
      ...this.setCustomerFormInvoice(),
      ...this.setCustomerFormDelivery(),
    });
  }

  setCustomerFormInvoice() {
    return {
      invoice_search: new FormControl('', [Validators.required]),
      invoice_uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_country: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_zip: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_city: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_street: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_house_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_additional_information: new FormControl({ value: '', disabled: true }, []),
    };
  }

  setCustomerFormDelivery() {
    return {
      delivery_search: new FormControl('', [Validators.required]),
      delivery_uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_country: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_zip: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_city: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_street: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_house_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_additional_information: new FormControl({ value: '', disabled: true }, []),
    };
  }

  setProjectForm() {
    return new FormGroup({
      search: new FormControl('ohne', [Validators.required]),
      title: new FormControl({ value: 'ohne', disabled: true }, [Validators.required]),
      number: new FormControl({ value: 100, disabled: true }, [Validators.required]),
      manager: new FormControl({ value: 'Rene Heinzl', disabled: true }, [Validators.required]),
      coManager: new FormControl({ value: 'Rene Heinzl', disabled: true }, [Validators.required]),
    });
  }

  setTextsForm() {
    return new FormGroup({
      textBeforeTitle: new FormControl('leer', [Validators.required]),
      textBefore: new FormControl(''),
      textAfterTitle: new FormControl('leer', [Validators.required]),
      textAfter: new FormControl(''),
    });
  }

  setSumsForm() {
    return this.fb.group({
      subtotal: new FormControl(''),
      taxes: this.fb.array([]),
      totalTaxes: new FormControl(''),
      totalSum: new FormControl(''),
    });
  }

  setLineItemsForm() {
    return this.fb.group({
      lineItems: this.fb.array([
        this.fb.group({
          title: ['', Validators.required],
          description: [''],
          quantity: ['', Validators.required],
          unitPrice: ['', Validators.required],
          taxPerc: ['', Validators.required],
          singleSum: ['', Validators.required],
        }),
      ]),
    });
  }

  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteValue(dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    this.filterValue= '';
  }

  setInvoiceAddressValues(address: any) {
    this.customerForm.get('invoice_search')?.setValue(`${address?.street} ${address?.house_number}`);
    this.customerForm.get('invoice_uuid')?.setValue(address?.uuid);
    this.customerForm.get('invoice_country')?.setValue(address?.country);
    this.customerForm.get('invoice_zip')?.setValue(address?.zip);
    this.customerForm.get('invoice_city')?.setValue(address?.city);
    this.customerForm.get('invoice_street')?.setValue(address?.street);
    this.customerForm.get('invoice_house_number')?.setValue(address?.house_number);
    this.customerForm.get('invoice_additional_information')?.setValue(address?.additional_information);
  }

  setDeliveryAddressValues(address: any) {
    this.customerForm.get('delivery_search')?.setValue(`${address?.street} ${address?.house_number}`);
    this.customerForm.get('delivery_uuid')?.setValue(address?.uuid);
    this.customerForm.get('delivery_country')?.setValue(address?.country);
    this.customerForm.get('delivery_zip')?.setValue(address?.zip);
    this.customerForm.get('delivery_city')?.setValue(address?.city);
    this.customerForm.get('delivery_street')?.setValue(address?.street);
    this.customerForm.get('delivery_house_number')?.setValue(address?.house_number);
    this.customerForm.get('delivery_additional_information')?.setValue(address?.additional_information);
  }

  setCustomerValues() {
    return new FormGroup({
      uuid: new FormControl({ value: this.isEditCompany.uuid, disabled: true }, [Validators.required]),
      bds_number: new FormControl({ value: this.isEditCompany.bds_number, disabled: true }, [Validators.required]),
      uid: new FormControl(this.isEditCompany.uid, [Validators.required]),
      company_register_number: new FormControl(this.isEditCompany.company_register_number),
      person_id: new FormControl(this.isEditPerson),
      ...this.setCustomerValuesInvoice(),
      ...this.setCustomerValuesDelivery(),
    });
  }

  setCustomerValuesInvoice() {
    return {
      invoice_search: new FormControl(`${this.isEditInvoiceAddress.street} ${this.isEditInvoiceAddress.house_number}`, [Validators.required]),
      invoice_uuid: new FormControl({ value: this.isEditInvoiceAddress.uuid, disabled: true }, [Validators.required]),
      invoice_country: new FormControl({ value: this.isEditInvoiceAddress.country, disabled: true }, [Validators.required]),
      invoice_zip: new FormControl({ value: this.isEditInvoiceAddress.zip, disabled: true }, [Validators.required]),
      invoice_city: new FormControl({ value: this.isEditInvoiceAddress.city, disabled: true }, [Validators.required]),
      invoice_street: new FormControl({ value: this.isEditInvoiceAddress.street, disabled: true }, [Validators.required]),
      invoice_house_number: new FormControl({ value: this.isEditInvoiceAddress.house_number, disabled: true }, [
        Validators.required,
      ]),
    };
  }

  setCustomerValuesDelivery() {
    return {
      delivery_search: new FormControl(`${this.isEditDeliveryAddress.street} ${this.isEditDeliveryAddress.house_number}`, [Validators.required]),
      delivery_uuid: new FormControl({ value: this.isEditDeliveryAddress.uuid, disabled: true }, [Validators.required]),
      delivery_country: new FormControl({ value: this.isEditDeliveryAddress.country, disabled: true }, [Validators.required]),
      delivery_zip: new FormControl({ value: this.isEditDeliveryAddress.zip, disabled: true }, [Validators.required]),
      delivery_city: new FormControl({ value: this.isEditDeliveryAddress.city, disabled: true }, [Validators.required]),
      delivery_street: new FormControl({ value: this.isEditDeliveryAddress.street, disabled: true }, [Validators.required]),
      delivery_house_number: new FormControl({ value: this.isEditDeliveryAddress.house_number, disabled: true }, [
        Validators.required,
      ]),
    };
  }

  resetForms() {
    // this.customerAuto.options.forEach((option) => option.deselect());
    // this.customerForm.reset();
    // this.projectForm = this.setProjectForm();
    // this.textsForm = this.setTextsForm();
    // this.lineItemsAuto.forEach((item) => {
    //   item.options.forEach((option) => option.deselect());
    // });
    // this.lineItemsForm.reset();
    // while (this.lineItems.length > 1) {
    //   this.lineItems.removeAt(1);
    // }
    // this.sumsForm.reset();
  }

  getInputs() {
    console.log('getInputs)', this.customerForm)
    return {
      title: this.projectForm.get('title')?.value,
      input_locale: this.detailsForm.get('inputLocale')?.value,
      output_locale: this.detailsForm.get('outputLocale')?.value,
      text_before: this.textsForm.get('textBefore')?.value,
      text_after: this.textsForm.get('textAfter')?.value,
      subtotal: this.sumsForm.get('subtotal')?.value,
      total_taxes: this.sumsForm.get('totalTaxes')?.value,
      customer_id: this.customerForm.get('uuid')?.value,
      customer_uid: this.customerForm.get('uid')?.value,
      project_title: this.projectForm.get('title')?.value,
      project_number: this.projectForm.get('number')?.value,
      project_manager: this.projectForm.get('manager')?.value,
      project_co_manager: this.projectForm.get('coManager')?.value,
      customer_company_register_number: this.customerForm.get('company_register_number')?.value,
      delivery_address_id: this.customerForm.get('delivery_uuid')?.value,
      invoice_address_id: this.customerForm.get('invoice_uuid')?.value,
      person_id: this.customerForm.get('person_id')?.value?.uuid,
    };
  }


  expandedDescription!: number;

  expandDescription(i: number) {
    console.log(i);
    this.expandedDescription = i;
  }
}
