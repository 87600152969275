import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'financehub-drag-drop-template',
  templateUrl: './drag-drop-template.component.html',
  styleUrls: ['./drag-drop-template.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgForOf,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
  ],
})
export class DragDropTemplateComponent {
    
    @Input() accept!: string;
    @Output() fileSelected = new EventEmitter<File>();

    dragDropText = 'Drag & Drop or Click to Upload File';  

    uploadInputChange(event: any) {
        const input = event.target as HTMLInputElement;
        if (input?.files?.length) {
            const file = input.files[0];
            this.dragDropText = file.name;
            this.fileSelected.emit(file);
        } 
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        const dropArea = event.currentTarget as HTMLElement;
        dropArea.classList.add('drag-over');
    }

    onDragLeave(event: DragEvent): void {
        const dropArea = event.currentTarget as HTMLElement;
        dropArea.classList.remove('drag-over');
    }

    onDrop(event: any): void {
        event.preventDefault();
        const dropArea = event.currentTarget as HTMLElement;
        dropArea.classList.remove('drag-over');
        if (event.dataTransfer?.files?.length) {
            const file = event.dataTransfer.files[0];
            this.fileSelected.emit(file);
            this.dragDropText = file.name;
        }
    }

}
