<form #formProject="ngForm">
  <div class="flex justify-center">
    <form
      class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
      [formGroup]="financialService.projectForm"
    >
      <financehub-input-template
        [optionsArray]="filteredProjects"
        [formGroup]="financialService.projectForm"
        (autocompleteChanged)="onProjectChange($event)"
        (autocompleteSelected)="onProjectSelected($event)"
        type="autocomplete"
        label="Suche"
        displayField="title"
        controlName="search"
      />

      <financehub-input-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Name"
        controlName="title"
      />

      <financehub-input-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Projektnummer"
        controlName="number"
      />

      <financehub-input-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 1"
        controlName="manager"
      />

      <financehub-input-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 2"
        controlName="coManager"
      />
    </form>
  </div>
</form>
