import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSortModule, Sort } from '@angular/material/sort';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { DialogTemplateComponent } from '../../../components/dialog-template/dialog-template.component';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { ListTemplateComponent } from 'src/app/components/list-template/list-template.component';
import { InputTemplateComponent } from 'src/app/components/input-template/input-template.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PersonsService } from 'src/app/services/crm/persons.service';

@Component({
  selector: 'financehub-person-list',
  templateUrl: './person-list.component.html',
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    RouterLink,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    ListTemplateComponent,
    DialogModule,
    MatSnackBarModule
  ],
})
export class PersonListComponent implements OnInit {

  config!: any;
  persons: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  private querySubscription!: Subscription;

  @ViewChild(ListTemplateComponent) childComponent!: ListTemplateComponent;

  constructor(
    public dialog: Dialog,
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,

  ) {}


  ngOnInit(): void {
    this.config = ProjectSettings;
    this.fetchEntities();
  }

  fetchEntities() {
    this.querySubscription = this.personsService.getPersons().subscribe(({ data, loading }) => {
      this.persons = data.persons;
      this.sortTable();
    });
  }

   sortTable() {
    this.dataSource = new MatTableDataSource(this.persons);
    this.dataSource.sort = this.childComponent.sort;
    this.dataSource.paginator = this.childComponent.paginator;
    const sortState: Sort = { active: this.config.table.sortBy, direction: 'desc' };
    if (this.dataSource.sort) {
      this.dataSource.sort.active = sortState.active;
      this.dataSource.sort.direction = sortState.direction;
      this.dataSource.sort.sortChange.emit(sortState);
    }
  }

  openDeleteDialog(entity: any): void {
      const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
        width: '500px',
        data: {
          text: `${this.config.dialog.deletePerson} '${entity.first_name}'?`,
          element: entity,
          cancel: this.config.dialog.deleteCancel,
          confirm: this.config.dialog.deleteConfirm
        },
      });
      dialogRef.closed.subscribe((entity) => {
        if (entity) this.delete(entity);
      });
    }
    
    delete(entityToDelete: any) {
      this.querySubscription = this.personsService.deletePerson(entityToDelete.uuid).subscribe(({ data, loading }) => {
        this.persons = this.persons.filter((item: any) => item.uuid !== entityToDelete.uuid);
        this.sortTable();
        this._snackBar.openFromComponent(SnackbarTemplateComponent, 
          { data: { 
            button: this.config.snackbar.confirm, 
            message: this.config.snackbar.deletedPerson } 
          });
      });
    }
  
    deleteFilter() {
      this.dataSource.filter = '';
    }
  
    onFilterChange(value: any) {
      this.dataSource.filter = value.trim().toLowerCase();
    }
  
    ngOnDestroy() {
      this.querySubscription.unsubscribe();
    }
}
