<div class="flex justify-center">
  <form
    class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="financialService.textsForm"
  >
    <financehub-input-template
      *ngIf="!financialService.isEdit"
      [optionsArray]="financialService.textsBefore"
      [formGroup]="financialService.textsForm"
      (selectionChanged)="onTextBeforeSelected($event.value)"
      type="select"
      label="Text Before"
      controlName="textBeforeTitle"
      optionValue="title"
    />

    <financehub-input-template
      [formGroup]="financialService.textsForm"
      label="Text davor"
      controlName="textBefore"
      type="textarea"
    />

    <financehub-input-template
      *ngIf="!financialService.isEdit"
      [optionsArray]="financialService.textsAfter"
      [formGroup]="financialService.textsForm"
      (selectionChanged)="onTextAfterSelected($event.value)"
      type="select"
      label="Text After"
      controlName="textAfterTitle"
      optionValue="title"
    />

    <financehub-input-template
      [formGroup]="financialService.textsForm"
      label="Text danach"
      controlName="textAfter"
      type="textarea"
    />
  </form>
</div>
