import { Component } from '@angular/core';
import { InvoiceService } from '../../services/finance/invoice.service';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinancialService } from '../../services/finance/financial.service';

@Component({
  selector: 'financehub-invoice-sums',
  templateUrl: './invoice-sums.component.html',
  styleUrls: ['./invoice-sums.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, DecimalPipe, FormsModule, ReactiveFormsModule],
})
export class InvoiceSumsComponent {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    public fb: FormBuilder,
  ) {
    this.financialService.sumsForm = this.financialService.setSumsForm();
    this.financialService.taxes = this.financialService.sumsForm.get('taxes') as FormArray;
  }
}
