import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SnowflakeId from 'snowflake-id';
import { AddressesService } from '../../services/crm/addresses.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../assets/config/project-config';
import { AddTemplateComponent } from '../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Company } from 'src/app/interfaces/company';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { InputTemplateComponent } from 'src/app/components/input-template/input-template.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Address } from 'src/app/interfaces/address';
import { Person } from 'src/app/interfaces/person';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'financehub-client-add',
  templateUrl: './client-add-component.html',
  styleUrls: ['./client-add-component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    AddTemplateComponent,
    ReactiveFormsModule,
    MatSnackBarModule,
    NgFor,
    InputTemplateComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule
  ],
})
export class clientAddComponent implements OnInit {
  constructor(
    private addressesService: AddressesService,
    private companyService: CompanyService,
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,
    private addService: AddService,
    public financialService: FinancialService

  ) {
  }

  addressForm!: FormGroup;
  companyForm!: FormGroup;
  personForm!: FormGroup;
  personCompanyForm!: FormGroup;
  addressTypeForm!: FormGroup;

  companySelected = false;
  addressSelected = false;
  personSelected = false;
  
  searchCompanyInput = '';
  searchAddressInput = '';
  searchPersonInput = '';

  invalidAddressType = false;
  invalidForm = false;
  config = ProjectSettings;
  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });

  @ViewChild('companyAuto') companyAuto!: MatAutocomplete;
  @ViewChild('addressAuto') addressAuto!: MatAutocomplete;
  @ViewChild('personAuto') personAuto!: MatAutocomplete;


  ngOnInit() {
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
    this.addressTypeForm.setValidators(this.oneFieldRequiredValidator);
    this.addressTypeForm.updateValueAndValidity();
    this.getCompanies();
    this.getAddresses();
    this.getPersons();
   }

   oneFieldRequiredValidator(group: AbstractControl): ValidationErrors | null {
    const field1 = group.get('invoice')?.value;
    const field2 = group.get('delivery')?.value;
    return (field1 || field2 ) ? null : { required: true };
  }

   //companies
   getCompanies() {
    this.companyService.getCompanies().subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies;
      this.financialService.filteredCompanies = [...data.companies];
      this.sortEntities(this.financialService.filteredCompanies, 'name_company');
    });
   }

   //addresses
   getAddresses() {
    this.addressesService.getAddresses().subscribe(({ data, loading }) => {
      this.financialService.filteredAddressesOriginal = data.addresses;
      this.financialService.filteredAddresses = [...data.addresses];
      this.sortEntities(this.financialService.filteredAddresses, 'name');
    });
  }

  //persons
  getPersons() {
    this.personsService.getPersons().subscribe(({ data, loading }) => {
      this.financialService.filteredPersonsOriginal = data.persons;
      this.financialService.filteredPersons = [...data.persons];
      this.sortEntities(this.financialService.filteredPersons, 'last_name');
    });
  }

  sortEntities(array: any, name: any) {
    const sortedArray = array.sort((a:any, b:any) => {
      const nameA = a[name].toUpperCase();
      const nameB = b[name].toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  //companies
  displayCompanyName(company: Company) {
    return company ? company.name_company : '';
  }

  //addresses
  displayAddressName(address: Address) {
    return address ? address.name : '';
  }
  
  //persons
  displayPersonName(person: Person) {
    return person ? person.last_name + ' ' + person.first_name : '';
  }

  //companies
  clearCompanyInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchCompanyInput = '';
      this.searchCompanyChanged('');
    }
  }

  //addresses
  clearAddressInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchAddressInput = '';
      this.searchAddressChanged('');
    }
  }

  //persons
  clearPersonInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.searchPersonInput = '';
      this.searchPersonChanged('');
    }
  }

  //companies
   searchCompanyChanged(event: any) {
    this.deselectOptions(this.companyAuto);
    if (typeof event === 'string') {
      this.financialService.filteredCompanies = this.financialService.filteredCompaniesOriginal.filter((c) =>
        c.name_company.toLowerCase().includes(event.toLowerCase()),
      );
    };
    if (this.companySelected) {
      this.enableFields(this.companyForm);
      this.companyForm = this.addService.createFormGroup(this.config.fields.company);
      this.companySelected = false;
    }
    
  }

  //addresses
  searchAddressChanged(event: any) {
    this.deselectOptions(this.addressAuto);
    if (typeof event === 'string') {
      this.financialService.filteredAddresses = this.financialService.filteredAddressesOriginal.filter((c) =>
        c.name.toLowerCase().includes(event.toLowerCase()),
      );
    };
    if (this.addressSelected) {
      this.enableFields(this.addressForm);
      this.enableFields(this.addressTypeForm);
      this.addressForm = this.addService.createFormGroup(this.config.fields.address);
      this.addressSelected = false;
    }
  }

  //persons
  searchPersonChanged(event: any) {
    this.deselectOptions(this.personAuto);
    if (typeof event === 'string') {
      this.financialService.filteredPersons = this.financialService.filteredPersonsOriginal.filter((c) =>
        c.last_name.toLowerCase().includes(event.toLowerCase()) || c.first_name.toLowerCase().includes(event.toLowerCase()) 
      || (c.last_name.toLowerCase() + ' ' + c.first_name.toLowerCase()).includes(event.toLowerCase())
      || (c.first_name.toLowerCase() + ' ' + c.last_name.toLowerCase()).includes(event.toLowerCase())
      );
    }
    if (this.personSelected) {
      this.enableFields(this.personForm);
      this.enableFields(this.personCompanyForm);
      this.personForm = this.addService.createFormGroup(this.config.fields.person);
      this.personSelected = false;
    }
  }

  deselectOptions(auto: MatAutocomplete) {
    auto.options.forEach((item) => {
      item.selected && item.deselect();
    });
  }

  //companies
  searchCompanySelected(value: any) {
    this.companyService.getCompany(value.uuid).subscribe(({ data }) => {
      const selectedCompany =  data.company;
      Object.keys(this.companyForm.controls).forEach(controlName => {
        const controlValue = selectedCompany[controlName];
        const type = this.config.fields.company.find((f: any) => f.controlName === controlName)?.type;
        this.companyForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
      });
      this.sortEntities(this.financialService.filteredCompanies, 'name_company');
      this.disableFields(this.companyForm);
      this.companySelected = true;
      if (selectedCompany.invoice_addresses.length > 0) {
        this.searchAddressSelected(selectedCompany.invoice_addresses[0]);
        this.addressTypeForm.controls['invoice'].setValue(true);
        this.addressTypeForm.controls['delivery'].setValue(false);
      } else if (this.addressSelected) {
        this.addressForm = this.addService.createFormGroup(this.config.fields.address);
        this.addressSelected = false;
      }
    });
  }

  //addresses
  searchAddressSelected(value: any) {
    this.addressesService.getAddress(value.uuid).subscribe(({ data }) => {
      const selectedAddress =  data.address
      Object.keys(this.addressForm.controls).forEach(controlName => {
        const controlValue = selectedAddress[controlName];
        const type = this.config.fields.address.find((f: any) => f.controlName === controlName)?.type;
        this.addressForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
      });
      this.sortEntities(this.financialService.filteredAddresses, 'name');
      this.disableFields(this.addressForm);
      this.disableFields(this.addressTypeForm);
      this.addressSelected = true;
    });
  }

  //persons
  searchPersonSelected(value: any) {
    this.personsService.getPerson(value.uuid).subscribe(({ data }) => {
      const selectedPerson =  data.person;
      Object.keys(this.personForm.controls).forEach(controlName => {
        const controlValue = selectedPerson[controlName];
        const type = this.config.fields.person.find((f: any) => f.controlName === controlName)?.type;
        this.personForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
        // this.personCompanyForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
      });
      this.sortEntities(this.financialService.filteredPersons, 'last_name');
      this.disableFields(this.personForm);
      this.disableFields(this.personCompanyForm);
      this.personSelected = true;
    });
  }
  
  enableFields(form: FormGroup) {
    Object.keys(form.controls).forEach(controlName => {
      controlName !== 'uuid' ? form.get(controlName)?.enable() : null;
    })
  }

  disableFields(form: FormGroup) {
    Object.keys(form.controls).forEach(controlName => {
      form.get(controlName)?.disable();
    })
  }


  create() {
    if (this.companyForm.invalid || this.addressForm.invalid || this.personForm.invalid || this.addressTypeForm.invalid) {
      this.processFormIsInvalid();
    } else {
      this.processFormIsValid();
    }
  }

  processFormIsInvalid() {
    this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.incompleteClient } });
    this.companyForm.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    this.personForm.markAllAsTouched();
    this.addressTypeForm.markAllAsTouched();
    this.invalidForm = true;
    console.log(this.addressTypeForm)
  }

  processFormIsValid() {
    // if (!this.companySelected) {
    //   this.addressForm = this.addService.parseToString(this.addressForm, this.config.fields.address);
    //   this.companyForm = this.addService.parseToString(this.companyForm, this.config.fields.company);
    //   this.personForm = this.addService.parseToString(this.personForm, this.config.fields.person);
    //   this.addressesService.saveAddress(this.getEntityFromForm(this.addressForm)).subscribe(({ data, loading }) => {});
    //   this.companyService.saveCompany(this.getEntityFromForm(this.companyForm)).subscribe(({ data, loading }) => {});
    //   this.personsService.savePerson(this.getEntityFromForm(this.personForm)).subscribe(({ data, loading }) => {});
    //   this.addAddressToCompany();
    //   this.addPersonToCompany();
    //   this.resetForms();
    //   this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdClient } });
    // }

    this.addressForm = this.addService.parseToString(this.addressForm, this.config.fields.address);
    this.companyForm = this.addService.parseToString(this.companyForm, this.config.fields.company);
    this.personForm = this.addService.parseToString(this.personForm, this.config.fields.person);

    this.companyService.updateOrCreateCompanyPerson({
      company: this.getEntityFromForm(this.companyForm),
      address: this.getEntityFromForm(this.addressForm),
      company_address: {
        type_delivery: this.addressTypeForm.controls['delivery'].value,
        type_invoice: this.addressTypeForm.controls['invoice'].value,
        address_uuid: this.addressForm.controls['uuid'].value,
      },
      person: this.getEntityFromForm(this.personForm),
      company_person: {
        person_uuid: this.personForm.controls['uuid'].value,
        position: this.personCompanyForm.controls['position'].value,
        phone_office: this.personCompanyForm.controls['phone_office'].value,
        email_office: this.personCompanyForm.controls['mail_office'].value,
      }
    }).subscribe(({ data, loading }) => {
      this.resetForms();
      this.resetSearchInputs();
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdClient } });
    });
  }

  getEntityFromForm(form: FormGroup) {
    const formValues = form.getRawValue();
    return {
      ...formValues
    }
  }

  resetSearchInputs() {
    this.searchCompanyInput = '';
    this.searchAddressInput = '';
    this.searchPersonInput = '';
  }

  resetCompany() {
    this.companyForm.reset();
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.searchCompanyInput = '';
    this.searchCompanyChanged('');
  }

  resetAddress() {
    this.addressForm.reset();
    this.addressTypeForm.reset();
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
    this.searchAddressInput = '';
    this.searchAddressChanged('');
  }

  resetPerson() {
    this.personForm.reset();
    this.personCompanyForm.reset();
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.searchPersonInput = '';
    this.searchPersonChanged('');
  }

  resetForms() {
    this.addressForm.reset();
    this.companyForm.reset();
    this.personForm.reset();
    this.personCompanyForm.reset();
    this.addressTypeForm.reset();
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
    this.companyForm = this.addService.createFormGroup(this.config.fields.company);
    this.personForm = this.addService.createFormGroup(this.config.fields.person);
    this.personCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    this.addressTypeForm = this.addService.createFormGroup(this.config.fields.addressType);
  }
 
}
