import { Injectable } from '@angular/core';
import { status } from '../../interfaces/status';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  invoiceStatuses: status[] = [
    { type: 'draft', title: 'Draft' },
    { type: 'ready', title: 'Ready' },
    { type: 'open', title: 'Open' },
    { type: 'due', title: 'Due' },
    { type: 'paid', title: 'Paid' },
    { type: 'partially paid', title: 'Partially Paid' },
    { type: 'cancelled', title: 'Cancelled' },
  ];

  offerStatuses: status[] = [
    { type: 'draft', title: 'Draft' },
    { type: 'ready', title: 'Ready' },
    { type: 'due', title: 'Due' },
    { type: 'accepted', title: 'Accepted' },
    { type: 'cancelled', title: 'Cancelled' },
  ];
}
