import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CalculateService } from '../../services/finance/calculate.service';
import { CdkDropListGroup, CdkDropList, CdkDrag, DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { InvoiceService } from '../../services/finance/invoice.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAccordion, MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { InvoiceSumsComponent } from '../invoice-sums/invoice-sums.component';
import { FinancialService } from '../../services/finance/financial.service';

@Component({
  selector: 'financehub-invoice-positions',
  templateUrl: './invoice-positions.component.html',
  standalone: true,
  imports: [
    FormsModule,
    CdkDropListGroup,
    MatExpansionModule,
    CdkDropList,
    NgFor,
    MatAutocompleteModule,
    MatOptionModule,
    NgIf,
    CdkDrag,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    InvoiceSumsComponent,
    NgClass,
  ],
})
export class InvoicePositionsComponent implements OnInit, AfterViewInit {
  filteredProducts!: any[];

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;
  @ViewChildren('lineItemsAuto') lineItemsAuto!: QueryList<MatAutocomplete>;
  @Input() scrollTarget!: any;
  @ViewChildren('panel') panel!: QueryList<ElementRef>;

  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    public calculateService: CalculateService,
    public fb: FormBuilder,
  ) {
    this.financialService.lineItemsForm = this.financialService.setLineItemsForm();
    this.financialService.lineItems = this.financialService.lineItemsForm.get('lineItems') as FormArray;
  }

  ngOnInit() {
    this.filteredProducts = this.financialService.products;
    this.sortLineItems();
  }

  ngAfterViewInit(): void {
    this.financialService.lineItemsAuto = this.lineItemsAuto;
  }

  panelsOpened(): boolean {
    if (this.accordion) {
      return this.panels.toArray().some((panel) => !panel.expanded);
    } else {
      return false;
    }
  }

  addContainer() {
    const lineItemGroup = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      quantity: ['', Validators.required],
      unitPrice: ['', Validators.required],
      taxPerc: ['', Validators.required],
      singleSum: ['', Validators.required],
    });
    this.financialService.lineItems.push(lineItemGroup);
    setTimeout(() => {
      if (this.scrollTarget) {
        this.scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 10);
  }

  sortLineItems() {
    const sortedArray = this.filteredProducts.sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onProductChange(event: KeyboardEvent, i: number) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.lineItems.at(i).get('title')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredProducts = this.financialService.products.filter((c) =>
      c.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }

  onProductSelected(event: MatAutocompleteSelectedEvent, i: number) {
    const selectedTitle = event.option.value;
    const selectedProduct = this.financialService.products.find((product) => product.title === selectedTitle);
    this.setValues(i, selectedProduct);
    this.calculateService.calcSums(i);
  }

  setValues(i: number, selectedProduct: any) {
    this.financialService.lineItems.at(i).get('description')?.setValue(selectedProduct.description);
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      this.financialService.lineItems.at(i).get('unitPrice')?.setValue(selectedProduct.unitPrice.toFixed(2).replace('.', ','));
      this.financialService.lineItems.at(i).get('taxPerc')?.setValue(selectedProduct.tax.toFixed(2).replace('.', ','));
    } else if (this.financialService.detailsForm.get('inputLocale')?.value == 'en-EN') {
      this.financialService.lineItems.at(i).get('unitPrice')?.setValue(selectedProduct.unitPrice.toFixed(2));
      this.financialService.lineItems.at(i).get('taxPerc')?.setValue(selectedProduct.tax.toFixed(2));
    }
  }

  deleteLineItem(i: number) {
    this.financialService.lineItems.controls.splice(i, 1);
    this.calculateService.calcSubtotal();
    this.calculateService.calcTaxes();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.financialService.lineItems.controls, event.previousIndex, event.currentIndex);
  }

  scrollToPanel(i: number) {
    // this.panel.toArray()[i].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  onInputDescriptionChange(event: any) {
    if (event.key === 'Escape') {
      this.financialService.expandedDescription = -1;
    }
  }
}
