<mat-sidenav-container class="h-full">
  <mat-sidenav
    class="w-max"
    #drawer
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    fixedInViewport
  >
    <mat-toolbar class="flex justify-center !bg-white !px-1 !text-sm"
      >FINANCE
      <img class="mx-1 h-4 invert" src="../../assets/fc_logo.png" />
      <strong>COMPASS</strong>
    </mat-toolbar>
    <mat-nav-list class="navigation-list">
      <financehub-navigation-template
        [route]="currentRoute"
        title="Dashboard"
        icon="dashboard"
        routeIs="/"
        link=""
      />

      <div mat-subheader>Finance</div>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Invoices"
        icon="receipt_long"
        routeIncludes="invoice"
        link="invoices"
      />

      <financehub-navigation-template
        [route]="currentRoute"
        title="Offers"
        icon="local_offer"
        routeIncludes="offer"
        link="offers"
      />

      <div mat-subheader>CRM</div>

      <financehub-navigation-template
        [route]="currentRoute"
        title="New Client"
        icon="person_add"
        routeIncludes="client"
        link="client/create"
      />


      <financehub-navigation-template
        [route]="currentRoute"
        title="Companies"
        icon="business"
        routeIncludes="compan"
        link="companies"
      />

      <financehub-navigation-template
        [route]="currentRoute"
        title="Contacts"
        icon="people"
        routeIncludes="person"
        link="persons"
      />

      <financehub-navigation-template
        [route]="currentRoute"
        title="Addresses"
        icon="location_on"
        routeIncludes="address"
        link="addresses"
      />

      <financehub-navigation-template
        [route]="currentRoute"
        title="Time tracking"
        icon="access_time"
        routeIncludes="time"
        link="timeTracking"
      />

      <financehub-navigation-template
        [route]="currentRoute"
        title="Graph"
        icon="share"
        routeIncludes="graph"
        link="graph"
      />
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button *ngIf="isHandset$ | async" (click)="drawer.toggle()" type="button" aria-label="Toggle sidenav" mat-icon-button>
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <h2 class="!text-xl">{{ currentRouteName }}</h2>
      <span class="example-spacer"></span>
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>account_circle</mat-icon>
        <span>{{ usercode }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="isLoggedIn" (click)="logout()" mat-menu-item>Sign Out</button>
      </mat-menu>
    </mat-toolbar>

    <div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
