<div class="flex flex-col">
  <div
    class="sticky top-14 z-20 flex h-auto flex-col items-center justify-around bg-gray-700 p-5 text-sm text-gray-100 sm:justify-between sm:text-base"
  >
    <div class="flex w-full justify-between text-end lg:w-4/5">
      <financehub-invoice-sums></financehub-invoice-sums>
      <div class="flex flex-col items-center justify-between">
        <button (click)="addContainer()" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
        <mat-icon class="cursor-pointer" *ngIf="panelsOpened()" (click)="accordion.openAll()">unfold_more</mat-icon>
        <mat-icon class="cursor-pointer" *ngIf="!panelsOpened()" (click)="accordion.closeAll()">unfold_less</mat-icon>
      </div>
    </div>
  </div>

  <mat-accordion class="h-full" #accordion="matAccordion" [multi]="true">
    <form class="my-5 flex w-full flex-col items-center" [formGroup]="financialService.lineItemsForm">
      <div class="a-flex-center flex-col" (cdkDropListDropped)="drop($event)" formArrayName="lineItems" cdkDropList>
        <mat-expansion-panel
          class="draggable-element lg:w-4/5"
          *ngFor="let item of financialService.lineItems.controls; let i = index; let last = last"
          [ngClass]="{ 'border-2 border-red-300': item.invalid && financialService.invalidForm }"
          [formGroupName]="i"
          [expanded]="true"
          (afterExpand)="scrollToPanel(i)"
          cdkDrag
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="a-flex-center font-medium">
              <div class="absolute left-5 flex w-1/4 text-gray-600">
                <div class="drag-handle flex items-center" cdkDragHandle>
                  <span><span *ngIf="i < 9">0</span>{{ i + 1 }}.</span>
                  <mat-icon class="mx-2" (click)="$event.stopPropagation()">drag_handle</mat-icon>
                </div>
                <mat-icon
                  *ngIf="financialService.lineItems.controls.length > 1"
                  (click)="deleteLineItem(i); $event.stopPropagation()"
                  >delete</mat-icon
                >
              </div>
              <span class="w-3/4 text-end">{{ item.get('title')?.value }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="grid grid-cols-1 px-5 lg:grid-cols-2 lg:gap-x-5" #panel cdkDragDisabled>
            <mat-form-field class="w-full lg:col-start-1 lg:row-start-1" appearance="outline">
              <mat-label>Titel</mat-label>
              <input
                [formControlName]="'title'"
                [matAutocomplete]="lineItemsAuto"
                (keyup)="onProductChange($event, i)"
                matInput
              />
              <mat-error class="text-end" *ngIf="financialService.lineItems.at(i).get('title')?.errors?.['required']"
                >Pflichtfeld</mat-error
              >
              <mat-autocomplete
                #lineItemsAuto="matAutocomplete"
                (optionSelected)="onProductSelected($event, i)"
                panelWidth="auto"
              >
                <mat-option *ngFor="let product of filteredProducts" [value]="product.title">{{ product.title }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="w-full lg:col-start-1 lg:row-start-2" appearance="outline">
              <mat-label>Beschreibung</mat-label>
              <textarea class="!h-24 !resize-none" [formControlName]="'description'" matInput></textarea>
            </mat-form-field>

            <div class="w-full xs:flex lg:col-start-2 lg:row-start-1">
              <mat-form-field class="mr-1 w-full" appearance="outline">
                <mat-label>Menge</mat-label>
                <input [formControlName]="'quantity'" (input)="calculateService.calcSums(i, 'quantity')" matInput />
                <mat-error *ngIf="financialService.lineItems.at(i).get('quantity')?.errors?.['required']"></mat-error>
              </mat-form-field>

              <mat-form-field class="mx-1 w-full" appearance="outline">
                <mat-label>EP</mat-label>
                <input [formControlName]="'unitPrice'" (input)="calculateService.calcSums(i, 'unitPrice')" matInput />
                <mat-error *ngIf="financialService.lineItems.at(i).get('unitPrice')?.errors?.['required']"></mat-error>
              </mat-form-field>

              <mat-form-field class="mx-1 w-full" appearance="outline">
                <mat-label>UST</mat-label>
                <input [formControlName]="'taxPerc'" (input)="calculateService.calcSums(i, 'taxPerc')" matInput />
                <mat-error *ngIf="financialService.lineItems.at(i).get('taxPerc')?.errors?.['required']"></mat-error>
              </mat-form-field>

              <mat-form-field class="ml-1 w-full" appearance="outline">
                <mat-label>Summe</mat-label>
                <input [formControlName]="'singleSum'" (input)="calculateService.calcSums(i, 'singleSum')" matInput />
                <mat-error *ngIf="financialService.lineItems.at(i).get('singleSum')?.errors?.['required']"></mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </form>
  </mat-accordion>
</div>
