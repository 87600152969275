<a
  class="flex border-l-4"
  [ngClass]="{
    'border-[#5DA4CE] !bg-[#E0E0E0]': route.toLowerCase().includes(routeIncludes) || route.toLowerCase() === routeIs,
    'border-white': !route.toLowerCase().includes(routeIncludes) && route.toLowerCase() !== routeIs
  }"
  [routerLink]="link"
  mat-list-item
>
  <div *ngIf="route.toLowerCase().includes(routeIncludes) || route.toLowerCase() === routeIs">
    <hr class="absolute left-0 top-[20px] h-2 w-4 border-0 bg-[#5DA4CE]" />
    <div class="bg-primary absolute left-3 top-[8px] h-8 w-8 rounded-full bg-[#5DA4CE]"></div>
  </div>

  <mat-icon
    class="z-20"
    [ngClass]="{ '!text-gray-100': route.toLowerCase().includes(routeIncludes) || route.toLowerCase() === routeIs }"
    matListItemIcon
  >
    {{ icon }}
  </mat-icon>

  <div matListItemTitle matHint class="!text-sm">{{ title }}</div>
</a>
