import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { InputTemplateComponent } from '../../../components/input-template/input-template.component';
import { CompanyService } from '../../../services/crm/company.service';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { AddressesService } from '../../../services/crm/addresses.service';
import { PersonsService } from '../../../services/crm/persons.service';
import { Address, AddressType } from '../../../interfaces/address';
import { Person } from '../../../interfaces/person';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { OrbDemoComponent } from '../../../orb-demo/orb-demo.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatInputModule } from '@angular/material/input';
import { DialogTemplateComponent } from '../../../components/dialog-template/dialog-template.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';

@Component({
  selector: 'financehub-company-detail',
  templateUrl: './company-detail.component.html',
  standalone: true,
  imports: [
    RouterLink,
    MatExpansionModule,
    NgIf,
    MatToolbarModule,
    NgClass,
    FormsModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatCheckboxModule,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    InputTemplateComponent,
    ReactiveFormsModule,
    OrbDemoComponent,
    MatSnackBarModule,
    MatInputModule,
    DialogModule,
    DetailTemplateComponent
  ],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private companyService: CompanyService,
    private addressesService: AddressesService,
    private personsService: PersonsService,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
  ) {
  }

  uuid!: string;
  config!: any;
  dialogCall!: string;
  
  //company
  private querySubscription!: Subscription;
  companyForm!: FormGroup;
  company!: any; 
  numberExistsCompany = null;
  numberNotValidCompany = false;
  editCompanyActive = false;

  //address
  dataSourceAddresses!: MatTableDataSource<Address>;
  editAddressesActive = false;
  availableAddresses: Address[] = [];
  addressesOfCompany: AddressType[] = [];
  addAddressOverlay = false;
  selectedAddress = '';
  deleteAddressOverlay = false;
  addressToDelete!: AddressType;
  valueFilterAdresses = '';
  numberExistsAddressStates: boolean[] = [];
  numberNotValidAddressStates: boolean[] = [];
  numberExistsAddress = null;
  numberNotValidAddress = false;
  checkboxes = {
    delivery: false,
    invoice: false,
  };

  //persons
  dataSourcePersons!: MatTableDataSource<Person>;
  editPersonsActive = false;
  availablePersons: Person[] = [];
  personsOfCompany: Person[] = [];
  addPersonOverlay = false;
  selectedPerson = '';
  deletePersonOverlay = false;
  personToDelete!: Person;
  valueFilterPersons = '';
  numberExistsPerson = null;
  numberNotValidPerson = false;
  numberExistsPersonStates: boolean[] = [];
  numberNotValidPersonStates: boolean[] = [];
  companyPersons: Person[] = [];

  @ViewChild('addresses', { read: MatSort, static: true }) sortAddresses!: MatSort;
  @ViewChild('persons', { read: MatSort, static: true }) sortPersons!: MatSort;
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    if (!isInputFocused) {
      switch (event.key) {
        case '1':
          this.matTabGroup.selectedIndex = 0;
          break;
        case '2':
          this.matTabGroup.selectedIndex = 1;
          break;
        case '3':
          this.matTabGroup.selectedIndex = 2;
          break;
        default:
          break;
      }
    }
  }

  ngOnInit() {
    this.config = ProjectSettings;
    this.createOrUpdateCompanyForm();
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.companyService.getCompany(this.uuid).subscribe(({ data, loading }) => {
        this.company = { ...data.company };
        this.createOrUpdateCompanyForm(this.company);
        this.addressesOfCompany = this.company.delivery_addresses
          .map((dA: Address) => {
            return { type: 'delivery', ...dA };
          })
          .concat(
            this.company.invoice_addresses.map((iA: Address) => {
              return { type: 'invoice', ...iA };
            }),
          );
        this.personsOfCompany = [...this.company.persons];
        this.getAddresses();
        this.getPersons();
      });
    });
  }

  createOrUpdateCompanyForm(company: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.company.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = company[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.companyForm = new FormGroup(formGroupConfig);
  }

  getAddresses() {
    this.addressesService.getAddresses().subscribe(({ data, loading }) => {
      this.addressesService.availableAddresses = data.addresses.filter((addr: Address) => {
        return !this.addressIsInUse(addr.uuid, 'invoice') || !this.addressIsInUse(addr.uuid, 'delivery');
      });
      this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
      this.dataSourceAddresses.sort = this.sortAddresses;
    });
  }
  
  getPersons() {
    this.personsService.getPersons().subscribe(({ data, loading }) => {
      this.personsService.availablePersons = data.persons.filter((person: Person) => {
        return (
          this.company.persons.findIndex((p: Person) => {
            return p.uuid === person.uuid;
          }) === -1
        );
      });
      this.dataSourcePersons = new MatTableDataSource<Person>(this.personsOfCompany);
      this.dataSourcePersons.sort = this.sortPersons;
      const updatedData = this.dataSourcePersons.data.map((item) => ({
        ...item,
        birthday_formatted: item.birthday ? this.datePipe.transform(new Date(item.birthday)) : null,
      }));
      this.dataSourcePersons.data = updatedData;
    });
  }

  //mat labels
  getLabel(section: string) {
    if (section === 'address') {
      let count = this.dataSourceAddresses?.data?.length;
      return `2 - ${this.config.labels.addresses} (${count})`;
    } else {
      let count = this.dataSourcePersons?.data?.length;
      return `3 - ${this.config.labels.persons} (${count})`;
    }
  }

  //button edit company
  editCompany() {
    this.editCompanyActive = true;
    this.enableFields();
  }

  //enable fields company
  enableFields() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.companyForm.get(controlName)?.enable() : null;
    })
  }

  //update company
  updateCompany() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      const controlValue = this.companyForm.get(controlName)?.value;
      const type = this.config.fields.company.find((f: any) => f.controlName === controlName).type;
      this.company[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.companyService.updateCompany(this.uuid, this.company).subscribe(({ data, loading }) => {
      this.editCompanyActive = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedAddress} });
      this.disableFields();
    });
  }

  //disable fields company
  disableFields() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.companyForm.get(controlName)?.disable() : null;
    })
  }

  //add address dialog
  openAddAddressDialog(): void {
    this.addressesService.dialogCall = 'addAddressToCompany';
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        headline: this.config.dialog.selectAddress,
        text: null,
        element: 'element',
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addAddress();
      }
      this.addressesService.dialogCall = '';
    });
  }

  //add address
  addAddress() {
    this.companyService
      .saveCompanyAddress(this.uuid, {
        type_delivery: this.addressesService.addressToCompanyForm.get('delivery')?.value,
        type_invoice: this.addressesService.addressToCompanyForm.get('invoice')?.value,
        address_uuid: this.addressesService.addressToCompanyForm.get('selectedAddress')?.value,
      })
      .subscribe(({ data, loading }) => {
        const newAddr = this.addressesService.availableAddresses.find((addr: Address) => {
          return addr.uuid === this.addressesService.addressToCompanyForm.get('selectedAddress')?.value;
        });
        if (newAddr) {
          if (this.checkboxes.delivery) this.addressesOfCompany.push({ type: 'delivery', ...newAddr });
          if (this.checkboxes.invoice) this.addressesOfCompany.push({ type: 'invoice', ...newAddr });
          if (this.addressIsInUse(this.selectedAddress, 'delivery') && this.addressIsInUse(this.selectedAddress, 'invoice'))
            this.addressesService.availableAddresses = this.addressesService.availableAddresses.filter(
              (addr: Address) => addr.uuid !== this.selectedAddress,
            );
          this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
        }
        this.addAddressOverlay = false;
        this.selectedAddress = '';
        this.checkboxes.delivery = false;
        this.checkboxes.invoice = false;
        this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.addedAddress } });
      });
  }

  //delete address dialog
  openDeleteAddressDialog(address: any): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        text: `${this.config.dialog.deleteAddress} '${address.name}'?`,
        element: address,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) this.deleteAddress(el);
    });
  }

  //delete address
  deleteAddress(address: any) {
    this.addressToDelete = address;
    this.companyService
      .deleteCompanyAddress(this.uuid, { type: this.addressToDelete.type, address_uuid: this.addressToDelete.uuid })
      .subscribe(() => {
        const deleteIndex = this.addressesOfCompany.findIndex((addr: AddressType) => {
          return addr.uuid === this.addressToDelete.uuid && addr.type === this.addressToDelete.type;
        });
        // Add address back to available array if it was used as delivery and invoice address
        if (
          this.addressIsInUse(this.addressToDelete.uuid, 'delivery') &&
          this.addressIsInUse(this.addressToDelete.uuid, 'invoice')
        )
          this.availableAddresses.push(this.addressesOfCompany[deleteIndex]);
        this.addressesOfCompany.splice(deleteIndex, 1);
        this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
        this.deleteAddressOverlay = false;
        this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.removedAddress } });
      });
  }

  //address
  addressIsInUse(addrUuid: string, type: string) {
    return (
      this.addressesOfCompany.findIndex((addr: AddressType) => {
        return addr.uuid === addrUuid && addr.type === type;
      }) !== -1
    );
  }

  //add person dialog
  openAddPersonDialog(): void {
    this.personsService.dialogCall = 'addPersonToCompany';
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        headline: this.config.dialog.selectPerson,
        text: null,
        element: 'element',
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addPerson();
      }
      this.personsService.dialogCall = '';
    });
  }

  //add person
  addPerson() {
    this.companyService
      .saveCompanyPerson(this.uuid, { person_uuid: this.personsService.personToCompanyForm.get('selectedPerson')?.value })
      .subscribe(({ data, loading }) => {
        const newPerson = this.personsService.availablePersons.find((person: Person) => {
          return person.uuid === this.personsService.personToCompanyForm.get('selectedPerson')?.value;
        });
        if (newPerson) {
          this.personsService.availablePersons = this.personsService.availablePersons.filter(
            (person: Person) => person.uuid !== this.selectedPerson,
          );
          this.personsOfCompany.push(newPerson);
          this.dataSourcePersons = new MatTableDataSource<Person>(this.personsOfCompany);
          this.selectedPerson = '';
          this.addPersonOverlay = false;
          this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.addedPerson } });
        }
      });
  }

  //delete person dialog
  openDeletePersonDialog(person: any): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        text: `${this.config.dialog.deleteAddress} '${person.name}'?`,
        element: person,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) this.deletePerson(el);
    });
  }

  //delete person
  deletePerson(personToDelete: any) {
    this.companyService.deleteCompanyPerson(this.uuid, personToDelete.uuid).subscribe(() => {
      const deleteIndex = this.personsOfCompany.findIndex((person: Person) => {
        return person.uuid === personToDelete.uuid;
      });
      this.personsService.availablePersons.push(this.personsOfCompany[deleteIndex]);
      this.personsOfCompany.splice(deleteIndex, 1);
      this.dataSourcePersons = new MatTableDataSource<Person>(this.companyPersons);
      this.deletePersonOverlay = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.removedPerson } });
    });
  }

  //filter
  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  //filter
  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    switch (value) {
      case 'addresses':
        this.valueFilterAdresses = '';
        break;
      case 'persons':
        this.valueFilterPersons = '';
        break;
    }
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  // saveCheckboxChanges(element: any, event: any) {}

  // editPersons() {
  //   this.editPersonsActive = !this.editPersonsActive;
  // }

  // editAddresses() {
  //   this.editAddressesActive = true;
  // }

  // updateAddresses() {
  //   this.editAddressesActive = false;
  // }

  // getDeletePersonOverlay(person: Person) {
  //   this.deletePersonOverlay = true;
  //   this.personToDelete = person;
  // }

}