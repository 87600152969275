import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { InvoiceService } from '../../services/finance/invoice.service';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { MatInputModule } from '@angular/material/input';
import { FinancialService } from '../../services/finance/financial.service';

@Component({
  selector: 'financehub-invoice-texts',
  templateUrl: './invoice-texts.component.html',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputTemplateComponent,
    NgIf,
    MatInputModule,
  ],
})
export class InvoiceTextsComponent {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
  ) {
    this.financialService.textsForm = this.financialService.setTextsForm();
  }

  onTextBeforeSelected(value: string) {
    const text = this.financialService.textsBefore.find((p) => p.title == value) || {
      title: 'empty',
      text: '',
    };
    this.financialService.textsForm.get('textBeforeTitle')?.setValue(text.title);
    this.financialService.textsForm.get('textBefore')?.setValue(text.text);
  }

  onTextAfterSelected(value: string) {
    const text = this.financialService.textsAfter.find((p) => p.title == value) || {
      title: 'empty',
      text: '',
    };
    this.financialService.textsForm.get('textAfterTitle')?.setValue(text.title);
    this.financialService.textsForm.get('textAfter')?.setValue(text.text);
  }
}
