<div class="text-primary text-lg mb-2">Project</div>
<form #formProject="ngForm">
  <div class="flex justify-center">
    <form
      class="mb-4 flex grid w-full grid-cols-1 flex-col items-start lg:grid-cols-12 gap-x-2"
      [formGroup]="financialService.projectForm"
    >
      <financehub-input-template
        class="col-span-12"
        [optionsArray]="filteredProjects"
        [formGroup]="financialService.projectForm"
        (autocompleteChanged)="onProjectChange($event)"
        (autocompleteSelected)="onProjectSelected($event)"
        type="autocomplete"
        label="Search"
        displayField="title"
        controlName="search"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-6"
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Name"
        controlName="title"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-6 xl:col-start-10 xl:col-span-3"
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Project Nr."
        controlName="number"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-6"
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 1"
        controlName="manager"
      />

      <financehub-input-template
        class="col-span-12 lg:col-span-6"
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 2"
        controlName="coManager"
      />
    </form>
  </div>
</form>
