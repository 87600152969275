import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'financehub-snackbar-template',
  templateUrl: './snackbar-template.component.html', 
  standalone: true,
  imports: [MatButtonModule, MatSnackBarModule],
})
export class SnackbarTemplateComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  snackBarRef = inject(MatSnackBarRef);
}
