<div class="flex justify-center">
    <div class="w-full lg:w-4/5">
      
      <div class="my-5 flex items-center justify-between px-5 lg:px-0">
        <mat-form-field class="table-filter mr-3 w-3/5 lg:w-2/5" appearance="outline">
          <mat-label>{{config.table.filter}}</mat-label>
          <input [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" matInput />
          <mat-icon class="cursor-pointer !p-1" *ngIf="filterValue" (click)="deleteFilter($event)" matSuffix>close</mat-icon>
        </mat-form-field>
        <button class="w-2/5" [routerLink]="'../' + entity + '/create'" mat-raised-button color="primary"><mat-icon>add</mat-icon>{{addButton}}</button>
      </div>
  
      <div class="mat-elevation-z8 mx-5 mb-5 lg:mx-0">
        <div class="overflow-x-auto">
          <table [dataSource]="dataSource" mat-table matSort>
            <ng-container *ngFor="let c of array">
              <ng-container [matColumnDef]="c" *ngIf="c !== 'actions'">
                <th class="" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by {{ c }}">{{c}}</th>
                <td class="relative" *matCellDef="let element" mat-cell>
                  {{ element[c] }}   
                </td>
              </ng-container>
              <ng-container matColumnDef="actions" *ngIf="c === 'actions'">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let element" mat-cell>
                  <div class="flex flex-col justify-end xs:flex-row">
                    <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../' + entity + '/' + element.uuid">edit</mat-icon>
                    <mat-icon class="cursor-pointer" (click)="deleteElement(element)"> delete </mat-icon>
                  </div>
                </td>
              </ng-container> 
            </ng-container>
            <tr *matHeaderRowDef="array" mat-header-row></tr>
            <tr *matRowDef="let row; let even = even; columns: array" [ngClass]="{ '!bg-gray-200': even }" mat-row></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="config.table.itemsPerPage" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
  
    </div>
  </div>
    