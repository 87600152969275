<div class="flex justify-center mt-5">
  <mat-form-field class="w-full max-w-7xl">
    <mat-label>Graph type</mat-label>
    <mat-select 
      multiple
      [(ngModel)]="selectedGraphTypes"
      (selectionChange)="onGraphTypeChange()"
      class="border-0 bg-transparent focus:border-0"
    >
      <mat-option value="supplyChain">Supply chain</mat-option>
      <mat-option value="customerSegmentation">Customer segmentation</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="flex flex-wrap mx-8 my-8 text-sm font-bold justify-center">
  <div class="flex items-center mr-4 mb-2">
    <span class="flex-shrink-0 rounded-full bg-green-500 h-4 w-4 mr-2 inline-block"></span>
    <span>Companies</span>
  </div>
  <div class="flex items-center mr-4 mb-2">
    <span class="flex-shrink-0 rounded-full bg-blue-500 h-4 w-4 mr-2 inline-block"></span>
    <span>Billing Addresses</span>
  </div>
  <div class="flex items-center mr-4 mb-2">
    <span class="flex-shrink-0 rounded-full bg-violet-500 h-4 w-4 mr-2 inline-block"></span>
    <span>Shipping Addresses</span>
  </div>
  <div class="flex items-center mr-4 mb-2">
    <span class="flex-shrink-0 rounded-full bg-amber-500 h-4 w-4 mr-2 inline-block"></span>
    <span>Persons</span>
  </div>
  <div class="flex items-center mr-4 mb-2">
    <span class="flex-shrink-0 rounded-full bg-rose-500 h-4 w-4 mr-2 inline-block"></span>
    <span>Invoices</span>
  </div>
</div>
<div class="flex justify-center h-48 xl:h-72 2xl:h-96" id="graph"></div>