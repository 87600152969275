<form [formGroup]="formGroup">
  <mat-checkbox class="!text-sm" *ngIf="type === 'checkbox'" [formControlName]="controlName">{{label}}</mat-checkbox>
  <mat-form-field *ngIf="type !== 'checkbox'" (click)="controlName === 'uuid' ? copyText(formGroup.get(controlName)) : null" class="w-full !text-sm" [ngClass]="{'!text-xs cursor-pointer': controlName === 'uuid', 'mat-form-field-textarea': type === 'textarea', 'mat-form-field-normal': type !== 'textarea'}" appearance="outline" (click)="navigateToLink(type, formGroup.get(controlName))">
    <div class="!w-[80%] ml-1 !h-[18px] px-[4px] max-w-fit fixed bottom-[-8px] left-0 z-50 right-0 !bg-background p-0 rounded line-clamp-1 break-all !text-gray-400 !text-2xs" *ngIf="showTooltip && placeholder"><span class="!h-[14px] flex">{{placeholder}}</span></div>
    <mat-label>{{ label }}</mat-label>
        <input
          class="!ring-0"
          *ngIf="type === 'text' || type === 'number' || type === 'link' || type === 'password'"
          [type]="type"
          [readonly]="readonly"
          [formControlName]="controlName"
          autocomplete="new-password"
          matInput
          [placeholder]="placeholder"
          (focus)="checkTooltip(controlName)"
          (input)="onInputCheckTooltip(controlName, entity)"
          (blur)="showTooltip = false"
        />
      <textarea
        class="!resize-none !ring-0 !mt-2"
        *ngIf="type === 'textarea'"
        [readonly]="readonly"
        [formControlName]="controlName"
        matInput
        (input)="checkTooltip(controlName)"
        (blur)="showTooltip = false"
      ></textarea>

      <mat-select (selectionChange)="onSelectionChange($event)" *ngIf="type === 'select'" [formControlName]="controlName" class="!flex">
        <mat-option *ngFor="let element of optionsArray; let i = index" [value]="optionValue ? element[optionValue] : element">{{ optionValue ? element[optionValue] : element }}</mat-option>
      </mat-select>

      <div *ngIf="type === 'datepicker'" class="flex items-center">
        <input [matDatepicker]="picker" matInput [formControlName]="controlName" [placeholder]="placeholder" readonly/>
        <mat-datepicker-toggle [for]="picker" matIconSuffix class="flex"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startDate" disabled="false"></mat-datepicker>
      </div>

      <div *ngIf="type === 'autocomplete'" class="!flex">
        <input [placeholder]="placeholder" (input)="checkTooltip(controlName)" (blur)="showTooltip = false" [matAutocomplete]="auto" (keyup)="onAutocompleteChange($event)" autocomplete="new-password" [formControlName]="controlName" [readonly]="readonly" matInput />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayWith"
          (optionSelected)="onAutocompleteSelected($event)"
          panelWidth="auto"
        >
          <mat-option *ngFor="let option of optionsArray" [value]="optionValue ? option[optionValue] : option">{{ option[displayField] }}<span *ngIf="displayFieldSecondary"> {{ option[displayFieldSecondary] }}</span></mat-option>
        </mat-autocomplete>
      </div>

      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['required']">required</mat-error>
      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['email']">{{ label }} not valid</mat-error>
      <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['pattern']">only numbers possible</mat-error>
    </mat-form-field>
</form>