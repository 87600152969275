import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import SnowflakeId from 'snowflake-id';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../components/snackbar-template/snackbar-template.component';

@Injectable({
  providedIn: 'root',
})
export class AddService {
  constructor(
    // private _snackBar: MatSnackBar,
  ) {}

  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });
  
  config = ProjectSettings;
  form: any;

  createFormGroup(fields: any) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    fields.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      formGroupConfig[field.controlName] = new FormControl(
        { value: field.controlName === 'uuid' ? this.snowflake.generate() : field.defaultValue, disabled: field.readonly },
        validators
      );
    });
    return new FormGroup(formGroupConfig);
  }

  parseToString(form: any, fields: any) {
    Object.keys(form.controls).forEach(controlName => {
      const controlValue = form.get(controlName)?.value;
      const field = fields.find((f: any) => f.controlName === controlName);
      field.type === 'number' ? form.get(controlName)?.setValue(parseInt(controlValue)) : null
    });
    return form;
  }
}
