export const ProjectSettings = {
    labels: {
        addresses: 'Addresses',
        address: 'Address',
        companies: 'Companies',
        company: 'Company',
        persons: 'Persons',
        person: 'Person'
    },
    button: {
        addAddress: 'Add Address',
        addCompany: 'Add Company',
        addPerson: 'Add Person',
        addOffer: 'Add Offer',
        addInvoice: 'Add Invoice',
        createAddress: 'Create Address',
        createCompany: 'Create Company',
        createPerson: 'Create Person',
        createInvoice: 'Create Invoice',
        createOffer: 'Create Offer',
        editAddress: 'Edit Address',
        editCompany: 'Edit Company',
        editPerson: 'Edit Person',
        saveAddress: 'Save Address',
        saveCompany: 'Save Company',
        savePerson: 'Save Person',
        updateInvoice: 'Update Invoice',
        updateOffer: 'Update Offer',
    },
    snackbar: {
        deletedAddress: 'Address Deleted',
        deletedCompany: 'Company Deleted',
        deletedPerson: 'Person Deleted',
        createdAddress: 'Address Created',
        createdCompany: 'Company Created',
        createdPerson: 'Person Created',
        createdInvoice: 'Invoice Created',
        createdOffer: 'Offer Created',
        createdClient: 'Client Created',
        removedAddress: 'Address removed',
        removedCompany: 'Company removed',
        removedPerson: 'Person removed',
        updatedAddress: 'Address Updated',
        updatedCompany: 'Company Updated',
        updatedPerson: 'Person Updated',
        updatedInvoice: 'Invoice Updated',
        updatedOffer: 'Offer Updated',
        incompleteInvoice: 'Invoice Incomplete',
        incompleteOffer: 'Offer Incomplete',
        incompleteClient: 'Client Incomplete',
        confirm: 'OK'
    },
    dialog: {
        deleteAddress: 'Delete Address',
        deleteCompany: 'Delete Company',
        deletePerson: 'Delete Person',
        deleteInvoice: 'Delete Invoice',
        deleteOffer: 'Delete Offer',
        deleteCancel: 'No, not delete',
        deleteConfirm: 'Yes, delete',
        selectAddress: 'Select Address',
        selectCompany: 'Select Company',
        selectPerson: 'Select Person',
        cancel: 'Cancel',
        confirm: 'OK'
    },
    sections: {
        company:  [
            { title: 'Bank Details', range: [4, 7] },
            { title: 'Additional Information', range: [7, 10] },
        ],
        addressType: [
            { title: 'Address Type', range: [0,2]}
        ],
        person: [
            { title: 'Private Information', range: [7,10]}
        ],
        personCompanyInfo: [
            { title: 'Company Information', range: [0,3]}
        ]
    },
    fields: {
        company: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'row-start-1 col-span-7 2xl:col-span-6'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, defaultValue: 100000, class: 'row-start-1 col-start-10 col-span-3'},
            {name: 'Name', controlName: 'name_company', type: 'text', validation: true, class: 'row-start-2 col-span-12'},
            {name: 'URL', controlName: 'url', type: 'link', class: 'row-start-3 col-span-12'},
            {name: 'Bankname', controlName: 'bank_name', type: 'text', class: 'row-start-4 col-span-5'},
            {name: 'IBAN', controlName: 'iban', type: 'text', class: 'row-start-4 col-span-7 2xl:col-start-7 2xl:col-span-6'},
            {name: 'BIC', controlName: 'bic', type: 'text', class: 'row-start-5 col-span-6 2xl:col-span-4'},
            {name: 'UID', controlName: 'uid', type: 'text', class: 'row-start-6 col-span-5 2xl:col-span-4'},
            {name: 'FBNR', controlName: 'company_register_number', type: 'text', class: 'row-start-6 col-start-8 col-span-5 2xl:col-start-10 2xl:col-span-3'},
            {name: 'Info', controlName: 'info', type: 'textarea', class: 'row-start-7 col-span-12'},
        ],
        address: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'col-span-7'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, defaultValue: 200000, class: 'col-start-10 col-span-3'},
            {name: 'Name', controlName: 'name', type: 'text', validation: true, class: 'col-span-12'},
            {name: 'Street', controlName: 'street', type: 'text', class: 'col-span-9'},
            {name: 'Nr.', controlName: 'house_number', type: 'text', class: 'col-span-3'},
            {name: 'Zip', controlName: 'zip', type: 'text', class: 'col-span-3'},
            {name: 'City', controlName: 'city', type: 'text', class: 'col-span-5'},
            {name: 'Country', controlName: 'country', type: 'text', class: 'col-span-4'},
        ],
        person: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'col-span-7'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, defaultValue: 300000, class: 'col-start-10 col-span-3'},
            {name: 'First Name', controlName: 'first_name', type: 'text', validation: true, class: 'col-span-6'},
            {name: 'Last Name', controlName: 'last_name', type: 'text', validation: true, class: 'col-span-6'},
            {name: 'Title', controlName: 'title_academic', type: 'text', class: 'col-span-3'},
            {name: 'Gender', controlName: 'gender', type: 'select', optionsArray: ['female', 'male', 'other'], class: 'col-span-4'},
            {name: 'Birthday', controlName: 'birthday', type: 'datepicker', class: 'col-span-5'},
            {name: 'Mail Private', controlName: 'mail_private', type: 'text', class: 'col-span-7'},
            {name: 'Phone Private', controlName: 'phone_private', type: 'text', class: 'col-span-5'},
            {name: 'Fax', controlName: 'fax', type: 'text', class: 'col-span-5'},
        ],
        personCompanyInfo: [
            {name: 'Mail Office', controlName: 'mail_office', type: 'text', class: 'col-span-7'},
            {name: 'Phone Office', controlName: 'phone_office', type: 'text', class: 'col-span-5'},
            {name: 'Position', controlName: 'position', type: 'text', class: 'col-span-5'},
        ],
        addressType: [
            {name: 'Invoice Address', controlName: 'invoice', type: 'checkbox', class: 'col-span-12'},
            {name: 'Delivery Address', controlName: 'delivery', type: 'checkbox', class: 'col-span-12'},
        ]
    },
    table: {
        filter: 'Filter',
        itemsPerPage: [5,10,30],
        sortBy: 'bds_number',
    },
    tableColumns: {
        companies:  ['bds_number', 'name_company', 'actions'],
        companyAddresses: ['bds_number', 'name', 'country', 'zip', 'city', 'street', 'house_number', 'invoice', 'delivery', 'actions'],
        companyPersons: ['bds_number', 'gender', 'title_academic', 'first_name', 'last_name', 'position', 'birthday', 'phone_office', 'mail_office', 'info', 'actions'],
        addresses: ['bds_number', 'name', 'actions'],
        addressCompanies: ['bds_number', 'name_company'],
        addressPersons: ['bds_number', 'first_name', 'last_name',],
        persons: ['bds_number', 'first_name', 'last_name', 'actions'],
        personCompanies: ['bds_number', 'name_company'],
        personAddresses: [ 'bds_number', 'name', 'country', 'zip', 'city', 'street', 'house_number']
    },
    invoiceProjects: [
        { title: 'Anker', coManager: 'test', number: 101, manager: 'Rene Heinzl' },
        { title: 'ohne', coManager: 'Rene Heinzl', number: 100, manager: 'Rene Heinzl' },
        { title: 'Recruiting', coManager: 'test', number: 102, manager: 'Markus Nissl' },
        { title: 'Financehub', coManager: 'test', number: 103, manager: 'Rene Heinzl' },
        { title: 'A1', coManager: 'test', number: 104, manager: 'Christoph Burger' },
        { title: 'Brantner', coManager: 'test', number: 105, manager: 'Rene Heinzl' },
      ],
    invoiceProducts: [
        {
            title: 'Softwareentwicklung',
            unitPrice: 1500,
            tax: 20,
            description: 'Gestaltung, Entwicklung und Implementierung von maßgeschneiderten Softwarelösungen.',
        },
        {
            title: 'Cybersicherheit',
            unitPrice: 50,
            tax: 10,
            description:
            'Sicherung von IT-Systemen und -Daten vor Bedrohungen, Angriffen und unbefugtem Zugriff. Dies umfasst Penetrationstests, Sicherheitsaudits, Implementierung von Firewalls, Antiviren-Software und Schulungen für Mitarbeiter.',
        },
        {
            title: 'Netzwerk- und Infrastruktur',
            unitPrice: 199.9,
            tax: 10,
            description:
            'Planung, Implementierung und Wartung von Netzwerken, Servern, Storage-Systemen und anderen IT-Infrastrukturen.',
        },
        {
            title: 'Datenbankmanagement und -optimierung',
            unitPrice: 500,
            tax: 20,
            description:
            'Verwaltung von Datenbanken, die Optimierung von Abfragen, die Datensicherung, die Wiederherstellung und die Skalierung, um sicherzustellen, dass Daten effizient gespeichert, verwaltet und abgerufen werden können.',
        },
        {
            title: 'IT-Beratung',
            unitPrice: 3500,
            tax: 15,
            description:
            'Fachwissen und Empfehlungen in Bezug auf Technologiestrategien, Infrastruktur, Prozessoptimierung und Technologieauswahl',
        },
        {
            title: 'Datenanalyse und Business Intelligence',
            unitPrice: 10,
            tax: 10,
            description: 'Sammlung, Verarbeitung und Analyse von Unternehmensdaten',
        },
        { title: 'Mobile App-Entwicklung', unitPrice: 2399.99, tax: 20, description: '' },
        {
            title: 'IT-Infrastrukturüberwachung und -wartung',
            unitPrice: 745.9,
            tax: 10,
            description:
            'kontinuierliche Überwachung, Wartung und Aktualisierung der IT-Infrastruktur gewährleisten, um Ausfallzeiten zu minimieren und die Leistung aufrechtzuerhalten',
        }
    ],
    invoicePreTexts: [
        { title: 'leer', text: '' },
        {
          title: 'Variante 1',
          text: `Guten Tag!\n\nVielen Dank für Ihren Auftrag. Vereinbarungsgemäß berechnen wir Ihnen hiermit folgende Leistungen:`,
        },
        {
          title: 'Variante 2',
          text: `Sehr geehrte Damen und Herren!\n\nVielen Dank für Ihr Vertrauen in unsere Produkte.\nWir hoffen, Sie sind zufrieden und würden uns freuen, wieder von Ihnen zu hören.`,
        },
        {
          title: 'Variante 3',
          text: 'Wir bedanken uns für die gute Zusammenarbeit und stellen Ihnen vereinarungsgemäß folgende Lieferungen und Leistungen in Rechnung:',
        },
    ],
    invoicePostTexts: [
        { title: 'leer', text: '' },
        { title: 'Variante 1', text: 'Bei Rückfragen stehe ich Ihnen wie gewohnt jederzeit gerne zur Verfügung!' },
        { title: 'Variante 2', text: 'Vielen Dank für Ihren Auftrag!' },
        { title: 'Variante 3', text: 'Ich danke Ihnen für die gute Zusammenarbeit!' }
    ]
    

}