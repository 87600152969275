import { Component, OnInit } from '@angular/core';
import SnowflakeId from 'snowflake-id';
import { AddressesService } from '../../../services/crm/addresses.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { AddTemplateComponent } from '../../../components/add-template/add-template.component';
import { AddService } from 'src/app/services/add.service';

@Component({
  selector: 'financehub-address-add',
  templateUrl: './address-add.component.html',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    AddTemplateComponent,
    ReactiveFormsModule,
    MatSnackBarModule,
    NgFor
  ],
})
export class AddressAddComponent implements OnInit {
  constructor(
    private addressesService: AddressesService,
    private _snackBar: MatSnackBar,
    private addService: AddService

  ) {
  }

  addressForm!: FormGroup;
  config!: any;
  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });
  
  ngOnInit() {
    this.config = ProjectSettings;
    this.addressForm = this.addService.createFormGroup(this.config.fields.address);
   }

  create() {
    this.addressForm = this.addService.parseToString(this.addressForm, this.config.fields.address);
    const newAddress = this.getValuesFromForm();
    this.addressesService.saveAddress(newAddress).subscribe(({ data, loading }) => {
      this.addressForm.reset();
      this.addressForm = this.addService.createFormGroup(this.config.fields.address);
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdAddress } });
    });
  }

  getValuesFromForm() {
    const formValues = this.addressForm.getRawValue();
    return {
      ...formValues,  
      companies: [],
      persons: []
    };
  }
}
