import { Injectable } from '@angular/core';
import { FinancialService } from './financial.service';
import { Apollo, gql } from 'apollo-angular';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import moment from 'moment';
import { InvoiceService } from './invoice.service';
import { defaultOfferConfig, FilterConfig } from 'src/app/interfaces/filter-config';

const GET_OFFERS = gql`
  query offers($filter: FilterInput) {
    offers(filter: $filter) {
      data {
        _id
        title
        offer_number
        input_locale
        output_locale
        offer_date
        validity_date
        text_before
        text_after
        subtotal
        total_taxes
        customer_id
        delivery_address_id
        invoice_address_id
        status
        customer {
          name_company
        }
      }
      totalCount
    }
  }
`;

const GET_OFFERS_BY_STATUS = gql`
  query offersByStatus {
    offersByStatus {
      _id
      offers {
        _id
        title
        offer_number
        input_locale
        output_locale
        offer_date
        validity_date
        text_before
        text_after
        subtotal
        total_taxes
        customer_id
        delivery_address_id
        invoice_address_id
        customer {
          name_company
        }
      }
    }
  }
`;

const GET_OFFER = gql`
  query offer($id: String!) {
    offer(id: $id) {
      _id
      title
      offer_number
      input_locale
      output_locale
      offer_date
      validity_date
      text_before
      text_after
      subtotal
      total_taxes
      customer_id
      customer {
        bds_number
        uid
        company_register_number
      }
      project_title
      project_number
      project_manager
      project_co_manager
      delivery_address_id
      invoice_address_id
      offer_line_items {
        _id
        offer_id
        title
        description
        quantity
        unitPrice
        taxPerc
      }
    }
  }
`;

const CREATE_OFFER = gql`
  mutation CreateOffer($createOfferInput: CreateOfferInput) {
    createOffer(createOfferInput: $createOfferInput) {
      _id
    }
  }
`;

const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: String!, $createOfferInput: CreateOfferInput) {
    updateOffer(id: $id, createOfferInput: $createOfferInput) {
      _id
    }
  }
`;

const DELETE_OFFER = gql`
  mutation DeleteOffer($id: String!) {
    deleteOffer(id: $id) {
      _id
    }
  }
`;

const UPDATE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($id: String!, $status: String!) {
    updateOfferStatus(id: $id, status: $status) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(
    public apollo: Apollo,
    public fb: FormBuilder,
    public financialService: FinancialService,
    public invoiceService: InvoiceService
  ) {}

  getOffers(filterConfig: FilterConfig): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFERS,
      variables: {
        filter: {
          limit_count: filterConfig.limit_count,
          limit_start: filterConfig.limit_start,
          sort_field: filterConfig.sort_field,
          sort_order: filterConfig.sort_order,
          filter_term: filterConfig.filter_term,
        }
      },
    }).valueChanges;
  }

  getOffersByStatus(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFERS_BY_STATUS,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getOffer(id: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFER,
      variables: { id },
    }).valueChanges;
  }

  saveOffer(): Observable<any> {
    const offerInput = this.getOfferInput();
    return this.apollo.mutate({
      mutation: CREATE_OFFER,
      variables: { createOfferInput: offerInput },
      refetchQueries: [{ query: GET_OFFERS, variables: { filter: defaultOfferConfig } }],
    });
  }

  updateOffer(offerId: string): Observable<any> {
    const offerInput = this.getOfferInput();
    return this.apollo.mutate({
      mutation: UPDATE_OFFER,
      variables: { id: offerId, createOfferInput: offerInput },
    });
  }

  deleteOffer(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_OFFER,
      variables: { id },
      refetchQueries: [{ query: GET_OFFERS, variables: { filter: defaultOfferConfig } }],
    });
  }

  getOfferInput(): any {
    return {
      ...this.financialService.getInputs(),
      offer_date: moment(this.financialService.detailsForm.get('offerDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      validity_date: moment(this.financialService.detailsForm.get('validityDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      offer_line_items: this.financialService.lineItems.value.map((lineItem: any) => {
        return {
          _id: lineItem._id ? lineItem._id : null,
          title: lineItem.title,
          description: lineItem.description,
          quantity: +lineItem.quantity.toString().replace(',', '.'),
          unitPrice: +lineItem.unitPrice.toString().replace(',', '.'),
          taxPerc: +lineItem.taxPerc.toString().replace(',', '.'),
        };
      }),
    };
  }

  updateStatus(id: string, status: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_OFFER_STATUS,
      variables: { id, status },
      //refetchQueries: [{ query: GET_OFFERS_BY_STATUS }],
    });
  }
}
