<div class="!bg-background min-h-screen py-10 px-[10vw] flex flex-col items-center">
    <div class="text-6xl text-primary font-light">Welcome!</div>
    <div>{{ appVersion }}</div>
    <div>{{ appDeploymentDate | date }}</div>
    <!-- TODO -->
    <!-- <div class="grid mt-20 gap-10">
        <div class="row-start-2 col-start-1 col-span-1">Top Clients</div>
        <div class="row-start-2 col-start-2 col-span-1">Top Project</div>
        <div class="row-start-3 col-start-1 col-span-1">Top xyz</div>
        <div class="row-start-3 col-start-2 col-span-1">Top zyx</div>
    </div> -->

</div>