<div class="grid grid-cols-12 grid-rows-auto">
  <div class="col-span-12 text-3xl text-primary font-light w-full"><span class="text-start">Edit Person</span></div>
  <financehub-detail-template
    class="relative top-10 col-span-12"
    [formGroup]="personForm" 
    (clickedUpdate)="updatePerson()"
    (clickedEdit)="editPerson()"
    [array]="config.fields.person"
    [editButton]="config.button.editPerson"
    [saveButton]="config.button.savePerson"
    [editActive]="editPersonActive"
  />
</div>