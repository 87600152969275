import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { DialogModule } from '@angular/cdk/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CompanyDetailComponent } from 'src/app/pages/company/company-detail/company-detail.component';
import { AddressDetailComponent } from 'src/app/pages/address/address-detail/address-detail.component';
import { PersonDetailComponent } from 'src/app/pages/person/person-detail/person-detail.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { OrbDemoComponent } from 'src/app/orb-demo/orb-demo.component';

@Component({
  selector: 'financehub-list-template',
  templateUrl: './list-template.component.html',
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    RouterLink,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    DialogModule,
    MatProgressSpinnerModule,
    CompanyDetailComponent,
    AddressDetailComponent,
    PersonDetailComponent,
    OrbDemoComponent
  ],
})
export class ListTemplateComponent implements OnInit, AfterViewInit{
  @Input() entity!: any;
  @Input() addButton!: string;
  @Input() array: any
  @Input() loading!: boolean;
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() sortActive!: string;
  @Input() sortDirection!: SortDirection
  @Input() isEditable = true;
  @Output() clickedDelete = new EventEmitter();
  @Output() filterDelete = new EventEmitter();
  @Output() filterChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter();
  @Output() pageChanged = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterValue = '';
  config!: any;
  // entityEditUuid!: any;

  constructor(
    private router: Router,
    public financialService: FinancialService
  ) {}

  ngOnInit(): void {
    this.config = ProjectSettings;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sort.sortChange.subscribe(() => {
      if (this.dataSource.paginator)
        this.dataSource.paginator.pageIndex = 0; // Reset to first page on sort change
      this.sortChanged.emit();
    });

    this.dataSource.paginator.page.subscribe(() => {
      this.pageChanged.emit();
    })
  }

  deleteElement(event: any) {
    this.clickedDelete.emit(event);
  }

  deleteFilter(event: any) {
    this.filterValue = '';
    this.filterDelete.emit(event)
  }

  onFilterChange(event: any) {
    this.filterChanged.emit(event)
  }

  editEntity(entity: any, uuid: any) {
    this.financialService.entityEdit = entity;
    this.financialService.entityEditUuid = uuid;
    // this.router.navigate(['/clients', uuid]);
  }
}
