<form (submit)="create()" class="flex flex-col justify-center items-center mx-[2vw] mt-[1vw] 3xl:mx-[10vw]">
  <div class="w-full grid grid-cols-12 grid-rows-auto gap-4">
    <!-- Company -->
    <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:col-start-1 lg:col-span-6 xl:col-start-1 xl:col-span-4">
      <mat-card-header>
        <mat-card-title class="!flex justify-between gap-x-5">
          <div class="w-1/2 flex flex-col">
            <div><span class="text-xl">Company &nbsp;</span><span class="font-light text-sm">({{companySelected ? 'Existing' : 'New'}})</span></div>
            <button type="button" class="flex justify-start text-sm text-[#5da4ce]" (click)="resetCompany()">
              Reset
            </button>
          </div>
          <mat-form-field class="w-1/2 !text-sm" appearance="outline">
            <mat-label>Search</mat-label>
            <input
              [matAutocomplete]="companyAuto" 
              (ngModelChange)="searchCompanyChanged($event)"
              (keyup)="clearCompanyInput($event)"
              [(ngModel)]="searchCompanyInput"
              matInput
              name="searchCompanyInput"
            />
            <mat-icon matSuffix class="!p-0 text-xl">search</mat-icon>
            <mat-autocomplete
              #companyAuto="matAutocomplete"
              (optionSelected)="searchCompanySelected($event.option.value)"
              [displayWith]="displayCompanyName"
              panelWidth="auto">
              <mat-option *ngFor="let option of financialService.filteredCompanies" [value]="option">{{ option.name_company }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-card-title>
      </mat-card-header>
      <hr class="mb-5">
      <mat-card-content>
        <financehub-add-template
          class="w-full"
          [formGroup]="companyForm" 
          [array]="config.fields.company.slice(0,4)"
        />
        <mat-accordion *ngFor="let s of config.sections.company">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> {{ s.title }} </mat-panel-title>
            </mat-expansion-panel-header>
            <financehub-add-template
              class="w-full"
              [formGroup]="companyForm" 
              [array]="config.fields.company.slice(s.range[0], s.range[1])"
            ></financehub-add-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>

    <!-- Address -->
    <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:row-start-1 lg:col-start-7 lg:col-span-6 xl:row-start-1 xl:col-start-5 xl:col-span-4">
      <mat-card-header>
        <mat-card-title class="!flex justify-between gap-x-5">
          <div class="w-1/2 flex flex-col">
            <div><span class="text-xl">Address &nbsp;</span><span class="font-light text-sm">({{addressSelected ? 'Existing' : 'New'}})</span></div>
            <button type="button" class="flex justify-start text-sm text-[#5da4ce]" (click)="resetAddress()">
              Reset
            </button>
          </div>
          <mat-form-field class="w-1/2 !text-sm" appearance="outline">
            <mat-label>Search</mat-label>
            <input
              [matAutocomplete]="addressAuto"
              (ngModelChange)="searchAddressChanged($event)"
              (keyup)="clearAddressInput($event)"
              [(ngModel)]="searchAddressInput"
              matInput
              name="searchAddressInput"
            />
            <mat-icon matSuffix class="!p-0 text-xl">search</mat-icon>
            <mat-autocomplete
              #addressAuto="matAutocomplete"
              (optionSelected)="searchAddressSelected($event.option.value)"
              [displayWith]="displayAddressName"
              panelWidth="auto">
              <mat-option *ngFor="let option of financialService.filteredAddresses" [value]="option">{{ option.name }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-card-title>
      </mat-card-header>
      <hr class="mb-5">
      <mat-card-content>
        <financehub-add-template
          class="w-full"
          [formGroup]="addressForm" 
          [array]="config.fields.address"
        />
        <mat-accordion *ngFor="let s of config.sections.addressType">
          <mat-expansion-panel class="border-1 border-white" [ngClass]="{
            'border-[#F44336]': addressTypeForm.invalid && addressTypeForm.touched,
            }"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> {{ s.title }} </mat-panel-title>
            </mat-expansion-panel-header>
            <financehub-add-template
              class="w-full"
              [formGroup]="addressTypeForm" 
              [array]="config.fields.addressType"
            ></financehub-add-template>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-error class="text-xs text-right px-4 font-light">{{addressTypeForm.invalid && addressTypeForm.touched ? 'required' : ''}}</mat-error>
      </mat-card-content>
    </mat-card>

    <!-- Person -->
    <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:row-start-2 lg:col-start-4 lg:col-span-6 xl:row-start-1 xl:col-start-9 xl:col-span-4">
      <mat-card-header>
        <mat-card-title class="!flex justify-between gap-x-5">
          <div class="w-1/2 flex flex-col">
            <div><span class="text-xl">Person &nbsp;</span><span class="font-light text-sm">({{personSelected ? 'Existing' : 'New'}})</span></div>
            <button type="button" class="flex justify-start text-sm text-[#5da4ce]" (click)="resetPerson()">
              Reset
            </button>
          </div>
          <mat-form-field class="w-1/2 !text-sm" appearance="outline">
            <mat-label>Search</mat-label>
            <input
              [matAutocomplete]="personAuto"
              (ngModelChange)="searchPersonChanged($event)"
              (keyup)="clearPersonInput($event)"
              [(ngModel)]="searchPersonInput"
              matInput
              name="searchPersonInput"
            />
            <mat-icon matSuffix class="!p-0 text-xl">search</mat-icon>
            <mat-autocomplete
              #personAuto="matAutocomplete"
              (optionSelected)="searchPersonSelected($event.option.value)"
              [displayWith]="displayPersonName"
              panelWidth="auto">
              <mat-option *ngFor="let option of financialService.filteredPersons" [value]="option">{{ option.last_name }}&nbsp;{{option.first_name}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-card-title>
      </mat-card-header>
      <hr class="mb-5">
      <mat-card-content>
        <financehub-add-template
          class="w-full"
          [formGroup]="personForm" 
          [array]="config.fields.person.slice(0,7)"
        />
        <mat-accordion *ngFor="let s of config.sections.person">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> {{ s.title }} </mat-panel-title>
            </mat-expansion-panel-header>
            <financehub-add-template
              class="w-full"
              [formGroup]="personForm" 
              [array]="config.fields.person.slice(s.range[0], s.range[1])"
            ></financehub-add-template>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngFor="let s of config.sections.personCompanyInfo">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> {{ s.title }} </mat-panel-title>
            </mat-expansion-panel-header>
            <financehub-add-template
              class="w-full"
              [formGroup]="personCompanyForm" 
              [array]="config.fields.personCompanyInfo.slice(s.range[0], s.range[1])"
            ></financehub-add-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-toolbar class="sticky bottom-0 flex justify-center !bg-white !bg-opacity-0 lg:col-span-2 lg:!px-0">
    <button class="w-full" mat-raised-button color="primary" type="submit">
        Create Client
    </button>
  </mat-toolbar>
</form>