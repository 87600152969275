<div>
    <input #uploadInput hidden="true" type="file" onclick="this.value=null" (change)="uploadInputChange($event)" [accept]="accept"/>
    <div mat-raised-button type="button" color="primary" (click)="uploadInput.click()"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        class="drag-drop-area flex items-center cursor-pointer border-1 border-gray-300 border-dashed h-9 px-2 rounded-md bg-white text-gray-500 text-xs">
    <mat-icon>upload_file</mat-icon>
    <span>{{dragDropText}}</span>
    </div>
</div>