import { Route } from '@angular/router';
import { AuthGuard } from './guards/auth-guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { AddressAddComponent } from './pages/address/address-add/address-add.component';
import { AddressDetailComponent } from './pages/address/address-detail/address-detail.component';
import { AddressListComponent } from './pages/address/address-list/address-list.component';

import { CompanyAddComponent } from './pages/company/company-add/company-add.component';
import { CompanyDetailComponent } from './pages/company/company-detail/company-detail.component';
import { CompanyListComponent } from './pages/company/company-list/company-list.component';

import { PersonAddComponent } from './pages/person/person-add/person-add.component';
import { PersonDetailComponent } from './pages/person/person-detail/person-detail.component';
import { PersonListComponent } from './pages/person/person-list/person-list.component';

import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';

import { OfferListComponent } from './pages/offer-list/offer-list.component';

import { TimeTrackingComponent } from './pages/time-tracking/time-tracking.component';
import { OrbDemoComponent } from './orb-demo/orb-demo.component';
import { MemgraphOrbComponent } from './orb-demo/memgraph-orb.component';
import { clientAddComponent } from './pages/client-add/client-add-component';

/*
    /invoices/ -> alle invoices angezeigt werden
    /invoices/{id} -> eine einzelne Invoice
    /invoice/create -> eine neue Erstellen
    /invoice/{id}/edit -> eine bestehende bearbeiten
 */

export const appRoutes: Route[] = [
  { path: 'client/create', component: clientAddComponent, canActivate: [AuthGuard], children: [], data: { name: 'Create Client' } },
  { path: 'invoices', component: InvoiceListComponent, canActivate: [AuthGuard], children: [], data: { name: 'Invoices' } },
  {
    path: 'invoice/create',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: false, name: 'Create Invoice' },
  },
  {
    path: 'invoice/:id',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: true, name: 'Invoice' },
  },

  { path: 'offers', component: OfferListComponent, canActivate: [AuthGuard], children: [], data: { name: 'Offers' } },
  {
    path: 'offer/create',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: false, name: 'Create offer' },
  },
  {
    path: 'offer/:id',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: true, name: 'Offer' },
  },

  { path: 'addresses', component: AddressListComponent, canActivate: [AuthGuard], children: [], data: { name: 'Addresses' } },
  { path: 'address/create', component: AddressAddComponent, canActivate: [AuthGuard], data: { name: 'Create address' } },
  { path: 'address/:uuid', component: AddressDetailComponent, canActivate: [AuthGuard], data: { name: 'Address' } },

  { path: 'persons', component: PersonListComponent, canActivate: [AuthGuard], children: [], data: { name: 'Contacts' } },
  {
    path: 'person/create',
    component: PersonAddComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { name: 'Create contact' },
  },
  { path: 'person/:uuid', component: PersonDetailComponent, canActivate: [AuthGuard], children: [], data: { name: 'Contact' } },

  { path: 'companies', component: CompanyListComponent, canActivate: [AuthGuard], children: [], data: { name: 'Companies' } },
  {
    path: 'company/create',
    component: CompanyAddComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { name: 'Create company' },
  },
  { path: 'company/:uuid', component: CompanyDetailComponent, canActivate: [AuthGuard], children: [], data: { name: 'Company' } },

  {
    path: 'timeTracking',
    component: TimeTrackingComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { name: 'Time tracking' },
  },

  { path: 'graph', component: OrbDemoComponent, canActivate: [AuthGuard], children: [], data: { name: 'Graph' } },
  { path: 'memgraph-graph', component: MemgraphOrbComponent, canActivate: [AuthGuard], children: [], data: { name: 'MemGraph' } },

  { path: '', component: DashboardComponent, canActivate: [AuthGuard], data: { name: 'Dashboard' } },
];
