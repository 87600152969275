<form (submit)="create()" class="!bg-background min-h-screen py-10 px-[5vw] 2xl:px-[10vw] flex flex-col items-center">
  <div class="text-primary font-light w-full mb-5 flex justify-between px-4">
    <div class="flex items-center">
      <span class="text-start text-3xl">Create Client</span>
        <mat-checkbox class="!text-sm ml-5" [(ngModel)]="editEnabled" name="enableEdit" (click)="enableEdit()" matTooltip="If enabled, you can edit existing companies, addresses and persons">Edit Mode</mat-checkbox>
    </div>
    <div class="flex gap-x-4 items-center">
      <financehub-drag-drop-template accept="*" fileSelected="fileSelected($event)"/>
      <button class="w-1/3 lg:w-auto" mat-stroked-button color="primary" type="button" (click)="resetForms()">
        {{config.button.reset}}
      </button>
      <button class="w-1/3 lg:w-auto" mat-raised-button color="primary" type="submit">
          {{config.button.create}}
      </button>
    </div>
  </div>
  <div class="flex flex-col justify-center items-center">
    <div class="w-full grid grid-cols-12 grid-rows-auto gap-4">
      <!-- Company -->
      <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:col-start-1 lg:col-span-4 !bg-background !shadow-none">
        <mat-card-header>
          <mat-card-title class="!flex justify-between gap-x-5 lg:gap-x-2">
              <div><span class="text-xl lg:text-lg">Company Details &nbsp;</span><span class="font-light text-sm">{{companySelected && !financialService.editCompany ? '(Existing)' : !companySelected && !financialService.editCompany ? '(New)' : ''}}</span><span class="font-medium text-sm text-red-400">{{companySelected && financialService.editCompany ? '(Edit Existing)' : ''}}</span></div>
              <div class="flex gap-x-5">
                <!-- <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="newCompany()">
                  New
                </button> -->
                <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="resetCompany()">
                  Reset
                </button>
              </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="!p-0 mt-4">
          <financehub-add-template
            class="w-full"
            entity="company"
            [formGroup]="companyForm" 
            [array]="config.fields.company.slice(0,4)"
            [optionsArray]="financialService.filteredCompanies"
            displayField="name_company"
            [displayWith]="displayCompanyName"
            (autoChanged)="searchCompanyChanged($event.target.value)"
            (autoSelected)="searchCompanySelected($event)"
            />
          <mat-accordion [multi]="true" #accordion1="matAccordion">
            <mat-expansion-panel class="!bg-background" (click)="clickedOnPanel(panels.panel1Expanded, accordion1)" (opened)="panels.panel1Expanded = true" (closed)="panels.panel1Expanded = false">
              <mat-expansion-panel-header>
                <mat-panel-title> Bank Details</mat-panel-title>
              </mat-expansion-panel-header>
              <financehub-add-template
                entity="company"
                class="w-full"
                [formGroup]="companyForm" 
                [array]="config.fields.company.slice(4, 7)"
              />
            </mat-expansion-panel>
            <mat-expansion-panel class="!bg-background" (click)="clickedOnPanel(panels.panel2Expanded, accordion1)" (opened)="panels.panel2Expanded = true" (closed)="panels.panel2Expanded = false">
              <mat-expansion-panel-header>
                <mat-panel-title> Additional</mat-panel-title>
              </mat-expansion-panel-header>
              <financehub-add-template
                entity="company"
                class="w-full"
                [formGroup]="companyForm" 
                [array]="config.fields.company.slice(7, 10)"
              />
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
  
      <!-- Address -->
      <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:row-start-1 lg:row-start-1 lg:col-start-5 lg:col-span-4 !bg-background !shadow-none">
        <mat-card-header>
          <mat-card-title class="!flex justify-between gap-x-5">
              <div><span class="text-xl lg:text-lg">Address Details&nbsp;</span><span class="font-light text-sm">{{addressSelected && !financialService.editAddress ? '(Existing)' : !addressSelected && !financialService.editAddress ? '(New)' : ''}}</span><span class="font-medium text-sm text-red-400">{{addressSelected && financialService.editAddress ? '(Edit Existing)' : ''}}</span></div>
              <div class="flex gap-x-5">
                <!-- <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="newAddress()">
                  New
                </button> -->
                <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="resetAddress()">
                  Reset
                </button>
              </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="!p-0 mt-4">
          <financehub-add-template
          entity="address"
          class="w-full"
          [formGroup]="addressForm" 
          [array]="config.fields.address"
          [optionsArray]="financialService.filteredAddresses"
          displayField="street"
          displayFieldSecondary="house_number"
          [displayWith]="displayAddressName"
          (autoChanged)="searchAddressChanged($event.target.value)"
          (autoSelected)="searchAddressSelected($event)"
          />
          <mat-accordion [multi]="true" #accordion2="matAccordion">
            <mat-expansion-panel (click)="clickedOnPanel(panels.panel3Expanded, accordion2)" (opened)="panels.panel3Expanded = true" (closed)="panels.panel3Expanded = false" class="!bg-background border-1 border-background" [ngClass]="{
              '!border-[#F44336]': addressTypeFormInvalid() && addressTypeForm.touched,
              }"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Address Type </mat-panel-title>
              </mat-expansion-panel-header>
              <financehub-add-template
                entity="address"
                class="w-full"
                [formGroup]="addressTypeForm" 
                [array]="config.fields.addressType"
              />
            </mat-expansion-panel>
          </mat-accordion>
          <!-- <mat-error class="text-xs text-right px-4 font-light">{{addressTypeForm.invalid && addressTypeForm.touched ? 'required' : ''}}</mat-error> -->
        </mat-card-content>
      </mat-card>
  
      <!-- Person -->
      <mat-card class="col-span-12 md:col-start-3 md:col-span-8 lg:row-start-1 lg:col-start-9 lg:col-span-4 !bg-background !shadow-none">
        <mat-card-header>
          <mat-card-title class="!flex flex-col gap-x-5">
            <div class="flex justify-between">
              <div><span class="text-xl lg:text-lg">Personal Details&nbsp;</span><span class="font-light text-sm">{{personSelected && !financialService.editPerson ? '(Existing)' : !personSelected && !financialService.editPerson ? '(New)' : ''}}</span><span class="font-medium text-sm text-red-400">{{personSelected && financialService.editPerson ? '(Edit Existing)' : ''}}</span></div>
              <div class="flex gap-x-5">
                <!-- <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="newPerson()">
                  New
                </button> -->
                <button type="button" class="flex justify-start text-sm text-[#0F658C]" (click)="resetPerson()">
                  Reset
                </button>
              </div>
            </div>
            <mat-error class="text-xs" *ngIf="!personIsAvailable && personSelected">Person exists for company</mat-error>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="!p-0 mt-4">
          <financehub-add-template
            entity="person"
            class="w-full"
            [formGroup]="personForm" 
            [array]="config.fields.person.slice(0,7)"
            [optionsArray]="financialService.filteredPersons"
            displayField="first_name"
            displayFieldSecondary="last_name"
            [displayWith]="displayPersonName"
            (autoChanged)="searchPersonChanged($event.target.value)"
            (autoSelected)="searchPersonSelected($event)"
          />
          
          <mat-accordion [multi]="true" #accordion3="matAccordion">
            <mat-expansion-panel class="!bg-background" [expanded]="true" (opened)="panels.panel4Expanded = true" (closed)="panels.panel4Expanded = false">
              <mat-expansion-panel-header (click)="clickedOnPanel(panels.panel4Expanded, accordion3)" >
                <mat-panel-title> Company </mat-panel-title>
              </mat-expansion-panel-header>
              <financehub-add-template
                entity="person"
                class="w-full"
                [formGroup]="personCompanyForm" 
                [array]="config.fields.personCompanyInfo"
              />
            </mat-expansion-panel>
            <mat-expansion-panel class="!bg-background" (click)="clickedOnPanel(panels.panel5Expanded, accordion3)" (opened)="panels.panel5Expanded = true" (closed)="panels.panel5Expanded = false">
              <mat-expansion-panel-header>
                <mat-panel-title> Person </mat-panel-title>
              </mat-expansion-panel-header>
              <financehub-add-template
                entity="person"
                class="w-full"
                [formGroup]="personForm" 
                [array]="config.fields.person.slice(7, 11)"
              />
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>