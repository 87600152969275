import { DatePipe, NgFor, NgIf, NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TimeTrackingTemplate } from '../../interfaces/time-tracking-template';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeTrackingTemplateService } from '../../services/time-tracking-template.service';
import { Subscription } from 'rxjs';
import { TimeTrackingDay } from '../../interfaces/time-tracking-day';
import { cloneDeep } from '@apollo/client/utilities';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { TimeTrackingInputTemplateComponent } from './time-tracking-input-template/time-tracking-input-template.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'financehub-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    NgIf,
    NgClass,
    MatTableModule,
    MatInputModule,
    TimeTrackingInputTemplateComponent,
  ],
})
export class TimeTrackingComponent implements OnInit, OnDestroy {
  constructor(
    private datePipe: DatePipe,
    private templateService: TimeTrackingTemplateService,
    private readonly keycloak: KeycloakService,
  ) {}

  monthsGerman: string[] = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  monthsEnglish: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  daysGerman: string[] = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

  templates: TimeTrackingTemplate[] = [];
  newTemplate: TimeTrackingTemplate = {
    name: 'Neue Vorlage',
    hours_amount: 38.5,
    type: 'office',
    days: [
      { day_index: 1, start: null, end: null, break: null },
      { day_index: 2, start: null, end: null, break: null },
      { day_index: 3, start: null, end: null, break: null },
      { day_index: 4, start: null, end: null, break: null },
      { day_index: 5, start: null, end: null, break: null },
    ],
  };

  selectedYear!: number;
  sortedMonthsGerman: string[] = [];
  selectedMonthGerman!: string;
  selectedMonthIndex!: number;
  // daysOfMonth: any[] = [];
  absencesToSelect: string[] = ['', 'Urlaub', 'Sonderurlaub', 'Krankenstand', 'Feiertag'];
  selectedTemplate = 1;
  years: number[] = [];
  userProfile: KeycloakProfile | null = null;

  private querySubscription!: Subscription;

  dataSource: any;
  displayedColumns: string[] = ['day', 'start', 'end', 'break', 'workingTime', 'absence', 'note'];

  async ngOnInit() {
    this.getYears();
    this.getMonths();
    this.getDays();
    this.querySubscription = this.templateService.getTimeTrackingTemplates().subscribe(({ data, loading }) => {
      this.templates = [this.newTemplate, ...cloneDeep(data.timeTrackingTemplates)];
    });
    const isLoggedIn = await this.keycloak.isLoggedIn();

    if (isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      console.log(this.userProfile)
    }
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  getYears() {
    const currentDate = new Date();
    for (let i = 0; i < 3; i++) {
      this.years.push(currentDate.getFullYear() + i - 1);
    }
    this.selectedYear = currentDate.getFullYear();
  }

  getMonths() {
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const monthIndex = (currentDate.getMonth() + i - 1) % 12;
      const currentMonth = this.monthsGerman[monthIndex];
      this.sortedMonthsGerman.push(currentMonth);
    }
    this.selectedMonthGerman = this.monthsGerman[currentDate.getMonth()];
  }

  getDays() {
    this.selectedMonthIndex = this.monthsGerman.findIndex((m) => m == this.selectedMonthGerman);
    this.dataSource = [];
    const currentDate = new Date(`${this.monthsEnglish[this.selectedMonthIndex]} 1, ${this.selectedYear}`);
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    this.pushDays(firstDayOfMonth, lastDayOfMonth);
  }

  pushDays(firstDayOfMonth: any, lastDayOfMonth: any) {
    for (let day = firstDayOfMonth; day <= lastDayOfMonth; day.setDate(day.getDate() + 1)) {
      const dayName = day.toLocaleDateString('de-DE', { weekday: 'long' });
      this.dataSource.push({
        day: day.getDate(),
        dayName: dayName,
        start: null,
        end: null,
        break: null,
        working: null,
        note: null,
        absence: null,
        weeklyHours: null,
      });
    }
  }

  createTemplate() {
    const input = this.templates[0];
    input.name = `Vorlage ${this.templates.length}`;
    this.templateService.saveTimeTrackingTemplate(input).subscribe(({ data, loading }) => {
      this.templates.push(input);
      this.selectedTemplate = this.templates.length - 1;
      this.resetTemplate();
    });
  }

  resetTemplate() {
    this.templates[0] = this.newTemplate;
  }

  useTemplate() {
    this.fillFields();
    this.weeklyHours();
  }

  fillFields() {
    for (const templateDay of this.templates[this.selectedTemplate].days) {
      for (const dayOfMonth of this.dataSource) {
        if (dayOfMonth.dayName === this.daysGerman[templateDay.day_index] && !dayOfMonth.absence) {
          this.fillField(dayOfMonth, templateDay);
        } else if (dayOfMonth.absence) {
          this.emptyField(dayOfMonth);
        }
      }
    }
  }

  fillField(dayOfMonth: any, templateDay: TimeTrackingDay) {
    dayOfMonth.start = templateDay.start;
    dayOfMonth.end = templateDay.end;
    dayOfMonth.break = templateDay.break;
    if (this.valuesExist(dayOfMonth)) {
      this.calcWorkinghours(dayOfMonth);
    }
  }

  valuesExist(dayOfMonth: any) {
    return dayOfMonth.start && dayOfMonth.end && dayOfMonth.break;
  }

  calcWorkinghours(dayOfMonth: any) {
    const startTime = new Date(`2023-01-01T${dayOfMonth.start}`);
    const endTime = new Date(`2023-01-01T${dayOfMonth.end}`);
    const timeDiff = endTime.getTime() - startTime.getTime() - this.breakInMillisec(dayOfMonth);
    if (timeDiff >= 0) {
      const hours = Math.floor(timeDiff / 3600000);
      const minutes = Math.floor((timeDiff % 3600000) / 60000);
      const seconds = Math.floor((timeDiff % 60000) / 1000);
      dayOfMonth.working = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
    }
  }

  breakInMillisec(dayOfMonth: any) {
    const [hours, minutes] = dayOfMonth.break.split(':').map(Number);
    return (hours * 3600 + minutes * 60 + 0) * 1000;
  }

  emptyField(dayOfMonth: any) {
    dayOfMonth.start = null;
    dayOfMonth.end = null;
    dayOfMonth.break = null;
    dayOfMonth.working = null;
  }

  weeklyHours() {
    let weeklySum = [];
    for (let i = 0; i < this.dataSource.length; i++) {
      weeklySum.push(this.dataSource[i].working);
      if (this.dataSource[i].dayName === 'Sonntag' || i === this.dataSource.length - 1) {
        this.dataSource[i].weeklyHours = this.calcWeeklyHours(weeklySum);
        weeklySum = [];
      }
    }
  }

  calcWeeklyHours(weeklySum: any) {
    const sum = weeklySum.reduce((acc: number, value: string) => {
      if (value) {
        const [hours, minutes, seconds] = value.split(':').map(Number);
        acc += hours * 3600 + minutes * 60 + seconds;
      }
      return acc;
    }, 0);
    const hours = Math.floor(sum / 3600);
    const minutes = Math.floor((sum % 3600) / 60);
    const seconds = sum % 60;
    const formattedSum = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    return formattedSum;
  }

  checkAbsence(d: any) {
    if (d.absence) {
      this.emptyField(d);
    }
  }

  deleteTemplate() {
    this.templates.splice(this.selectedTemplate, 1);
    this.selectedTemplate = this.templates.length - 1;
  }

  downloadFile() {
    console.log('complete month:', this.dataSource);
  }
}
