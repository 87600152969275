import { Component, OnDestroy, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SnackbarTemplateComponent } from '../snackbar-template/snackbar-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dialog } from '@angular/cdk/dialog';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';
import { ProjectSettings } from '../../../assets/config/project-config';
import { ListTemplateComponent } from 'src/app/components/list-template/list-template.component';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { defaultConfig, FilterConfig } from 'src/app/interfaces/filter-config';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { KeycloakAdminService } from 'src/app/services/keycloak-admin.service';

@Component({
  selector: 'financehub-list-parent',
  template: '',
  standalone: true,
  imports: [],
})
export class ListParentComponent implements OnDestroy {

    config = ProjectSettings;
    loading!: boolean;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    filterConfig: FilterConfig = defaultConfig;
    filterTerm: string | undefined = undefined;
    pageIndex!: number;

    private querySubscription!: Subscription;

    constructor(
        public dialog: Dialog,
        public addressesService: AddressesService,
        public companyService: CompanyService,
        public personsService: PersonsService,
        public keycloakAdminService: KeycloakAdminService,
        public _snackBar: MatSnackBar
    ) {}

  @ViewChild(ListTemplateComponent) childComponent!: ListTemplateComponent;

  fetchEntities(entity: string) {
    const serviceCall = 
        entity === 'companies' ? this.companyService.getCompanies(this.filterConfig) : 
        entity === 'addresses' ? this.addressesService.getAddresses(this.filterConfig) :
        entity === 'persons' ? this.personsService.getPersons(this.filterConfig) :
        this.keycloakAdminService.getUsers();
    this.loading = true;
    this.querySubscription = serviceCall.subscribe(({ data, loading }) => {
      this.dataSource.data = data[entity].data;
      if (this.childComponent.paginator) {
        setTimeout(() => {
          this.childComponent.paginator.length = data[entity].totalCount;
          this.childComponent.paginator.pageIndex = this.pageIndex;
        });
      }
      this.loading = false;
    });
  }

  refetchEntities(entity: string) {
    if (this.childComponent.paginator && this.childComponent.sort) {
      this.pageIndex = this.childComponent.paginator.pageIndex;
      this.filterConfig = {
        limit_count: this.childComponent.paginator.pageSize,
        limit_start: this.childComponent.paginator.pageSize * this.childComponent.paginator.pageIndex,
        sort_field: this.childComponent.sort.active,
        sort_order: this.childComponent.sort.direction,
        filter_term: this.filterTerm,
      };
      this.fetchEntities(entity);
    }
  }

  openDeleteDialog(entityToDelete: any, entity: string): void {
    this.addressesService.dialogCall = 'deleteAddressOfCompany';
    this.personsService.dialogCall = '';
    const text = 
        entity === 'companies' ? `${this.config.dialog.deleteCompany} '${entityToDelete.name_company}'` :
        entity === 'addresses' ? `${this.config.dialog.deleteAddress} '${entityToDelete.street} ${entityToDelete.house_number}'` :
        entity === 'persons' ? `${this.config.dialog.deletePerson} '${entityToDelete.first_name} ${entityToDelete.last_name}'` :
        `${this.config.dialog.deleteUser} '${entityToDelete.username}'`;
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        text: ` ${text}?`,
        element: entityToDelete,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe((entityToDelete) => {
      if (entityToDelete) this.delete(entityToDelete, entity);
    });
  }

  delete(entityToDelete: any, entity: string) {

    const message = 
        entity === 'companies' ? this.config.snackbar.deletedCompany :
        entity === 'addresses' ? this.config.snackbar.deletedAddress :
        entity === 'persons' ? this.config.snackbar.deletedPerson :
        this.config.snackbar.deletedUser;
    const serviceCall = 
        entity === 'companies' ? this.companyService.deleteCompany(entityToDelete.uuid) : 
        entity === 'addresses' ? this.addressesService.deleteAddress(entityToDelete.uuid) :
        entity === 'persons' ? this.personsService.deletePerson(entityToDelete.uuid) : 
        this.keycloakAdminService.deleteUser(entityToDelete.id);
    this.querySubscription = serviceCall.subscribe(() => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, 
        { data: { 
          button: this.config.snackbar.confirm, 
          message: message } 
        });
      this.refetchEntities(entity);
    });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

}
