import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { DialogModule } from '@angular/cdk/dialog';

@Component({
  selector: 'financehub-list-template',
  templateUrl: './list-template.component.html',
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    RouterLink,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    DialogModule
  ],
})
export class ListTemplateComponent implements OnInit, AfterViewInit{
  @Input() entity!: any;
  @Input() addButton!: string;
  @Input() array: any
  @Input() entities!: any;
  @Input() dataSource!: MatTableDataSource<any>;
  @Output() clickedDelete = new EventEmitter();
  @Output() filterDelete = new EventEmitter();
  @Output() filterChanged = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterValue: string = '';
  config!: any;

  ngOnInit(): void {
    this.config = ProjectSettings;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  deleteElement(event: any) {
    this.clickedDelete.emit(event);
  }

  deleteFilter(event: any) {
    this.filterValue = '';
    this.filterDelete.emit(event)
  }

  onFilterChange(event: any) {
    this.filterChanged.emit(event)
  }

}
