import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ProjectSettings } from 'src/assets/config/project-config';

@Injectable({
  providedIn: 'root',
})
export class AddService {
  config = ProjectSettings;
  form: any;

  createFormGroup(fields: any) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    fields.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      formGroupConfig[field.controlName] = new FormControl(
        { value: field.controlName === 'uuid' ? uuidv4() : field.defaultValue, disabled: field.readonly },
        validators,
      );
    });
    return new FormGroup(formGroupConfig);
  }

  parseToString(form: any, fields: any) {
    Object.keys(form.controls).forEach((controlName) => {
      const controlValue = form.get(controlName)?.value;
      const field = fields.find((f: any) => f.controlName === controlName);
      field.type === 'number' && controlValue ? form.get(controlName)?.setValue(parseInt(controlValue)) : null;
    });
    return form;
  }
}
