import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class CsvImportService {
  constructor(
    private http: HttpClient,
  ) {}

  importCsvData(csvType: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${environment.uri}/csv-import/${csvType}`, formData);
  }

  resetCollection(csvType: string) {
    return this.http.get(`${environment.uri}/csv-import/reset-collection/${csvType}`);
  }
}
