<mat-tab-group class="flex w-full" #matTabGroup>
  <!-- Company -->
  <mat-tab label="1 - Details">
    <financehub-detail-template
      class="w-full"
      [formGroup]="companyForm" 
      (clickedUpdate)="updateCompany()"
      (clickedEdit)="editCompany()"
      [array]="config.fields.company"
      [editButton]="config.button.editCompany"
      [saveButton]="config.button.saveCompany"
      [editActive]="editCompanyActive"
    />
  </mat-tab>

  <!-- Addresses -->
  <mat-tab [label]="getLabel('address')">
    <!-- TODO: TBD no editing of addresses in this view -->
    <div class="w-full lg:w-4/5">
      
      <div class="my-5 flex w-full justify-between px-5 lg:px-0">
        <mat-form-field class="company-filter w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>{{config.table.filter}}</mat-label>
          <input #input0 [(ngModel)]="valueFilterAdresses" (keyup)="applyFilter($event, dataSourceAddresses)" matInput />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="valueFilterAdresses"
            (click)="deleteValue('addresses', dataSourceAddresses)"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
          <button class="!hidden sm:!flex w-2/5" (click)="openAddAddressDialog(); $event.stopPropagation()" mat-raised-button color="primary">
            <mat-icon> add </mat-icon>{{config.button.addAddress}}
          </button>
      </div>

      <div class="mat-elevation-z8 mx-5 mb-5 lg:mx-0">
        <div class="overflow-x-auto">
          <table #addresses [dataSource]="dataSourceAddresses" mat-table matSort>
            <ng-container *ngFor="let c of config.tableColumns.companyAddresses">
              <ng-container [matColumnDef]="c" *ngIf="c !== 'actions'">
                <th class="" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by {{ c }}">{{c}}</th>
                <td class="relative" *matCellDef="let element" mat-cell>
                  <span *ngIf="element[c]">{{element[c]}}</span>
                  <mat-icon *ngIf="c === element.type">done</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions" *ngIf="c === 'actions'">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let element" mat-cell>
                  <div class="flex flex-col justify-end xs:flex-row">
                    <mat-icon class="mr-3 cursor-pointer" [routerLink]="'/address/' + element.uuid">edit</mat-icon>
                    <mat-icon class="cursor-pointer" (click)="openDeleteAddressDialog(element)"> delete </mat-icon>
                  </div>
                </td>
              </ng-container> 
            </ng-container>
            <tr *matHeaderRowDef="config.tableColumns.companyAddresses" mat-header-row></tr>
            <tr
              *matRowDef="let row; let even = even; columns: config.tableColumns.companyAddresses"
              [ngClass]="{ '!bg-gray-100': even, '!bg-gray-200': addressToDelete == row && deleteAddressOverlay }"
              mat-row
            ></tr> 
          </table>
        </div>

        <mat-toolbar class="sm:!hidden fixed bottom-4 !bg-white !bg-opacity-0 py-10 lg:col-span-2 lg:!px-0">
          <button class="mr-3" (click)="openAddAddressDialog()" mat-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
        </mat-toolbar>
      </div>
    </div>
  </mat-tab>

  <!-- Persons -->
  <mat-tab [label]="getLabel('person')">
    <!-- TODO: TBD no editing of persons in this view -->
    <div class="sticky top-0 my-5 flex w-full justify-between px-5 lg:w-4/5 lg:px-0">
      <mat-form-field class="company-filter w-2/5 lg:w-2/5" appearance="outline">
        <mat-label>{{config.table.filter}}</mat-label>
        <input #input0 [(ngModel)]="valueFilterPersons" (keyup)="applyFilter($event, dataSourcePersons)" matInput />
        <mat-icon
          class="cursor-pointer !p-1"
          *ngIf="valueFilterPersons"
          (click)="deleteValue('persons', dataSourcePersons)"
          matSuffix
          >close</mat-icon
        >
      </mat-form-field>
        <button class="!hidden sm:!flex w-2/5" (click)="openAddPersonDialog(); $event.stopPropagation()" mat-raised-button color="primary">
          <mat-icon> add </mat-icon>{{config.button.addPerson}}
        </button>
    </div>

    <div class="w-full lg:w-4/5">
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <div class="overflow-x-auto">
          <table #persons [dataSource]="dataSourcePersons" mat-table matSort>
            <ng-container *ngFor="let c of config.tableColumns.companyPersons">
              <ng-container [matColumnDef]="c" *ngIf="c !== 'actions'">
                <th class="" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by {{ c }}">{{c}}</th>
                <td class="relative" *matCellDef="let element" mat-cell>
                  {{element[c]}}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions" *ngIf="c === 'actions'">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let element" mat-cell>
                  <div class="flex flex-col justify-end xs:flex-row">
                    <mat-icon class="mr-3 cursor-pointer" [routerLink]="'/person/' + element.uuid">edit</mat-icon>
                    <mat-icon class="cursor-pointer" (click)="openDeletePersonDialog(element)"> delete </mat-icon>
                  </div>
                </td>
              </ng-container> 
            </ng-container>
            <tr *matHeaderRowDef="config.tableColumns.companyPersons" mat-header-row></tr>
            <tr
              *matRowDef="let row; let even = even; columns: config.tableColumns.companyPersons"
              [ngClass]="{ '!bg-gray-100': even, '!bg-gray-200': personToDelete == row && deletePersonOverlay }"
              mat-row
            ></tr> 
          </table>
        </div>
        <mat-toolbar class="sm:!hidden fixed bottom-4 !bg-white !bg-opacity-0 py-10 lg:col-span-2 lg:!px-0">
          <button class="mr-3" (click)="openAddPersonDialog()" mat-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <!-- <button *ngIf="!editPersonsActive" mat-mini-fab color="primary"><mat-icon> edit </mat-icon></button>
          <button *ngIf="editPersonsActive" mat-mini-fab color="primary"><mat-icon> check </mat-icon></button> -->
        </mat-toolbar>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<financehub-orb-demo [companyUuid]="uuid"></financehub-orb-demo>