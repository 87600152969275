<div class="flex flex-col">
  <div class="text-primary text-lg mb-2">Items</div>

  <!-- <div class="flex items-center justify-between px-6 w-11/12">
    <mat-icon class="cursor-pointer" *ngIf="panelsOpened()" (click)="accordion.openAll()">unfold_more</mat-icon>
    <mat-icon class="cursor-pointer" *ngIf="!panelsOpened()" (click)="accordion.closeAll()">unfold_less</mat-icon>
  </div> -->

  <div class="h-full invoice-accordion">
    <form class="flex flex-col items-center" [formGroup]="financialService.lineItemsForm">
      <div class="flex justify-center items-center flex-col" (cdkDropListDropped)="drop($event)" formArrayName="lineItems" cdkDropList>
        <div
          class="draggable-element !shadow-none mb-2"
          *ngFor="let item of financialService.lineItems.controls; let i = index; let last = last"
          [formGroupName]="i"
          (afterExpand)="scrollToPanel(i)"
          cdkDrag
        >
        <!-- <div class="drag-handle flex items-center" cdkDragHandle>
          <span><span *ngIf="i < 9">0</span>{{ i + 1 }}.</span>
          <mat-icon class="mx-2" (click)="$event.stopPropagation()">drag_handle</mat-icon>
        </div> -->
          <!-- <mat-expansion-panel-header>
            <mat-panel-title class="flex justify-center items-center font-medium">
              <div class="absolute left-5 flex w-1/4 text-gray-600">
                <div class="drag-handle flex items-center" cdkDragHandle>
                  <span><span *ngIf="i < 9">0</span>{{ i + 1 }}.</span>
                  <mat-icon class="mx-2" (click)="$event.stopPropagation()">drag_handle</mat-icon>
                </div>
                <mat-icon
                  *ngIf="financialService.lineItems.controls.length > 1"
                  (click)="deleteLineItem(i); $event.stopPropagation()"
                  >delete</mat-icon
                >
              </div>
              <span class="w-3/4 text-end">{{ item.get('title')?.value }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header> -->

          <div class="grid grid-cols-1 lg:grid-cols-12 gap-2" #panel >
            <div class="flex lg:col-start-1">
              <div><span *ngIf="i < 9">0</span>{{ i + 1 }}.</div>
              <mat-icon class="ml-1 cursor-grab" (click)="$event.stopPropagation()" cdkDragHandle>drag_handle</mat-icon>
              <mat-icon
                  *ngIf="financialService.lineItems.controls.length > 1"
                  class="cursor-pointer text-lg flex justify-center items-center"
                  (click)="deleteLineItem(i); $event.stopPropagation()"
                  >delete</mat-icon
                >
            </div>
            <!-- <div class="drag-handle flex items-center" cdkDragHandle>
              <span><span *ngIf="i < 9">0</span>{{ i + 1 }}.</span>
              <mat-icon class="mx-2" (click)="$event.stopPropagation()">drag_handle</mat-icon>
            </div> -->
            <mat-form-field class="mat-form-field-normal w-full !mb-0 lg:row-start-1 lg:col-start-2 lg:col-span-3 !text-sm" appearance="outline">
              <mat-label>Title</mat-label>
              <input
                formControlName="title"
                [matAutocomplete]="lineItemsAuto"
                (keyup)="onProductChange($event, i)"
                matInput
              />
              <!-- <mat-error class="text-end" *ngIf="financialService.lineItems.at(i).get('title')?.errors?.['required']"
                >Required</mat-error
              > -->
              <mat-autocomplete
                #lineItemsAuto="matAutocomplete"
                (optionSelected)="onProductSelected($event, i)"
                panelWidth="auto"
              >
                <mat-option *ngFor="let product of filteredProducts" [value]="product.title">{{ product.title }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div class="flex relative w-full lg:col-start-5 lg:col-span-3 2xl:col-span-4 !mb-0">
              <mat-form-field class="w-full !text-sm !bg-background !mb-0" [ngClass]="{'mat-form-field-textarea !absolute z-10': financialService.expandedDescription === i, 'mat-form-field-textarea-thin': financialService.expandedDescription !== i}" (click)="$event.stopPropagation(); financialService.expandDescription(i)" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea class="!resize-none" (keydown)="onInputDescriptionChange($event)" [ngClass]="{'!mt-2': financialService.expandedDescription === i}" formControlName="description" matInput></textarea>
              </mat-form-field>
            </div>

            <div class="w-full xs:flex gap-2 lg:row-start-1 lg:col-start-8 lg:col-span-5 2xl:col-start-9 2xl:col-span-4">
              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>Amount</mat-label>
                <input [formControlName]="'quantity'" (input)="calculateService.calcSums(i, 'quantity')" matInput />
                <!-- <mat-error *ngIf="financialService.lineItems.at(i).get('quantity')?.errors?.['required']"></mat-error> -->
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>UP</mat-label>
                <input [formControlName]="'unitPrice'" (input)="calculateService.calcSums(i, 'unitPrice')" matInput />
                <!-- <mat-error *ngIf="financialService.lineItems.at(i).get('unitPrice')?.errors?.['required']"></mat-error> -->
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>VAT</mat-label>
                <input [formControlName]="'taxPerc'" (input)="calculateService.calcSums(i, 'taxPerc')" matInput />
                <!-- <mat-error *ngIf="financialService.lineItems.at(i).get('taxPerc')?.errors?.['required']"></mat-error> -->
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>Sum</mat-label>
                <input [formControlName]="'singleSum'" (input)="calculateService.calcSums(i, 'singleSum')" matInput />
                <!-- <mat-error *ngIf="financialService.lineItems.at(i).get('singleSum')?.errors?.['required']"></mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div >
    <button (click)="addContainer()" mat-button color="primary" class="!shadow-none text-sm">Add New Item</button>
  </div>

</div>
