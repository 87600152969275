import { Component, OnInit, QueryList, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { NgIf, NgClass, NgFor, DatePipe, DecimalPipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { DialogTemplateComponent } from '../../components/dialog-template/dialog-template.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { StatusService } from '../../services/finance/status.service';
import { status } from '../../interfaces/status';
import { SnackbarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
import { OfferService } from '../../services/finance/offer.service';
import { FinancialService } from '../../services/finance/financial.service';
import { ProjectSettings } from 'src/assets/config/project-config';
import { defaultOfferConfig, FilterConfig } from 'src/app/interfaces/filter-config';

@Component({
  selector: 'financehub-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    NgFor,
    MatIconModule,
    DatePipe,
    DialogModule,
    MatTabsModule,
    MatToolbarModule,
    DecimalPipe,
    MatTooltipModule,
    MatSnackBarModule,
  ],
})
export class OfferListComponent implements OnInit {
  constructor(
    public statusService: StatusService,
    public offerService: OfferService,
    public financialService: FinancialService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
  ) {}

  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['number', 'offerDate', 'validityDate', 'net', 'gross', 'customerID', 'status', 'icon'];
  // dataSources: { [key: string]: MatTableDataSource<any> } = {};
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  allOffers: any = {};
  notSwitching = true;
  config = ProjectSettings;
  filterConfig: FilterConfig = defaultOfferConfig;
  pageIndex!: number;
  sortState: Sort = { active: 'invoice_number', direction: 'desc' };
  filterTerm = '';

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchOffers();
      }
    });
    this.fetchOffers();
  }

  fetchOffers() {
    this.offerService.getOffers(this.filterConfig).subscribe(({ data, loading }) => {
         //NEW UI START
        //  const allOffers = data.offersByStatus
        //  .map((statusGroup: any) => 
        //    statusGroup.offers.map((offer: any) => ({
        //      ...offer,
        //      status: statusGroup._id
        //    }))
        //  )
        //  .flat();
        this.dataSource.data = data.offers.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setTimeout(() => {
          if (this.dataSource.paginator) {
            this.dataSource.paginator.length = data.offers.totalCount;
            this.dataSource.paginator.pageIndex = this.pageIndex;
          }
        });
         //NEW UI END
      // this.statusService.offerStatuses.forEach((status: status, index: number) => {
      //   const statusType = status.type;
      //   const offersByStatus = data.offersByStatus.find((i: any) => i._id === statusType);
      //   if (offersByStatus) {
      //     this.initDataTables(offersByStatus, index);
      //   } else {
      //     this.dataSources[statusType] = new MatTableDataSource<any>([]);
      //     this.dataSources[statusType].paginator = this.paginator.toArray()[index];
      //     this.dataSources[statusType].sort = this.sort.toArray()[index];
      //   }
      // });
    });
  }

  resetPaginationAndRefetch() {
    if (this.dataSource.paginator)
      this.dataSource.paginator.pageIndex = 0;
    this.refetchOffers();
  }

  clearFilterAndRefetch() {
    this.filterTerm = '';
    this.refetchOffers();
  }

  refetchOffers() {
    if (this.dataSource.paginator && this.dataSource.sort) {
      this.pageIndex = this.dataSource.paginator.pageIndex;
      this.filterConfig = {
        limit_count: this.dataSource.paginator.pageSize,
        limit_start: this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex,
        sort_field: this.dataSource.sort.active,
        sort_order: this.dataSource.sort.direction,
        filter_term: this.filterTerm,
      };
      this.fetchOffers();
    }
  }

  initDataTables(items: any, index: number) {
    // this.allOffers[items._id] = items.offers;
    // this.financialService.filterValues[items._id] = '';
    // this.dataSources[items._id] = new MatTableDataSource<any>(this.allOffers[items._id]);
    // this.dataSources[items._id].paginator = this.paginator.toArray()[index];
    // this.sortTable(this.dataSources[items._id], this.sort.toArray()[index]);
    // const updatedData = this.dataSources[items._id].data.map((item) => ({
    //   ...item,
    //   offer_date_formatted: this.datePipe.transform(new Date(parseInt(item.offer_date))),
    //   validity_date_formatted: this.datePipe.transform(new Date(parseInt(item.validity_date))),
    //   total_sum: item.subtotal + item.total_taxes,
    //   name: item.customer ? item.customer.name_company : '',
    // }));
    // this.dataSources[items._id].data = updatedData;
  }

  // sortTable(dataSource: MatTableDataSource<any>, sort: MatSort) {
  //   dataSource.sort = sort;
  //   dataSource.sortingDataAccessor = (item, property) => {
  //     switch (property) {
  //       case 'number':
  //         return item.offer_number;
  //       case 'offerDate':
  //         return item.offer_date;
  //       case 'validityDate':
  //         return item.validity_date;
  //       case 'net':
  //         return item.subtotal;
  //       case 'gross':
  //         return item.subtotal;
  //       case 'customerID':
  //         return item.customer_id;
  //       default:
  //         return item[property];
  //     }
  //   };
  //   const sortState: Sort = { active: 'offerDate', direction: 'desc' };
  //   dataSource.sort.active = sortState.active;
  //   dataSource.sort.direction = sortState.direction;
  //   dataSource.sort.sortChange.emit(sortState);
  // }

  openDeleteDialog(offer: any): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        text: `${this.config.dialog.deleteOffer} '${offer.title}'?`,
        element: offer,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe((offer) => {
      if (offer) this.deleteOffer(offer);
    });
  }

  deleteOffer(offer: any) {
    this.offerService.deleteOffer(offer._id).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: 'OK', message: this.config.snackbar.deletedOffer } });
    });
  }

  statusChanged(value: string, id: string) {
    this.offerService.updateStatus(id, value).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: 'OK', message: this.config.snackbar.statusUpdated } });
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    const statusCount = this.statusService.offerStatuses.length;
    if (!isInputFocused) {
      for (let i = 0; i < statusCount; i++) {
        if (event.key === '' + (i + 1)) this.matTabGroup.selectedIndex = i;
      }
    }
  }

  getTabLabel(status: status, index: number) {
    // const statusCount = this.dataSources[status.type]?.data?.length || 0;
    // return `${index + 1} - ${status.title} (${statusCount})`;
  }

  availableStatuses(statusType: string) {
    // return this.statusService.offerStatuses.filter((s) => s.type !== statusType);
    return this.statusService.offerStatuses;
  }
}
