export interface FilterConfig {
  limit_count: number;
  limit_start: number;
  sort_field: string;
  sort_order: string;
  filter_term?: string;
}

const defaults = {
  limit_start: 0,
  sort_order: 'desc',
}

export const defaultConfig: FilterConfig = {
  limit_count: 5,
  sort_field: 'bds_number',
  ...defaults,
}

export const defaultInvoiceConfig: FilterConfig = {
  limit_count: 10,
  sort_field: 'invoice_number',
  ...defaults,
}

export const defaultOfferConfig: FilterConfig = {
  limit_count: 10,
  sort_field: 'offer_number',
  ...defaults,
}
  