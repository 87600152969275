import { NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'financehub-input-template',
  templateUrl: './input-template.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule
  ],
})
export class InputTemplateComponent implements OnInit{
  @Input() label!: string;
  @Input() readonly!: boolean;
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() type = "text";
  @Input() optionsArray!: any;
  @Input() displayWith!: any;
  @Input() displayField!: string;
  @Input() displayFieldSecondary: string | null = null;
  @Input() optionValue!: string;
  @Input() colSpan!: string;
  @Output() autocompleteChanged = new EventEmitter();
  @Output() autocompleteSelected = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();

  startDate!: Date;

  ngOnInit(): void {
    this.startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 35));
  }

  onAutocompleteChange(event: any) {
    this.autocompleteChanged.emit(event);
  }

  onAutocompleteSelected(event: any) {
    this.autocompleteSelected.emit(event);
  }

  onSelectionChange(event: any) {
    this.selectionChanged.emit(event);
  }

  navigateToLink(type: string,  field: any) {
    if (type === 'link' && field.disabled && field.value) {
      let absoluteURL = field.value;
      if (!absoluteURL.startsWith('http://') && !absoluteURL.startsWith('https://')) {
        if (absoluteURL.startsWith('www.')) {
          absoluteURL = `http://${absoluteURL}`;
        } else {
          absoluteURL = `http://www.${absoluteURL}`;
        }
      }
      window.open(absoluteURL, '_blank');
    }
  }
}
