<financehub-list-template 
  entity="address"
  [addButton]="config.button.addAddress"
  [array]="config.tableColumns.addresses"
  [dataSource]="dataSource"
  [sortActive]="sortState.active"
  [sortDirection]="sortState.direction"
  [loading]="loading"
  (clickedDelete)="openDeleteDialog($event, 'addresses')"
  (filterChanged)="onFilterChange($event)"
  (filterDelete)="deleteFilter()"
  (sortChanged)="refetchEntities('addresses')"
  (pageChanged)="refetchEntities('addresses')"
/>