import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { AddressesService } from '../../../services/crm/addresses.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';
import { FinancialService } from 'src/app/services/finance/financial.service';

@Component({
  selector: 'financehub-address-detail',
  templateUrl: './address-detail.component.html',
  standalone: true,
  imports: [DetailTemplateComponent]
})
export class AddressDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    public addressesService: AddressesService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public financialService: FinancialService
  ) {}

  config = ProjectSettings;
  private querySubscription!: Subscription;
  uuid!: string;
  address!: any;
  addressForm!: FormGroup;
  editAddressActive = false;

  ngOnInit() {
    this.createOrUpdateAddressForm();
    this.route.paramMap.subscribe((params) => {
      this.uuid = this.financialService.entityEditUuid; 
      this.querySubscription = this.addressesService.getAddress(this.uuid).subscribe(({ data, loading }) => {
        this.address = { ...data.address };
        this.createOrUpdateAddressForm(this.address);
      });
    });
  }

  createOrUpdateAddressForm(address: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.address.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = address[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.addressForm = new FormGroup(formGroupConfig);
  }

  editAddress() {
    this.editAddressActive = true;
    this.enableFields();
  }

  enableFields() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.addressForm.get(controlName)?.enable() : null;
    })
  }

  updateAddress() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      const controlValue = this.addressForm.get(controlName)?.value;
      const type = this.config.fields.address.find((f: any) => f.controlName === controlName)?.type;
      this.address[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.addressesService.updateAddress(this.uuid, this.address).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedAddress } });
    });
    this.editAddressActive = false;
    this.disableFields();
  }

  disableFields() {
    Object.keys(this.addressForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.addressForm.get(controlName)?.disable() : null;
    })
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}