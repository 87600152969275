<form class="flex" [formGroup]="financialService.sumsForm" class="bg-gray-100 p-5 rounded-xl">
  <table>
    <tr><td class="text-lg xl:text-xl">Total</td></tr>
    <tr class="text-sm xl:text-base">
      <td>Total Net</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'de-DE'">
        {{ financialService.sumsForm.get('subtotal')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'en-EN'">
        {{ financialService.sumsForm.get('subtotal')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
    <tr *ngFor="let t of financialService.sumsForm.get('taxes')?.value" class="text-sm xl:text-base">
      <ng-container *ngIf="t.taxPerc !== null">
        <td>+{{ t.taxPerc | number: '1.0-2' }}% USt.</td>
        <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'de-DE'">
          {{ t.taxEuro | number: '1.2-2' : 'de-DE' }}
        </td>
        <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'en-EN'">
          {{ t.taxEuro | number: '1.2-2' : 'en-EN' }}
        </td>
      </ng-container>
    </tr>
    <tr class="text-sm xl:text-base">
      <td>Total VAT</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'de-DE'">
        {{ financialService.sumsForm.get('totalTaxes')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'en-EN'">
        {{ financialService.sumsForm.get('totalTaxes')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
    <tr class="text-sm xl:text-base">
      <td>Gross Sum</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'de-DE'">
        {{ financialService.sumsForm.get('totalSum')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value === 'en-EN'">
        {{ financialService.sumsForm.get('totalSum')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
  </table>
</form>
