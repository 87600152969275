import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ConfigInitService } from './config-init-service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAdminService {
  private keycloakUrl= ''; 
  private realm = '';
  private clientId = '';
  private clientSecret = '';


  constructor(private http: HttpClient, private configService: ConfigInitService) {}

  public init(): Observable<void> {
    return this.configService.getConfig().pipe(
      map((config) => {
        if (config) {
          this.keycloakUrl = config.KEYCLOAK_URL;
          this.realm = config.KEYCLOAK_REALM;
          this.clientId = config.KEYCLOAK_CLIENT_ADMIN_ID;
          this.clientSecret = config.KEYCLOAK_CLIENT_SECRET;
        } else {
          throw new Error('Failed to load Keycloak configuration');
        }
      })
    );
  }

  private getAdminToken(): Observable<string> {
    const url = `${this.keycloakUrl}/realms/${this.realm}/protocol/openid-connect/token`;
    const body = new URLSearchParams();
    body.set('client_id', this.clientId);
    body.set('client_secret', this.clientSecret);
    body.set('grant_type', 'client_credentials');

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post<any>(url, body.toString(), { headers }).pipe(
      map((response) => response.access_token) // Extract the token
    );
  }

  public getUsers(): Observable<any> {
    return this.getAdminToken().pipe(
      switchMap((token) => {
        const url = `${this.keycloakUrl}/admin/realms/${this.realm}/users`;
        const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

        return this.http.get<any[]>(url, { headers }).pipe(
          map((users) => ({ 
            data: { 
              users: {
                totalCount: users.length, 
                data: users
              }
            }
          })) 
        );;
      })
    );
  }

  public createUser(user: {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
  }): Observable<any> {
    return this.getAdminToken().pipe(
      switchMap((token) => {
        const url = `${this.keycloakUrl}/admin/realms/${this.realm}/users`;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        });

        const userData = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          username: user.username,
          enabled: true, // Enable the user
          credentials: [
            {
              type: 'password',
              value: user.password,
              temporary: false, // Set `true` if the user needs to change the password on first login
            },
          ],
        };

        return this.http.post<any>(url, userData, { headers });
      })
    );
  }

  public deleteUser(userId: string) {
    return this.getAdminToken().pipe(
      switchMap((token) => {
        const url = `${this.keycloakUrl}/admin/realms/${this.realm}/users/${userId}`;
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });

        return this.http.delete<void>(url, { headers });
      })
    );
  }
}