<form (submit)="importCsvData()" class="flex flex-col mx-[2vw] mt-[1vw] 3xl:mx-[10vw]">
    <div class="text-primary font-light w-full mb-5 flex justify-between px-4">
        <div class="flex items-center">
          <span class="text-start text-3xl">CSV Import</span>
        </div>
        <div class="flex gap-x-4 items-center">
            <select matNativeControl name="csvType" [(ngModel)]="csvType" class="h-9 rounded-md !px-2 !border-1 !border-solid !border-gray-300">
                <option [value]="option.value" *ngFor="let option of options">{{option.text}}</option>
            </select>
            <financehub-drag-drop-template accept=".csv" (fileSelected)="fileSelected($event)"/>
            <button mat-stroked-button color="primary" type="button" (click)="resetCollection()">
                {{config.button.resetCollection}}
              </button>
            <button mat-raised-button color="primary" type="submit">
                {{config.button.import}}
            </button>
        </div>
    </div>
</form>