import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Company } from '../../interfaces/company';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { GET_PERSONS } from './persons.service';
import { GET_ADDRESSES } from './addresses.service';
import { FilterConfig, defaultConfig } from 'src/app/interfaces/filter-config';

const GET_COMPANIES = gql`
  query companies($filter: FilterInput) {
    companies (filter: $filter) {
      data {
        name_company
        uuid
        bds_number
        uid
        company_register_number
        kind
        delivery_addresses {
          bds_number
          country
          street
          city
          house_number
          zip
          uuid
          additional_information
        }
        invoice_addresses {
          bds_number
          country
          street
          city
          house_number
          zip
          uuid
          additional_information
        }
        persons {
          uuid
          first_name
          last_name
          bds_number
          birthday
          phone_private
          mail_private
          fax
          gender
          title_academic
          title_academic_after
        }
        invoices {
          title
          invoice_number
          delivery_address_id
          invoice_address_id
        }
      }
      totalCount
    }
  }
`;

const GET_COMPANY = gql`
  query company($uuid: String!) {
    company(uuid: $uuid) {
      name_company
      uuid
      bds_number
      uid
      company_register_number
      bank_name
      iban
      bic
      url
      kind
      info
      delivery_addresses {
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
        additional_information
      }
      invoice_addresses {
        bds_number
        country
        street
        city
        house_number
        zip
        uuid
        additional_information
      }
      company_persons {
        person_uuid
        position
        phone_office
        email_office
        fax_office
        phone_mobile
      }
      persons {
        uuid
        first_name
        last_name
        bds_number
        birthday
        phone_private
        mail_private
        fax
        gender
        title_academic
        title_academic_after
      }
    }
  }
`;

const GET_FIRST_BDS_NUMBER = gql`
  query companyFirstBdsNumber {
    companyFirstBdsNumber
  }
`;

const CREATE_COMPANY = gql`
  mutation CreateCompany($createCompanyInput: CreateCompanyInput) {
    createCompany(createCompanyInput: $createCompanyInput) {
      _id
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($uuid: String!, $updateCompanyInput: UpdateCompanyInput) {
    updateCompany(uuid: $uuid, updateCompanyInput: $updateCompanyInput) {
      _id
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation DeleteCompany($uuid: String!) {
    deleteCompany(uuid: $uuid) {
      _id
    }
  }
`;

const ADDRESS_TO_COMPANY = gql`
  mutation AddressToCompany($uuid: String!, $input: AddressCompanyInput) {
    addressToCompany(uuid: $uuid, input: $input) {
      _id
    }
  }
`;

const DELETE_COMPANY_ADDRESS = gql`
  mutation DeleteCompanyAddress($uuid: String!, $input: DeleteCompanyAddressInput) {
    deleteCompanyAddress(uuid: $uuid, input: $input) {
      _id
    }
  }
`;

const PERSON_TO_COMPANY = gql`
  mutation PersonToCompany($uuid: String!, $input: CompanyPersonInput) {
    personToCompany(uuid: $uuid, input: $input) {
      _id
    }
  }
`;

const UPDATE_COMPANY_PERSON = gql`
  mutation UpdateCompanyPerson($uuid: String!, $companyPersonInput: CompanyPersonInput) {
    updateCompanyPerson(uuid: $uuid, companyPersonInput: $companyPersonInput) {
      _id
    }
  }
`;

const DELETE_COMPANY_PERSON = gql`
  mutation DeleteCompanyPerson($uuid: String!, $personUuid: String!) {
    deleteCompanyPerson(uuid: $uuid, personUuid: $personUuid) {
      _id
    }
  }
`;

const UPDATE_OR_CREATE_COMPANY_PERSON = gql`
  mutation UpdateOrCreateCompanyPerson($input: UpdateOrCreateCompanyPersonInput) {
    updateOrCreateCompanyPerson(input: $input) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  getCompanies(filterConfig: FilterConfig): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_COMPANIES,
      variables: {
        filter: {
          limit_count: filterConfig.limit_count,
          limit_start: filterConfig.limit_start,
          sort_field: filterConfig.sort_field,
          sort_order: filterConfig.sort_order,
          filter_term: filterConfig.filter_term,
        }
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getCompany(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_COMPANY,
      variables: { uuid },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getFirstBdsNumber(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_FIRST_BDS_NUMBER,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  saveCompany(company: Company): Observable<any> {
    const companyInput = {
      name_company: company.name_company,
      uuid: company.uuid,
      bds_number: company.bds_number,
      uid: company.uid,
      company_register_number: company.company_register_number,
      bank_name: company.bank_name,
      iban: company.iban,
      bic: company.bic,
      url: company.url,
      info: company.info,
    };
    return this.apollo.mutate({
      mutation: CREATE_COMPANY,
      variables: { createCompanyInput: companyInput },
    });
  }

  updateCompany(uuid: string, company: Company): Observable<any> {
    const companyInput = {
      name_company: company.name_company,
      bds_number: company.bds_number,
      uid: company.uid,
      company_register_number: company.company_register_number,
      bank_name: company.bank_name,
      iban: company.iban,
      bic: company.bic,
      url: company.url,
      info: company.info,
    };
    return this.apollo.mutate({
      mutation: UPDATE_COMPANY,
      variables: { uuid, updateCompanyInput: companyInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompany(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_COMPANY, variables: { uuid } });
  }

  saveCompanyAddress(uuid: string, addressInput: any) {
    return this.apollo.mutate({
      mutation: ADDRESS_TO_COMPANY,
      variables: { uuid, input: addressInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompanyAddress(uuid: string, addressInput: any) {
    return this.apollo.mutate({
      mutation: DELETE_COMPANY_ADDRESS,
      variables: { uuid, input: addressInput },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  saveCompanyPerson(uuid: string, input: any) {
    return this.apollo.mutate({
      mutation: PERSON_TO_COMPANY,
      variables: { uuid, input },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  deleteCompanyPerson(uuid: string, personUuid: string) {
    return this.apollo.mutate({
      mutation: DELETE_COMPANY_PERSON,
      variables: { uuid, personUuid },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  updateCompanyPerson(uuid: string, companyPerson: any): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_COMPANY_PERSON,
      variables: { uuid, companyPersonInput: companyPerson },
      refetchQueries: [{ query: GET_COMPANY, variables: { uuid } }],
    });
  }

  updateOrCreateCompanyPerson(input: any) {
    return this.apollo.mutate({
      mutation: UPDATE_OR_CREATE_COMPANY_PERSON,
      variables: { input },
      refetchQueries: [
        { 
          query: GET_COMPANIES, 
          variables: { filter: defaultConfig }
        }, 
        { 
          query: GET_PERSONS, 
          variables: { filter: defaultConfig }
        }, 
        { 
          query: GET_ADDRESSES, 
          variables: { filter: defaultConfig }
        }
      ],
    });
  }

  getDummyCompanies() {
    return this.http.get(`${environment.uri}/companies/rss-dummy-companies`, { responseType: 'text' });
  }
}
