<financehub-list-template 
  entity="person"
  [addButton]="config.button.addPerson"
  [array]="config.tableColumns.persons"
  [dataSource]="dataSource"
  [sortActive]="sortState.active"
  [sortDirection]="sortState.direction"
  [loading]="loading"
  (clickedDelete)="openDeleteDialog($event, 'persons')"
  (filterChanged)="onFilterChange($event)"
  (filterDelete)="deleteFilter()"
  (sortChanged)="refetchEntities('persons')"
  (pageChanged)="refetchEntities('persons')"
/>