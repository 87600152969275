import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.development';

const uri = '/api/bff/query';

export function createApollo(httpLink: HttpLink, snackBar: MatSnackBar): ApolloClientOptions<any> {
  // Error handling link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        snackBar.open(`GraphQL Error: ${message}`, 'Close', { duration: 8000 });
      });
    }

    if (networkError) {
      snackBar.open('Network Error: Unable to reach the server.', 'Close', { duration: 8000 });
    }
  });

  // Combine error link with HttpLink
  const link = ApolloLink.from([errorLink, httpLink.create({ uri: `${environment.uri}/graphql` })]);

  return {
    link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MatSnackBar],
    },
  ],
  imports: [
    MatSnackBarModule,
  ]
})
export class GraphQLModule {}