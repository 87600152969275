<div class="flex flex-col justify-center items-center w-full">
    <form
        class="grid grid-cols-12 grid-rows-auto gap-x-2 w-full"
        [formGroup]="formGroup"
        (submit)="create()"
    >
        <financehub-input-template
            *ngFor="let f of array"
            [class]="f.class" 
            [formGroup]="formGroup"
            [controlName]="f.controlName" 
            [label]="f.name" 
            [type]="f.type"
            [optionsArray]="f.optionsArray"
            [readonly]="f.readonly"
        />
        <div class="row-start-12 col-span-12" *ngIf="createButton">
            <button class="w-full" [disabled]="disabledButton" mat-raised-button color="primary" type="submit">
                {{createButton}}
            </button>
        </div>
    </form>
</div>
  