import { Component, OnInit } from '@angular/core';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { FormGroup } from '@angular/forms';
import { AddService } from 'src/app/services/add.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from 'src/app/components/snackbar-template/snackbar-template.component';
import { AddTemplateComponent } from 'src/app/components/add-template/add-template.component';
import { KeycloakAdminService } from 'src/app/services/keycloak-admin.service';


@Component({
  selector: 'financehub-user-add',
  templateUrl: './user-add.component.html',
  standalone: true,
  imports: [
    AddTemplateComponent
  ]
})
export class UserAddComponent implements OnInit {

  constructor(
    private _snackBar: MatSnackBar,
    private addService: AddService,
    private keycloakAdminService: KeycloakAdminService,
  ) {
  }

  config = ProjectSettings;
  userForm!: FormGroup;

  ngOnInit() {
    this.userForm = this.addService.createFormGroup(this.config.fields.user);
  }


  create() {
    this.userForm = this.addService.parseToString(this.userForm, this.config.fields.user);
    const newPerson = this.getValuesFromForm();

    this.keycloakAdminService.createUser(newPerson).subscribe({
        next: () => {
          this.userForm.reset();
          this.userForm = this.addService.createFormGroup(this.config.fields.user);
          this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.createdUser } });
        },
        error: (err) => console.error('Error saving user:', err),
    });
  }

  getValuesFromForm() {
    const formValues = this.userForm.getRawValue();
    return {
      ...formValues,  
    };
  }

 
}
