<div class="relative flex min-h-screen flex-col text-gray-700" #scrollTarget>
  <div class="my-3 flex w-full justify-center">
    <mat-accordion class="w-full lg:w-4/5">
       <mat-expansion-panel
        [ngClass]="{ 'border-2 border-red-300': financialService.detailsForm.invalid && financialService.invalidForm }"
        [expanded]="true"
        >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Details</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <financehub-invoice-details/>
      </mat-expansion-panel>

      <mat-expansion-panel
        [ngClass]="{ 'border-2 border-red-300': financialService.customerForm.invalid && financialService.invalidForm }"
        [expanded]="false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Kunde</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <financehub-invoice-customer/>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Projekt</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <financehub-invoice-project/>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Texte</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <financehub-invoice-texts class="flex flex-col justify-between"/>
      </mat-expansion-panel>

    </mat-accordion>
  </div>

  <financehub-invoice-positions [scrollTarget]="scrollTarget"/>
   <div class="a-flex-center sticky bottom-5 z-30 mt-5 w-full">
    <button class="mx-5 w-full lg:w-4/5" (click)="submit()" mat-raised-button color="primary">
      {{
        financialService.isInvoice && !financialService.isEdit ? config.button.createInvoice
        : financialService.isInvoice && financialService.isEdit ? config.button.updateInvoice
        : !financialService.isInvoice && !financialService.isEdit ? config.button.createOffer
        : !financialService.isInvoice && financialService.isEdit ? config.button.updateOffer
        : ''
      }}
    </button>
  </div>
</div>
