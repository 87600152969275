<div class="!bg-background min-h-screen py-10 px-[10vw] flex gap-x-10 relative">
  <div class="flex flex-col w-full">
      <div class="text-3xl text-primary font-light w-full mb-1">
          <span class="text-start">Users</span>
      </div>
      <financehub-list-template 
        entity="user"
        [addButton]="config.button.addUser"
        [array]="config.tableColumns.users"
        [dataSource]="dataSource"
        [sortActive]="sortState.active"
        [sortDirection]="sortState.direction"
        [loading]="loading"
        [isEditable]="false"
        (clickedDelete)="openDeleteDialog($event, 'users')"
        (filterChanged)="onFilterChange($event)"
        (filterDelete)="deleteFilter()"
        (sortChanged)="refetchEntities('users')"
        (pageChanged)="refetchEntities('users')"
      />
  </div>
</div>