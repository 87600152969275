<div class="flex justify-center flex-col">
  <div class="text-primary text-lg mb-2">Client Details</div>
  <form
  class="mb-4 flex grid w-full grid-cols-1 flex-col items-start lg:grid-cols-2 gap-x-2"
  [formGroup]="financialService.detailsForm"
  >
    <financehub-input-template
      class="col-span-2"
      [optionsArray]="financialService.filteredCompanies"
      [formGroup]="financialService.detailsForm"
      (autocompleteChanged)="autocompleteChanged($event)"
      (autocompleteSelected)="onCompanySelected($event)"
      [displayWith]="displayCustomerName"
      displayField="name_company"
      type="autocomplete"
      label="Customer"
      controlName="customer"
    />

     <financehub-input-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Invoice Date - Days' : 'Quote Date - Days'"
      [controlName]="financialService.isInvoice ? 'invoiceDateDays' : 'offerDateDays'"
    />

    <financehub-input-template
      [readonly]="financialService.isEdit ? false : true"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Invoice Date' : 'Quote Date'"
      [type]="financialService.isEdit ? 'datepicker' : 'text'"
      [controlName]="financialService.isInvoice ? 'invoiceDate' : 'offerDate'"
    />

     <financehub-input-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Due Date Days' : 'Validity Date Days'"
      [controlName]="financialService.isInvoice ? 'dueDateDays' : 'validityDateDays'"
    />

    <financehub-input-template
      [formGroup]="financialService.detailsForm"
      [label]="financialService.isInvoice ? 'Due Date' : 'Validity Date'"
      [type]="financialService.isEdit ? 'datepicker' : 'text'"
      [controlName]="financialService.isInvoice ? 'dueDate' : 'validityDate'"
    />

    <financehub-input-template
      [optionsArray]="financialService.localsInput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Input Locale"
      controlName="inputLocale"
    />

    <financehub-input-template
      [optionsArray]="financialService.localsOutput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Output Locale"
      controlName="outputLocale"
    />
  </form>
</div>
